import { Injectable, } from '@angular/core';
import {NgClass} from '@angular/common';
import { ApiService } from '@core/services/api.service';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { SurveyPlanModel, SurveyPreviewModel, SurveysItemCreateModel, SurveysResponseModel, SurveyViewModel, StructureModel } from '@app/surveys/models/survey.model';
import {
  BlockCreateModel,
  BlockModel,
  BlockUpdateModel,
  NodeModel,
  NodeModelType,
  QuestionCreateModel,
  QuestionElementModel,
  QuestionUpdateModel,
  ScreenOutCreateModel,
  ScreenOutElementModel,
  ScreenOutUpdateModel,
  TextCreateModel,
  TextNodeModel,
  TextUpdateModel,
  QuotaNodeModel,
  QuotaNodeCreateModel,
  QuotaNodeUpdateModel,
  TextModel,
  BundleQuestionCreateModel,
  BundleQuestionModel,
  BundleElementModel,
  QuestionBundleUpdateModel,
} from '@app/surveys/models/survey-plan-elements.model';

@Injectable({
  providedIn: 'root'
})
export class SurveysApiService {

  constructor(private api: ApiService) {
  }

  // SURVEY CRUD

  createSurvey(survey: SurveysItemCreateModel): Observable<SurveyViewModel> {
    return this.api.post(`/surveys`, {survey: survey}).pipe(
      pluck('survey')
    );
  }

  updateSurvey(surveyView: Partial<SurveyViewModel>): Observable<SurveyViewModel> {
    return this.api.patch(`/surveys/${surveyView.id}`, {survey: surveyView}).pipe(
      pluck('survey'));
  }

  getSurveyPlanById(surveyId: string): Observable<SurveyPlanModel> {
    return this.api.get(`/surveys/${surveyId}/chunked`).pipe(
      map((response) =>
        (response.schema && response.data) ||
        (response.survey && response.survey.data) ||
        response.survey ||
        response)
    );
  }

  deleteSurvey(surveyId: string): Observable<boolean> {
    return this.api.delete(`/surveys/${surveyId}`).pipe(
      map((): boolean => true),
    );
  }

  duplicateSurvey(surveyId: string): Observable<boolean> {
    return this.api.post(`/surveys/${surveyId}/duplicate`, null).pipe(
      map((): boolean => true),
    );
  }

  // SURVEY VIEW

  getSurveysViews(params: URLSearchParams): Observable<SurveysResponseModel> {
    return this.api.get(`/surveys?${params.toString()}`);
  }

  // SURVEY VIEW - PREVIEW

  getSurveyPreviewById(surveyId: string): Observable<SurveyPreviewModel> {
    return this.api.post(`/surveys/${surveyId}/preview`, null).pipe(
      pluck('data')
    );
  }

  // SURVEYS - STRUCTURE

  updateSurveyPlanStructure(surveyId: string, structure: StructureModel): Observable<StructureModel> {
    return this.api.post(`/surveys/${surveyId}/structure`, {structure: structure}).pipe(
      pluck('structure')
    );
  }

  // BLOCK

  createBlock(surveyId: string, data: BlockCreateModel): Observable<BlockModel> {
    return this.api.post(`/surveys/${surveyId}/blocks`, {block: data}).pipe(
      pluck('block'));
  }

  updateBlock(surveyId: string, data: BlockUpdateModel): Observable<BlockModel> {
    return this.api.patch(`/surveys/${surveyId}/blocks/${data.id}`, {block: data}).pipe(
      pluck('block'));
  }

  deleteBlock(surveyId: string, blockId: string): Observable<boolean> {
    return this.api.delete(`/surveys/${surveyId}/blocks/${blockId}`).pipe(
      map(() => true)
    );
  }

  getBlockById(surveyId: string, blockId: string): Observable<BlockModel> {
    return this.api.get(`/surveys/${surveyId}/blocks/${blockId}/chunked`);
  }

  // BLOCK - STRUCTURE

  updateBlockStructure(surveyId: string, blockId: string, structure: StructureModel): Observable<StructureModel> {
    return this.api.post(`/surveys/${surveyId}/blocks/${blockId}/structure`, {structure: structure}).pipe(
      pluck('structure')
    );
  }

  // NODE

  deleteNodeInBlock(blockId: string, nodeId: string, surveyId: string): Observable<boolean> {
    const params = {survey_id: surveyId};
    return this.api.delete(`/blocks/${blockId}/nodes/${nodeId}`, {params}).pipe(
      map(() => true)
    );
  }

  // QUESTION

  createQuestion(data: QuestionCreateModel, surveyId: string, blockId: string): Observable<NodeModel> {
    const params = {
      survey_id: surveyId,
      block_id: blockId,
    };

    return this.api.post('/questions', {question: data}, {params}).pipe(
      pluck('node'));
  }

  updateQuestion(data: QuestionUpdateModel, surveyId: string): Observable<QuestionElementModel> {
    return this.api.patch(`/questions/${data.id}/bulk?survey_id=${surveyId}`, {question: data}).pipe(
      pluck('question'));
  }

  // QUESTION - STRUCTURE

  updateQuestionStructure(questionId: string, structure: StructureModel, surveyId: string): Observable<StructureModel> {
    const params = {survey_id: surveyId};
    return this.api.post(`/questions/${questionId}/structure`, {structure: structure}, {params}).pipe(
      pluck('structure')
    );
  }

  // BUNDLE QUESTION

  createBundleQuestion(data: BundleQuestionCreateModel, surveyId: string, blockId: string): Observable<NodeModel> {
    const params = {survey_id: surveyId, block_id: blockId};
    return this.api.post('/bundle_nodes', {bundle: data}, {params}).pipe(
      pluck('node'),
    );
  }

  updateQuestionBundle(data: QuestionBundleUpdateModel, surveyId:  string): Observable<BundleElementModel> {
    const params = {survey_id: surveyId};
    return this.api.patch(`/bundle_nodes/${data.id}`, {bundle: data}, {params}).pipe(
      pluck('bundle_node'),
    );
  }

  updateBundleQuestionsStructure(bundleNodeId: string, structure: StructureModel, surveyId: string): Observable<StructureModel> {
    const params = {survey_id: surveyId};
    return this.api.post(`/bundle_nodes/${bundleNodeId}/questions_structure`, {structure: structure}, {params}).pipe(
      pluck('structure')
    );
  }

  updateBundleQuestionOptionsStructure(bundleNodeId: string, structure: StructureModel, surveyId: string): Observable<StructureModel> {
    const params = {survey_id: surveyId};
    return this.api.post(`/bundle_nodes/${bundleNodeId}/question_options_structure`, {structure: structure}, {params}).pipe(
      pluck('structure')
    );
  }

  // TEXT

  createText(data: TextCreateModel, blockId: string, surveyId: string): Observable<NodeModel> {
    const params = {block_id: blockId, survey_id: surveyId};
    return this.api.post('/text_nodes', {text_node: data}, {params}).pipe(
      pluck('node'));
  }

  updateText(data: TextUpdateModel, blockId: string, surveyId: string): Observable<TextNodeModel> {
    const params = {block_id: blockId, survey_id: surveyId};
    return this.api.patch<TextModel>(`/text_nodes/${data.id}`, {text_node: data}, {params}).pipe(
      pluck('text_node'));
  }

  // SCREEN OUT

  createScreenOut(data: ScreenOutCreateModel, blockId: string, surveyId: string): Observable<NodeModel> {
    const params = {block_id: blockId, survey_id: surveyId};
    return this.api.post('/screen_out_nodes', {screen_out_node: data}, {params}).pipe(
      pluck('node'));
  }

  updateScreenOut(data: ScreenOutUpdateModel, blockId: string, surveyId: string): Observable<ScreenOutElementModel> {
    const params = {block_id: blockId, survey_id: surveyId};
    return this.api.patch(`/screen_out_nodes/${data.id}`, {screen_out_node: data}, {params}).pipe(
      pluck('screen_out_node'));
  }

  // QUOTA NODE

  createQuotaNode(data: QuotaNodeCreateModel, blockId: string, surveyId: string): Observable<NodeModel> {
    const params = {block_id: blockId, survey_id: surveyId};
    return this.api.post('/quota_nodes', {quota_node: data}, {params}).pipe(
      pluck('node'));
  }

  updateQuotaNode(data: QuotaNodeUpdateModel, blockId: string, surveyId: string): Observable<QuotaNodeModel> {
    const params = {block_id: blockId, survey_id: surveyId};
    return this.api.patch(`/quota_nodes/${data.id}`, {quota_node: data}, {params}).pipe(
      pluck('quota_node'));
  }
}
