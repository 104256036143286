import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type NodeIconType =
  'single' |
  'multiple' |
  'bundle' |
  'language' |
  'ranked' |
  'card' |
  'image' |
  'text' |
  'block' |
  'screenOut' |
  'webhook' |
  'quota' |
  'open';

@Component({
  selector: 'ds-element-icon',
  templateUrl: './element-icon.component.html',
  styleUrls: ['./element-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementIconComponent {
  @Input() iconType: NodeIconType;
}
