import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { EnvironmentModel } from '@env/environment.model';
import { BrowserStorageService } from '@core/services/browser-storage.service';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  readonly apiURI: string;
  readonly neoURI: string;
  readonly previewSocketURI: string;
  readonly messengerURL: string;

  constructor(private storage: BrowserStorageService) {
    const apiParam = this.storage.getItem('ds_api_hot_url_param') || '';
    const neoParam = this.storage.getItem('ds_neo_hot_url_param') || '';
    const messengerParam = this.storage.getItem('ds_messenger_hot_url_param') || '';
    const socket = this.storage.getItem('ds_preview_socket_hot_url_param') || '';

    this.apiURI = this.getApiURL(environment, apiParam);
    this.neoURI = this.getNeoURL(environment, neoParam);
    this.previewSocketURI = this.getPreviewSocketURL(environment, socket);
    this.messengerURL = this.getMessengerURL(environment, neoParam);
  }

  private getApiURL(env: EnvironmentModel, hotApi: string): string {
    if (env.runningTestEnv && hotApi.length !== 0) {
      return hotApi;
    }
    return env.api;
  }

  private getNeoURL(env: EnvironmentModel, hotNeo: string): string {
    if (env.runningTestEnv && hotNeo.length !== 0) {
      return hotNeo;
    }
    return env.neo;
  }

  private getPreviewSocketURL(env: EnvironmentModel, hotSocket: string): string {
    if (env.runningTestEnv && hotSocket.length !== 0) {
      return hotSocket;
    }
    return env.previewSocket;
  }

  private getMessengerURL(env: EnvironmentModel, hotMessenger: string): string {
    if (env.runningTestEnv && hotMessenger.length !== 0) {
      return hotMessenger;
    }
    return env.messenger;
  }
}
