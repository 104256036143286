import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ds-open-text-entry-icon',
  templateUrl: './open-text-entry-icon.component.html',
  styleUrls: ['./open-text-entry-icon.component.scss']
})
export class OpenTextEntryIconComponent implements OnInit {

  @Input() checked: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
