import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { BrowserStorageService } from '@core/services';

@Component({
  selector: 'ds-debug-panel',
  templateUrl: './debug-panel.component.html',
  styleUrls: ['./debug-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DebugPanelComponent implements AfterViewInit {
  @ViewChild('apiHot', { static: true }) apiHotInput: ElementRef;
  @ViewChild('previewSocketHot', { static: true }) previewSocketHotInput: ElementRef;
  @ViewChild('neoHot', { static: true }) neoHotInput: ElementRef;
  @ViewChild('messengerHot', { static: true }) messengerHotInput: ElementRef;

  open = false;

  constructor(private storage: BrowserStorageService) {
  }

  ngAfterViewInit() {
    this.apiHotInput.nativeElement.value = this.storage.getItem('ds_api_hot_url_param') || '';
    this.previewSocketHotInput.nativeElement.value = this.storage.getItem('ds_preview_socket_hot_url_param') || '';
    this.neoHotInput.nativeElement.value = this.storage.getItem('ds_neo_hot_url_param') || '';
    this.messengerHotInput.nativeElement.value = this.storage.getItem('ds_messenger_hot_url_param') || '';

  }

  toggleOpen() {
    this.open = !this.open;
  }

  storeParam() {
    const api = this.apiHotInput.nativeElement.value;
    const socket = this.previewSocketHotInput.nativeElement.value;
    const neo = this.neoHotInput.nativeElement.value;
    const messenger = this.messengerHotInput.nativeElement.value;

    this.storage.setItem('ds_api_hot_url_param', api);
    this.storage.setItem('ds_preview_socket_hot_url_param', socket);
    this.storage.setItem('ds_neo_hot_url_param', neo);
    this.storage.setItem('ds_messenger_hot_url_param', messenger);
  }

  clearParam() {
    this.storage.setItem('ds_api_hot_url_param', '');
    this.storage.setItem('ds_preview_socket_hot_url_param', '');
    this.storage.setItem('ds_neo_hot_url_param', '');
    this.storage.setItem('ds_messenger_hot_url_param', '');
    this.apiHotInput.nativeElement.value = '';
    this.previewSocketHotInput.nativeElement.value = '';
    this.neoHotInput.nativeElement.value = '';
    this.messengerHotInput.nativeElement.value = '';
  }
}
