import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'ds-html-text',
  templateUrl: 'html-text.component.html',
  styleUrls: ['html-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,

})

export class HtmlTextComponent {
  @Input() htmlText: string;

  constructor(private sanitizer: DomSanitizer) {
  }

  get sanitizedHtml(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.htmlText);
  }
}
