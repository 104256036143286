import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { assertUnreachable } from '@app/lib/utils';
import { ElementText, NodeDataType, QuestionOption } from '@app/surveys/models/survey-plan-elements.model';
import { NodeIconType } from '@shared/components/element-icon/element-icon.component';

// Overlaps with NodeQuestionData so that it can be reused for bundle questions
export interface ElementPreviewData {
  type: NodeDataType;
  code: string | null;
  text: ElementText;
  questionOptions: QuestionOption[];
  questionCode?: string | null;
  parentName?: string;
  parentCode?: string | null;
}

@Component({
  selector: 'ds-question-element-preview',
  templateUrl: './question-element-preview.component.html',
  styleUrls: ['./question-element-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionElementPreviewComponent implements OnInit {
  @Input() data: ElementPreviewData;
  isBundleQuestion = false;

  ngOnInit() {
    this.isBundleQuestion = this.data.type === NodeDataType.BundleQuestion;
  }

  get displayText(): ElementText {
    return this.data.text;
  }

  get hasQuestionCode(): boolean {
    return this.questionCode.length > 0;
  }

  get questionCode(): string {
    if (this.data.questionCode) {
    return this.data.questionCode || '';
    } else {
      return this.data.code || '';
    }
  }

  get parentCode(): string {
    return this.data.parentCode || '';
  }

  get questionOptionsTotal(): number {
    return this.data.questionOptions ? this.data.questionOptions.length : 0;
  }

  get iconType(): NodeIconType {
    switch (this.data.type) {
      case NodeDataType.RadioQuestion:
        return 'single';

      case NodeDataType.LanguageQuestion:
        return 'single';

      case NodeDataType.MultipleQuestion:
        return 'multiple';

      case NodeDataType.OpenEndedQuestion:
        return 'open';

      case NodeDataType.BundleQuestion:
      case NodeDataType.BundleNode:
        return 'bundle';

      default:
        assertUnreachable(this.data);
    }
  }
}
