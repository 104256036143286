import { SurveysResponseModel, SurveyView } from '@app/surveys/models/survey.model';
import { defaultPagination, Pagination } from '@shared/models/meta.model';
import { Action, createReducer, on } from '@ngrx/store';
import { SurveysViewsActions } from '@app/surveys-manager/actions';
import { Loading, NotAsked, RemoteData, Success, Updating, Refreshing } from '@app/remote-data/models/remote-data.model';

export const surveysViewsFeatureKey = 'surveysViews';

export interface State {
  selectedSurveyViewId: string;
  queryResults: RemoteData<SurveysResponseModel>;
  pagination: Pagination;
  surveysViews: Map<string, RemoteData<SurveyView>>;
  positionsStatus: RemoteData<void>;
  positions: string[];
}

export const initialState: State = {
  selectedSurveyViewId: '',
  queryResults: new NotAsked(),
  pagination: defaultPagination(),
  surveysViews: new Map(),
  positionsStatus: new Success(null),
  positions: []
};

const surveyViewsReducer = createReducer(
  initialState,

  on(SurveysViewsActions.querySurveysViews, (state) => ({
    ...state,
    queryResults: new Loading()
  })),

  on(SurveysViewsActions.replaceQueryResult, (state, {result}) => ({
    ...state,
    queryResults: result
  })),

  on(SurveysViewsActions.replacePagination, (state, {pagination}) => ({
    ...state,
    pagination
  })),

  on(SurveysViewsActions.clearSurveysViews, (state) => ({
    ...initialState,
  })),

  on(SurveysViewsActions.addSurveysViews, (state, {surveysViews}) => {
    const _surveysViews = new Map(state.surveysViews);
    surveysViews.forEach((item) => _surveysViews.set(item[0], item[1]));

    return {
      ...state,
      surveysViews: _surveysViews
    };
  }),

  on(SurveysViewsActions.replaceSurveyViews, (state, {surveysViews}) => {
    return {
      ...state,
      surveysViews: new Map(surveysViews),
    };
  }),

  // UPDATE

  on(SurveysViewsActions.updateSurveyView, (state, {uuid, surveyView}) => {
    const _surveysViews = new Map(state.surveysViews);
    _surveysViews.set(uuid, new Updating(state.surveysViews.get(uuid), surveyView));

    return {
      ...state,
      surveysViews: _surveysViews
    };
  }),

  on(SurveysViewsActions.replaceSurveyView, (state, {uuid, surveyView}) => {
    const _surveysViews = new Map(state.surveysViews);
    _surveysViews.set(uuid, surveyView);

    return {
      ...state,
      surveysViews: _surveysViews
    };
  }),

  on(SurveysViewsActions.deleteSurveyView, (state, {surveyUuid}) => {
    const _surveysViews = new Map(state.surveysViews);
    _surveysViews.set(surveyUuid, new Refreshing(state.surveysViews.get(surveyUuid)));

    return {
      ...state,
      surveysViews: _surveysViews
    };
  }),

  on(SurveysViewsActions.removeSurveyView, (state, {surveyUuid}) => {
    const _surveysViews = new Map(state.surveysViews);
    _surveysViews.delete(surveyUuid);

    return {
      ...state,
      surveysViews: _surveysViews
    };
  }),

  // SELECT

  on(SurveysViewsActions.selectSurveyView, (state, {surveyUuid}) => ({
    ...state,
    selectedSurveyViewId: surveyUuid
  })),

  on(SurveysViewsActions.clearSelectedSurveyView, (state) => ({
    ...state,
    selectedSurveyViewId: ''
  })),

  // MOVE

  on(SurveysViewsActions.moveSurveyView, (state) => ({
    ...state,
    positionsStatus: new Updating(state.positionsStatus, null)
  })),

  on(SurveysViewsActions.replacePositionsStatus, (state, {status}) => ({
    ...state,
    positionsStatus: status
  })),

  on(SurveysViewsActions.addPositions, (state, payload) => ({
    ...state,
    positions: [...state.positions, ...payload.positions]
  })),

  on(SurveysViewsActions.replacePositions, (state, {positions}) => ({
    ...state,
    positions
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return surveyViewsReducer(state, action);
}

export const getSelectedSurveyViewId = (state: State) => state.selectedSurveyViewId;
export const getQueryResult = (state: State) => state.queryResults;
export const getPagination = (state: State) => state.pagination;
export const getSurveysViews = (state: State) => state.surveysViews;
export const getPositionsStatus = (state: State) => state.positionsStatus;
export const getPositions = (state: State) => state.positions;
