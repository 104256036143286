import {
  Component,
  EventEmitter,
  forwardRef,
  HostBinding, Injectable,
  Injector,
  Input, OnDestroy,
  OnInit,
  Output, Type,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

export interface RadioChangeEvent {
  name: string;
  value: string;
}

@Injectable()
export class DsRadioControlRegistry {
  private _accessors: any[] = [];

  add(control: NgControl, accessor: RadioComponent) {
    this._accessors.push([control, accessor]);
  }

  remove(accessor: RadioComponent) {
    for (let i = this._accessors.length - 1; i >= 0; --i) {
      if (this._accessors[i][1] === accessor) {
        this._accessors.splice(i, 1);
        return;
      }
    }
  }

  select(accessor: RadioComponent) {
    this._accessors.forEach((c) => {
      if (this._isSameGroup(c, accessor) && c[1] !== accessor) {
        c[1].fireUncheck(accessor.value);
      }
    });
  }

  private _isSameGroup(controlPair: [NgControl, RadioComponent],
                       accessor: RadioComponent): boolean {
    if (!controlPair[0].control) {
      return false;
    }

    return controlPair[0]['_parent'] === accessor._control['_parent'] && controlPair[1].name === accessor.name;
  }
}

@Component({
  selector: 'ds-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RadioComponent),
    multi: true
  }]
})
export class RadioComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @HostBinding('class.disabled')
  get disabledValue() {
    return this.disabled;
  }

  @ViewChild('radio', { static: true }) inputElement;

  @Input() id: string;
  @Input() name: string;
  @Input() formControlName: string;
  @Input() content: string;
  @Input() code: string;
  @Input() value: string;
  @Input()
  get checked(): boolean {
    return this._checked;
  }
  set checked(value: boolean) {
    this._checked = value;
    this.inputElement.nativeElement.checked = this._checked;
  }

  @Output() readonly change = new EventEmitter<RadioChangeEvent>();

  _checked: boolean;
  disabled: boolean;

  _control: NgControl;

  _fn: Function;
  onChange = () => {};
  onTouched = () => {};

  constructor(private registry: DsRadioControlRegistry,
              private injector: Injector) {}

  ngOnInit(): void {
    this._control = this.injector.get<NgControl>(NgControl);
    this.checkName();
    this.registry.add(this._control, this);
  }

  inputBlur() {
    this.onTouched();
  }

  inputClicked(event: Event) {
    event.stopPropagation();
  }

  inputValueChange(event: Event) {
    event.stopPropagation();

    this._checked = this.inputElement.nativeElement.checked;

    this.emitChangeEvent();
  }

  emitChangeEvent() {
    this.onChange();
    if (this._checked) {
      this.change.emit({name: this.name, value: this.value});
    }
  }

  registerOnChange(fn: any): void {
    this._fn = fn;
    this.onChange = () => {
      fn(this.value);
      this.registry.select(this);
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.checked = value === this.value;
  }

  fireUncheck(value: any): void {
    this.writeValue(value);
  }

  ngOnDestroy(): void { this.registry.remove(this); }

  private checkName(): void {
    if (this.name && this.formControlName && this.name !== this.formControlName) {
      this.throwNameError();
    }
    if (!this.name && this.formControlName) {
      this.name = this.formControlName;
    }
  }

  private throwNameError(): void {
    throw new Error(`
      [RadioComponent]
      If you define both a name and a formControlName attribute on your radio button, their values
      must match. Ex: <ds-radio formControlName="food" name="food">
    `);
  }
}
