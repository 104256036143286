<div class="select-wrapper"
     tabindex="0"
     [style.width]="width && (width + 'px')"
     [style.height]="height && (height + 'px')"
     [class.is-open]="isOpen"
     [class.disabled]="isDisabled"
     [class.slim]="slim"
     (click)="open($event)">

    <div [innerHTML]="selectedOption ? (selectedOption.selectedLabel || selectedOption.label ) : placeholder"
         class="selected-option-wrapper"
    ></div>

    <ds-chevron-down-icon class="icon"></ds-chevron-down-icon>
</div>

<ng-template #menu>
    <ul class="select-option-wrapper {{optionClassName}}">
        <li *ngFor="let option of options"
            [class.is-selected]="isSelected(option)"
            (click)="selectOption(option)"
            [innerHTML]="option.label">
        </li>
    </ul>
</ng-template>
