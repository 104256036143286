import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef, HostBinding,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  EventEmitter
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { fromEvent, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { SelectOption } from '@shared/models/select-option.model';
import { optionsTemplates, OptionsTemplate } from '@shared/components/select/option-templates';

@Component({
  selector: 'ds-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectComponent),
    }
  ]
})
export class SelectComponent implements ControlValueAccessor, OnInit {
  @HostBinding('class.disabled')
  get disabledValue() {
    return this.disabled;
  }

  @Input() options: Array<SelectOption>;
  @Input() placeholder = '&nbsp;';
  @Input() useAutoWidth: boolean;
  @Input() optionsTemplate: OptionsTemplate;
  @Input() slim: boolean;
  @Input() width: number = null;
  @Input() height: number = null;
  @Input() optionClassName: string;
  @Input() isDisabled: boolean;

  @Output() selectedValue = new EventEmitter<string|number>();

  @ViewChild('menu', { static: true }) menu: TemplateRef<any>;

  overlayRef: OverlayRef;
  sub: Subscription;

  isOpen = false;
  disabled: boolean;

  selectedOption: SelectOption | null = null;
  private onModelChange = (value: any) => {};
  private onTouched = () => {};

  constructor(
    private cdr: ChangeDetectorRef,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
  ) {
  }

  ngOnInit(): void {
    if (this.optionsTemplate) {
      this.options = optionsTemplates.get(this.optionsTemplate);
    }

    if (this.options && this.useAutoWidth) {
      this.width = (this.placeholder && this.placeholder.length || 0) * 10 + 50;

      this.options.forEach((option) => {
        const computedWidth = option.label.length * 10 + 50;

        this.width = computedWidth > this.width ? computedWidth : this.width;
      });
    }
  }

  registerOnChange(fn: (value: any) => any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if (isDisabled) {
      this.close();
    }
  }

  writeValue(value: any): void {
    if (this.options && this.options.find) {
      this.selectedOption = this.options.find((option) => option.value === value) || null;
    }
  }

  isSelected(option: SelectOption): boolean {
    return this.selectedOption && option.value === this.selectedOption.value;
  }

  selectOption(option: SelectOption): void {
    this.selectedOption = option;
    this.selectedValue.emit(option.value);
    this.close();
    this.onModelChange(option.value);
  }

  open(event: FocusEvent | MouseEvent) {
    event.stopPropagation();

    if (this.disabled) {
      return;
    }

    if (this.isOpen) {
      this.close();
      return;
    }

    this.onTouched();

    this.close();
    const el: any = event.currentTarget;

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(new ElementRef(el))
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
      width: 'auto',
      minWidth: el.offsetWidth || 100,
    });

    this.overlayRef.attach(new TemplatePortal(this.menu, this.viewContainerRef, {}));

    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter((e) => {
          const clickTarget = e.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1),
      )
      .subscribe(() => this.close());

    this.isOpen = true;
    this.cdr.markForCheck();
  }

  close() {
    if (this.sub) {
      this.sub.unsubscribe();
      this.sub = null;
    }

    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }

    this.isOpen = false;

    this.cdr.markForCheck();
  }
}

