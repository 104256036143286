import { SearchNode } from '@app/surveys/models/search-node.model';
import { Block, BundleQuestion, Node, QuestionOption } from '@app/surveys/models/survey-plan-elements.model';
import { SurveyView } from '@app/surveys/models/survey.model';

export enum ConditionOwnerType {
  Block = '[Condition Owner] Block',
  Node = '[Condition Owner] Node',
  QuestionOption = '[Condition Owner] Question Option',
  BundleQuestion = '[Condition Owner] Bundle Question',
}

export enum ConditionDecisionType {
  Node = '[Condition Decision] - Node',
  // Survey = '[Condition Decision] - Survey',
  // Profile = 'Condition Decision - Profile',
  // MeatData = 'Condition Decision - MetaData',
  // Device = 'Condition Decision - Device'
}

export interface ConditionOwner {
  type: ConditionOwnerType;
  data: SurveyView | Block | Node | QuestionOption | BundleQuestion;
}

export class ConditionBlockOwner implements ConditionOwner {
  readonly type = ConditionOwnerType.Block;

  constructor(readonly data: Block) {
  }
}

export class ConditionNodeOwner implements ConditionOwner {
  readonly type = ConditionOwnerType.Node;

  constructor(readonly data: Node) {
  }
}

export class ConditionQuestionOptionOwner implements ConditionOwner {
  readonly type = ConditionOwnerType.QuestionOption;

  constructor(readonly data: QuestionOption) {
  }
}

export class ConditionBundleOptionOwner implements ConditionOwner {
  readonly type = ConditionOwnerType.BundleQuestion;

  constructor(readonly data: BundleQuestion) {
  }
}

export type ConditionOwners =
  ConditionBlockOwner
  | ConditionNodeOwner
  | ConditionQuestionOptionOwner;

export interface ConditionDecision {
  type: ConditionDecisionType;
  data: SearchNode;
}

export class ConditionNodeDecision implements ConditionDecision {
  readonly type = ConditionDecisionType.Node;

  constructor(readonly data: SearchNode) {
  }
}

export type ConditionDecisions = ConditionNodeDecision;

export enum LogicModalSearchMode {
  Question = 'question'
}

export interface AddLogicOwnerModalData {
  owner: ConditionOwners;
  mode: LogicModalSearchMode;
}

export interface AddLogicOwnerModalResponse {
  owner: ConditionOwners;
  decision: ConditionDecisions | false;
}
