<svg
  *ngIf="!checked"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.27986 4.58447C4.63328 4.58447 4.10986 5.10789 4.10986 5.75447V15.3545C4.10986 16.0011 4.63328 16.5245 5.27986 16.5245H7.19986C7.61408 16.5245 7.94986 16.8603 7.94986 17.2745V20.1526C7.94986 20.3338 8.16415 20.43 8.29967 20.3094L8.29978 20.3093L12.3414 16.7141C12.4787 16.592 12.6561 16.5245 12.8399 16.5245H18.7199C19.3665 16.5245 19.8899 16.0011 19.8899 15.3545V5.75447C19.8899 5.10789 19.3665 4.58447 18.7199 4.58447H5.27986ZM2.60986 5.75447C2.60986 4.27946 3.80485 3.08447 5.27986 3.08447H18.7199C20.1949 3.08447 21.3899 4.27946 21.3899 5.75447V15.3545C21.3899 16.8295 20.1949 18.0245 18.7199 18.0245H13.1252L9.29686 21.4299L9.29674 21.43C8.19385 22.4114 6.44986 21.6282 6.44986 20.1526V18.0245H5.27986C3.80485 18.0245 2.60986 16.8295 2.60986 15.3545V5.75447ZM6.4502 8.15039C6.4502 7.73618 6.78598 7.40039 7.2002 7.40039H16.8002C17.2144 7.40039 17.5502 7.73618 17.5502 8.15039C17.5502 8.5646 17.2144 8.90039 16.8002 8.90039H7.2002C6.78598 8.90039 6.4502 8.5646 6.4502 8.15039ZM7.2002 11.7202C6.78598 11.7202 6.4502 12.056 6.4502 12.4702C6.4502 12.8844 6.78598 13.2202 7.2002 13.2202H13.9202C14.3344 13.2202 14.6702 12.8844 14.6702 12.4702C14.6702 12.056 14.3344 11.7202 13.9202 11.7202H7.2002Z"
    fill="currentColor"
  />
</svg>

<svg
  *ngIf="checked"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.60986 5.75447C2.60986 4.27946 3.80485 3.08447 5.27986 3.08447H18.7199C20.1949 3.08447 21.3899 4.27946 21.3899 5.75447V15.3545C21.3899 16.8295 20.1949 18.0245 18.7199 18.0245H13.1252L9.29686 21.4299L9.29674 21.43C8.19385 22.4114 6.44986 21.6282 6.44986 20.1526V18.0245H5.27986C3.80485 18.0245 2.60986 16.8295 2.60986 15.3545V5.75447ZM6.4502 8.15039C6.4502 7.73618 6.78598 7.40039 7.2002 7.40039H16.8002C17.2144 7.40039 17.5502 7.73618 17.5502 8.15039C17.5502 8.5646 17.2144 8.90039 16.8002 8.90039H7.2002C6.78598 8.90039 6.4502 8.5646 6.4502 8.15039ZM6.4502 12.4702C6.4502 12.056 6.78598 11.7202 7.2002 11.7202H13.9202C14.3344 11.7202 14.6702 12.056 14.6702 12.4702C14.6702 12.8844 14.3344 13.2202 13.9202 13.2202H7.2002C6.78598 13.2202 6.4502 12.8844 6.4502 12.4702Z"
    fill="currentColor"
  />
</svg>
