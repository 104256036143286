import { ErrorHandler, Injectable, Injector } from '@angular/core';
import Bugsnag, { Client } from '@bugsnag/js';
import { filter, take } from 'rxjs/operators';

import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import * as fromCore from '@core/reducers';

@Injectable()
export class BugsnagErrorHandler extends ErrorHandler {
  bugsnagClient: Client | null = null;

  constructor(private readonly injector: Injector) {
    super();
    if (environment.bugsnagEnabled) {
      this.bugsnagClient = Bugsnag.start({
        apiKey: environment.bugsnagKey || '',
        enabledReleaseStages: ['production', 'staging', `testing`],
        releaseStage: environment.bugsnagStage,
        appVersion: environment.appVersion
      });
    }
  }

  handleError(error: any): void {
    if (this.bugsnagClient === null) {
      ErrorHandler.prototype.handleError.call(this, error);
      return;
    }

    const handledState = {
      severity: 'error',
      severityReason: {type: 'unhandledException'},
      unhandled: true,
    };

    const event = this.bugsnagClient.Event.create(
      error,
      true,
      handledState,
      'angular error handler',
      1
    );

    event.setUser(this.getUserId());

    if (error.ngDebugContext) {
      event.addMetadata('angular', {
        component: error.ngDebugContext.component,
        context: error.ngDebugContext.context,
      });
    }

    this.bugsnagClient._notify(event);

    ErrorHandler.prototype.handleError.call(this, error);

    // console.error(error);
  }

  private getUserId(): string {
    // This should be written so its synchronous but given store' sync ability it's ok for this example
    let userUuid = 'no-uuid';

    const store = this.injector.get<Store<fromCore.State>>(Store);

    store.pipe(
      select(fromCore.userProfile),
      take(1),
      filter((user) => user !== null),
    )
      .subscribe((user) => {
        userUuid = user.uuid;
      });

    return userUuid;
  }
}
