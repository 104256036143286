import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ConditionOwnerType, ConditionOwners } from '@app/shared/models/search-models';
import { NodeDataType } from '@app/surveys/models/survey-plan-elements.model';

@Component({
  selector: 'ds-element-preview-panel',
  templateUrl: './element-preview-panel.component.html',
  styleUrls: ['./element-preview-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ElementPreviewPanelComponent {
  @Input() owner: ConditionOwners;
  @Input() text: string;

  readonly conditionOwnerType = ConditionOwnerType;
  readonly nodeDataType = NodeDataType;

  get isOwnerBlock() {
    return this.owner && this.owner.type === ConditionOwnerType.Block;
  }
}
