import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NodeIconType } from '@shared/components/element-icon/element-icon.component';
import { ElementText, TextData } from '@app/surveys/models/survey-plan-elements.model';

@Component({
  selector: 'ds-text-element-preview',
  templateUrl: './text-element-preview.component.html',
  styleUrls: ['./text-element-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextElementPreviewComponent {
  @Input() data: TextData;

  constructor() {
  }

  get displayText(): ElementText {
    return this.data.text;
  }

  get iconType(): NodeIconType {
    return 'text';
  }
}
