import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-spinner-icon',
  templateUrl: './spinner-icon.component.html',
  styleUrls: ['./spinner-icon.component.scss']
})
export class SpinnerIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
