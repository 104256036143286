import { NgModule } from '@angular/core';
import { AbacusIconComponent } from './abacus-icon/abacus-icon.component';
import { AgeIconComponent } from './age-icon/age-icon.component';
import { BlockIconComponent } from './block-icon/block-icon.component';
import { BundleIconComponent } from './bundle-icon/bundle-icon.component';
import { ChevronDownIconComponent } from './chevron-down-icon/chevron-down-icon.component';
import { ChevronLeftIconComponent } from './chevron-left-icon/chevron-left-icon.component';
import { ChevronRightIconComponent } from './chevron-right-icon/chevron-right-icon.component';
import { ChevronUpIconComponent } from './chevron-up-icon/chevron-up-icon.component';
import { CloseIconComponent } from './close-icon/close-icon.component';
import { CodeBranchIconComponent } from './code-branch-icon/code-branch-icon.component';
import { CopyIconComponent } from './copy-icon/copy-icon.component';
import { DownloadIconComponent } from './download-icon/download-icon.component';
import { ExclusiveIconComponent } from './exclusive-icon/exclusive-icon.component';
import { FileCheckIconComponent } from './file-check-icon/file-check-icon.component';
import { FileIconComponent } from './file-icon/file-icon.component';
import { GenderIconComponent } from './gender-icon/gender-icon.component';
import { GlobeAmericasIconComponent } from './globe-americas-icon/globe-americas-icon.component';
import { GoogleIconComponent } from './google-icon/google-icon.component';
import { HelpIconComponent } from './help-icon/help-icon.component';
import { InfoIconComponent } from './info-icon/info-icon.component';
import { LinkIconComponent } from './link-icon/link-icon.component';
import { MultipleChoiceIconComponent } from './multiple-choice-icon/multiple-choice-icon.component';
import { OpenTextEntryIconComponent } from './open-text-entry-icon/open-text-entry-icon.component';
import { PencilAltIconComponent } from './pencil-alt-icon/pencil-alt-icon.component';
import { PinIconComponent } from './pin-icon/pin-icon.component';
import { PlusIconComponent } from './plus-icon/plus-icon.component';
import { QuotaIconComponent } from './quota-icon/quota-icon.component';
import { RandomIconComponent } from './random-icon/random-icon.component';
import { ScreenOutIconComponent } from './screen-out-icon/screen-out-icon.component';
import { SearchIconComponent } from './search-icon/search-icon.component';
import { SingleChoiceIconComponent } from './single-choice-icon/single-choice-icon.component';
import { TextIconComponent } from './text-icon/text-icon.component';
import { TranslationsIconComponent } from './translations-icon/translations-icon.component';
import { TrashIconComponent } from './trash-icon/trash-icon.component';
import { WebhookIconComponent } from './webhook-icon/webhook-icon.component';
import { SpinnerIconComponent } from './spinner-icon/spinner-icon.component';
import { TickIconComponent } from './tick-icon/tick-icon.component';
import { CommonModule } from '@angular/common';
import { ImageIconComponent } from './image-icon/image-icon.component';
import { WarningIconComponent } from './warning-icon/warning-icon/warning-icon.component';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AbacusIconComponent,
    AgeIconComponent,
    BlockIconComponent,
    BundleIconComponent,
    ChevronDownIconComponent,
    ChevronLeftIconComponent,
    ChevronRightIconComponent,
    ChevronUpIconComponent,
    CloseIconComponent,
    CodeBranchIconComponent,
    CopyIconComponent,
    DownloadIconComponent,
    ExclusiveIconComponent,
    FileCheckIconComponent,
    FileIconComponent,
    GenderIconComponent,
    GlobeAmericasIconComponent,
    GoogleIconComponent,
    HelpIconComponent,
    InfoIconComponent,
    LinkIconComponent,
    MultipleChoiceIconComponent,
    OpenTextEntryIconComponent,
    PencilAltIconComponent,
    PinIconComponent,
    PlusIconComponent,
    QuotaIconComponent,
    RandomIconComponent,
    ScreenOutIconComponent,
    SearchIconComponent,
    SingleChoiceIconComponent,
    TextIconComponent,
    TranslationsIconComponent,
    TrashIconComponent,
    WebhookIconComponent,
    SpinnerIconComponent,
    TickIconComponent,
    ImageIconComponent,
    WarningIconComponent
  ],
  exports: [
    AbacusIconComponent,
    AgeIconComponent,
    BlockIconComponent,
    BundleIconComponent,
    ChevronDownIconComponent,
    ChevronLeftIconComponent,
    ChevronRightIconComponent,
    ChevronUpIconComponent,
    CloseIconComponent,
    CodeBranchIconComponent,
    CopyIconComponent,
    DownloadIconComponent,
    ExclusiveIconComponent,
    FileCheckIconComponent,
    FileIconComponent,
    GenderIconComponent,
    GlobeAmericasIconComponent,
    GoogleIconComponent,
    HelpIconComponent,
    InfoIconComponent,
    LinkIconComponent,
    MultipleChoiceIconComponent,
    OpenTextEntryIconComponent,
    PencilAltIconComponent,
    PinIconComponent,
    PlusIconComponent,
    QuotaIconComponent,
    RandomIconComponent,
    ScreenOutIconComponent,
    SearchIconComponent,
    SingleChoiceIconComponent,
    TextIconComponent,
    TranslationsIconComponent,
    TrashIconComponent,
    WebhookIconComponent,
    SpinnerIconComponent,
    TickIconComponent,
    ImageIconComponent,
    WarningIconComponent
  ]
})
export class TabulaIconModule {
}
