import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as _throw, of, merge } from 'rxjs';
import { AuthService, GwiRefreshTokenModel } from '@core/services';
import { catchError, filter, mergeMap, map, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { GWI_API_PRODUCTION, GWI_API_STAGING } from '../constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const auth: AuthService = this.injector.get(AuthService);

    return this.handleRequest(auth, req, next).pipe(
      catchError((response) =>
        merge(
          of(response.status).pipe(
            filter((status) => status !== 401),
            mergeMap(() => _throw(response))
          ),
          of(response.status).pipe(
            filter((status) => status === 401),
            mergeMap(() =>
              merge(
                this.authErrorHasAuthGrant(response, auth).pipe(
                  mergeMap(() => this.handleRequest(auth, req, next))
                ),
                this.authErrorNoAuthGrant(response, auth)
              )
            )
          )
        )
      )
    );
  }

  authErrorHasAuthGrant(
    error: HttpErrorResponse,
    auth: AuthService
  ): Observable<GwiRefreshTokenModel | never> {
    return of(error.status).pipe(
      filter((status) => status === 401),
      mergeMap(() => auth.accessToken$),
      filter((accessToken: string) => accessToken !== null),
      mergeMap(() => {
        auth.logout();
        return _throw(new Error('Unknown authorisation error!'));
      })
    );
  }

  authErrorNoAuthGrant(
    error: HttpErrorResponse,
    auth: AuthService
  ): Observable<never> {
    return of(error.status).pipe(
      filter((status) => status === 401),
      mergeMap(() => auth.accessToken$),
      filter((accessToken: string) => accessToken === null),
      tap(() => auth.logout()),
      mergeMap(() => _throw(error))
    );
  }

  handleRequest(
    auth: AuthService,
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const gwiRefreshUrl = `${environment.runningTestEnv ? GWI_API_STAGING : GWI_API_PRODUCTION}/v1/users-next/refresh_tokens`;
    const isRefreshRequest = req.url === gwiRefreshUrl;
    if (isRefreshRequest) {
      return auth.refreshHeader$.pipe(
        map((header: string) => req.clone(this.setReqHeaders(req, header))),
        mergeMap((authReq: HttpRequest<any>) => next.handle(authReq))
      );
    } else {
      return auth.authorizationHeader$.pipe(
        map((header: string) => req.clone(this.setReqHeaders(req, header))),
        mergeMap((authReq: HttpRequest<any>) => next.handle(authReq))
      );
    }
  }

  setReqHeaders(req: HttpRequest<any>, header: string): { setHeaders: {} } {
    return {
      setHeaders: {
        Authorization: header,
        ...(!req.headers.has('Accept') && {
          Accept: 'application/json',
        }),
        ...(!(req.body instanceof FormData) && {
          'Content-Type': 'application/json',
        }),
      },
    };
  }
}
