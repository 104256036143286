import { ElementType, QuestionElementType } from '@app/languages/models/translations.models';
import { Loading, NotAsked, RemoteData, Success } from '@app/remote-data/models/remote-data.model';
import { Block, BlockModel, BundleNodeModel, Node, NodeModel, NodeModelType, QuestionElementModel, QuestionNodeModel, toBlock, toNode } from '@app/surveys/models/survey-plan-elements.model';
import { PaginationModel } from '@shared/models/meta.model';

/******************************
 API
 *****************************/

export type SurveyModelState = 'archived' | 'draft' | 'duplicating' | 'active';

export type CampaignModelState = 'active' | 'deactivated';

export type SurveyPermittedEventModel = 'archive' | 'to_draft' | 'activate';

// API - SURVEYS MANAGER RESPONSE

export interface SurveysResponseModel {
  surveys: SurveyViewModel[];
  meta: PaginationModel;
}

export interface CampaignModel {
  code: string;
  id: string;
  state: CampaignModelState;
}

export interface SurveyViewModel {
  id: string;
  name: string;
  campaigns: CampaignModel[];
  state: SurveyModelState;
  created_at: string;
  updated_at: string | null;
  number_of_questions: number;
  permitted_events: SurveyPermittedEventModel[];
  language_ids: string[];
}

// API - SURVEYS MANAGER CRUD

export interface SurveysItemCreateModel {
  name: string;
}

// API - SURVEY BUILDER RESPONSE

export interface SurveyPlanModel {
  id: string;
  name: string;
  row: number;
  state: SurveyModelState;
  created_at: string;
  updated_at: string | null;
  has_random_order: boolean;
  blocks: BlockModel[];
  structure?: StructureModel | null;
  block_ids: string[];
}

export interface StructureModel {
  randomize_groups: boolean;
  groups: StructureGroupModel[];
}

export interface StructureGroupModel {
  randomize_items: boolean;
  fixed: boolean;
  name: string;
  items: StructureGroupItemModel[];
  uuid?: string; // Only needed on Question level
}

export interface StructureGroupItemModel {
  id: string;
  fixed: boolean;
}

// API - SURVEY PREVIEW

export interface SurveyPreviewModel {
  version_uuid: string;
}

export interface SearchResultOptionsModel {
  id: string;
  name: string;
  name_html: string;
  element_type: ElementType;
  code: string;
}

interface BaseSearchResultNodeModel {
  id: string;
  name: string;
  name_html: string;
  element_type: ElementType;
  code: string;
}

export interface TextSearchResultNodeModel extends BaseSearchResultNodeModel {
  element_type: Extract<ElementType, 'TextNode' | 'QuestionOption' | 'Bundle::Question' | 'Bundle::QuestionOption'>;
}

interface QuestionSearchResultNodeModel extends BaseSearchResultNodeModel {
  element_type: QuestionElementType;
  question_options: TextSearchResultNodeModel[];
}

interface BundleSearchResultNodeModel extends BaseSearchResultNodeModel {
  element_type: 'Bundle::Node';
  bundle_question_options: TextSearchResultNodeModel[];
  bundle_questions: TextSearchResultNodeModel[];
}

export type SearchResultNodeModel = TextSearchResultNodeModel | QuestionSearchResultNodeModel | BundleSearchResultNodeModel;

export interface SearchElementsResponseModel {
  survey_elements: SearchResultNodeModel[];
}

/******************************
 DASH
 *****************************/
// DASH - TRANSLATIONS

export interface SurveyDescription {
  uuid: string;
  name: string;
  state: SurveyState;
}

// DASH - SURVEYS MANAGER

export enum SurveyState {
  Active = '[Survey] Active',
  Archived = '[Survey] Archived',
  Draft = '[Survey] Draft',
  Duplicating = '[Survey] Duplicating'
}

export enum CampaignState {
  Active = '[Campaign] Active',
  Deactivated = '[Campaign] Deactivated',
}

export interface Campaign {
  id: string;
  code: string;
  state: CampaignState;
}

export interface SurveyView {
  uuid: string;
  name: string;
  campaigns: Campaign[];
  state: SurveyState;
  createdAt: Date | null;
  updatedAt: Date | null;
  numberOfQuestions: number;
  permittedSurveyStates: SurveyState[];
  languageIds: string[];
}

// DASH - SURVEY BUILDER

export interface SurveyPlan {
  uuid: string;
  name: string;
  state: SurveyState;
  createdAt: Date | null;
  updatedAt: Date | null;
}

export interface Structure {
  randomizeGroups: boolean;
  groups: StructureGroup[];
}

export interface StructureGroup {
  randomizeItems: boolean;
  fixed: boolean;
  text: string;
  items: StructureGroupItem[];
  uuid?: string; // Only required on Question level
}

export interface StructureGroupItem {
  uuid: string;
  fixed: boolean;
  text: string;
  code: string;
}

// HELPERS

export const SurveyStateMap = new Map<SurveyModelState, SurveyState>([
  ['active', SurveyState.Active],
  ['archived', SurveyState.Archived],
  ['draft', SurveyState.Draft],
  ['duplicating', SurveyState.Duplicating],
]);

export const SurveyModelStateMap = new Map<SurveyState, SurveyModelState>([
  [SurveyState.Active, 'active'],
  [SurveyState.Archived, 'archived'],
  [SurveyState.Draft, 'draft'],
  [SurveyState.Duplicating, 'duplicating']
]);

export const CampaignStateMap = new Map<CampaignModelState, CampaignState>([
  ['active', CampaignState.Active],
  ['deactivated', CampaignState.Deactivated]
]);

export const CampaignModelStateMap = new Map<CampaignState, CampaignModelState>([
  [CampaignState.Active, 'active'],
  [CampaignState.Deactivated, 'deactivated'],
]);

export const SurveyPermittedEventMap = new Map<SurveyPermittedEventModel, SurveyState>([
  ['activate', SurveyState.Active],
  ['archive', SurveyState.Archived],
  ['to_draft', SurveyState.Draft]
]);

// HELPERS - SURVEYS MANAGER

export function toSurveyViewModel(surveyView: SurveyView): Partial<SurveyViewModel> {
  return {
    id: surveyView.uuid,
    name: surveyView.name,
    state: SurveyModelStateMap.get(surveyView.state),
    language_ids: surveyView.languageIds
  };
}

function toCampaign(model: CampaignModel): Campaign {
  return {
    id: model.id,
    code: model.code,
    state: CampaignStateMap.get(model.state)
  };
}
export function toSurveyView(model: SurveyViewModel): SurveyView {
  return {
    uuid: model.id,
    name: model.name || '',
    campaigns: model.campaigns.map(campaignModel => toCampaign(campaignModel)),
    state: SurveyStateMap.get(model.state),
    createdAt: model.created_at ? new Date(model.created_at) : null,
    updatedAt: model.updated_at ? new Date(model.updated_at) : null,
    numberOfQuestions: model.number_of_questions || 0,
    permittedSurveyStates: toPermittedSurveyStates(model.state, model.permitted_events),
    languageIds: model.language_ids
  };
}

export function toPermittedSurveyStates(stateModel: SurveyModelState, permittedEventsModel: SurveyPermittedEventModel[]): SurveyState[] {
  return permittedEventsModel.map((event) => SurveyPermittedEventMap.get(event)).concat(SurveyStateMap.get(stateModel));
}

// HELPERS - SURVEY BUILDER

export interface SurveyPlanComponents {
  survey: SurveyPlan;
  surveyStructure: RemoteData<Structure>;
  blocks: Map<string, RemoteData<Block>>;
  blocksStructures: Map<string, RemoteData<Structure>>;
  nodes: Map<string, RemoteData<Node>>;
  nodesStructures: Map<string, RemoteData<Structure>>;
}

export interface SurveyPlanDetails {
  survey: SurveyPlan;
  surveyStructure: RemoteData<Structure>;
  blocksToLoad: Map<string, RemoteData<Block>>;
  blocksStructuresToLoad: Map<string, RemoteData<Structure>>;
}

export interface SurveyPlanBlock {
  block: Block;
  blockStructure: RemoteData<Structure>;
  nodes: NodeModel[];
}

interface SurveyPlanNodeBase {
  node: Node;
}

export interface QuestionNode extends SurveyPlanNodeBase {
  nodeStructure: RemoteData<Structure>;
}

export interface BundleNode extends SurveyPlanNodeBase {
  questionsStructure: RemoteData<Structure>;
  questionOptionsStructure: RemoteData<Structure>;
}

export type SurveyPlanNode = SurveyPlanNodeBase | QuestionNode | BundleNode;

export function toSurveyPlan(model: SurveyPlanModel): SurveyPlan {
  return {
    uuid: model.id || '',
    name: model.name || '',
    state: SurveyStateMap.get(model.state),
    createdAt: model.created_at ? new Date(model.created_at) : null,
    updatedAt: model.updated_at ? new Date(model.updated_at) : null,
  };
}

export function toSurveyPlanBlocks(models: BlockModel[]): Map<string, RemoteData<Block>> {
  const entries = models.map((model) =>
    <[string, RemoteData<Block>]>[model.id, new Success(toBlock(model))]);
  return new Map(entries);
}

export function toSurveyPlanLoadingBlocks(uuids: string[]): Map<string, RemoteData<Block>> {
  const entries = uuids.map((uuid) =>
    <[string, RemoteData<Block>]>[uuid, new Loading()]);
  return new Map(entries);
}

export function toSurveyPlanLoadingBlocksStructures(uuids: string[]): Map<string, RemoteData<Structure>> {
  const entries = uuids.map((uuid) =>
    <[string, RemoteData<Structure>]>[uuid, new Loading()]);
  return new Map(entries);
}

export function toSurveyPlanNodes(models: NodeModel[]): Map<string, RemoteData<Node>> {
  const entries = models.map((model) =>
    <[string, RemoteData<Node>]>[model.id, new Success(toNode(model))]);
  return new Map(entries);
}

export function toSurveyPlanElementStructures(models: Array<BlockModel>): Map<string, RemoteData<Structure>> {
  const entries = models.map((model) => {
      const structure = (model.structure && Object.keys(model.structure).length !== 0)
        ? toStructure(model.structure)
        : generateStructure(model.nodes);

      return <[string, RemoteData<Structure>]>[model.id, new Success(structure)];
    }
  );
  return new Map(entries);
}

export function toSurveyPlanElementStructure(model: BlockModel): RemoteData<Structure> {
  return new Success(
    (model.structure && Object.keys(model.structure).length !== 0)
      ? toStructure(model.structure)
      : generateStructure(model.nodes)
  );
}

export function toSurveyPlanStructure(model: SurveyPlanModel): RemoteData<Structure> {
  return (model.structure && Object.keys(model.structure).length !== 0)
    ? new Success(toStructure(model.structure))
    : new Success(generateStructure(model.blocks));
}

export function toSurveyPlanNodesStructures(models: Array<NodeModel>): Map<string, RemoteData<Structure>> {
  const entries = models.filter((model) =>
    model.type === 'RadioQuestion' || model.type === 'MultipleQuestion' || model.type === 'LanguageQuestion')
    .map((model) => {
    return <[string, RemoteData<Structure>]>[model.id, new Success(toQuestionStructure(<QuestionElementModel>model.element))];
  });

  return new Map(entries);
}


export function toQuestionStructure(model: QuestionElementModel): Structure {
  return toStructure(model.structure);
}

export function toSurveyPlanNode(model: NodeModel): SurveyPlanNode {
  return {
    node: toNode(model),
    ...(isQuestionNode(model) && {
      nodeStructure: new Success(toStructure(model.element.structure)),
    }),
    ...(isBundleNode(model) && {
      questionsStructure: new Success(toStructure(model.element.questions_structure)),
      questionOptionsStructure: new Success(toStructure(model.element.question_options_structure)),
    })
  };
}

function isBundleNode(model: NodeModel): model is BundleNodeModel {
  return model.type === 'Bundle::Node';
}


export function isQuestionNode(model: NodeModel): model is QuestionNodeModel {
  return ([
    'RadioQuestion',
    'MultipleQuestion',
    'LanguageQuestion',
    'OpenEndedQuestion',
  ] as NodeModelType[]).includes(model.type);
}

// HELPERS - STRUCTURE

export function generateStructure(items: Array<BlockModel | NodeModel>): Structure {
  return {
    randomizeGroups: false,
    groups: items.map((item) => ({
      randomizeItems: false,
      fixed: false,
      text: 'Group',
      items: [{ uuid: item.id, fixed: false, text: '', code: '' }]
    }))
  };
}

function transformSingleGroupToItemGroups(model: StructureModel): Structure {
  const group = model.groups[0];
  const groupItemsModel: StructureGroupItemModel[] = model.groups[0].items;
  const structureGroup: StructureGroup[] = [];

  groupItemsModel.forEach((item) => {
    structureGroup.push({
      randomizeItems: group.randomize_items,
      fixed: group.fixed,
      text: group.name,
      items: [{
        uuid: item.id,
        fixed: item.fixed,
        text: '',
        code: ''
      }]
    });
  });
  return {
    randomizeGroups: model.randomize_groups,
    groups: structureGroup
  };
}

export function toStructure(model: StructureModel): Structure {
  if (model.groups.length === 1) {
    return transformSingleGroupToItemGroups(model);
  }
    return {
      randomizeGroups: model.randomize_groups,
      groups: model.groups.map((group) => ({
        randomizeItems: group.randomize_items,
        fixed: group.fixed,
        text: group.name,
        items: group.items.map((item) => (
          {
            uuid: item.id,
            fixed: item.fixed,
            text: '',
            code: ''
          }))
      }))
    };
}

export function toStructureModel(data: Structure): StructureModel {
  return {
    randomize_groups: data.randomizeGroups,
    groups: data.groups.map((group) => ({
      randomize_items: group.randomizeItems,
      fixed: group.fixed,
      name: group.text,
      items: group.items.map((item) => ({
        id: item.uuid,
        fixed: item.fixed
      }))
    }))
  };
}
