<ng-container [ngSwitch]="iconType">
  <ds-block-icon *ngSwitchCase="'block'"></ds-block-icon>

  <ds-text-icon *ngSwitchCase="'text'"></ds-text-icon>

  <svg *ngSwitchCase="'image'" class="icon" width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2"><path d="M4 7.75l2.86 3.51 4-5.26L16 14H0l4-6.25z" fill="#fff" fill-rule="nonzero"/></svg>

  <ds-single-choice-icon *ngSwitchCase="'single'"></ds-single-choice-icon>

  <ds-multiple-choice-icon *ngSwitchCase="'multiple'"></ds-multiple-choice-icon>

  <ds-open-text-entry-icon *ngSwitchCase="'open'" class="icon"></ds-open-text-entry-icon>

  <svg *ngSwitchCase="'ranked'" class="icon" width="16px" height="16px" viewBox="0 0 16 16"
       xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2"><path d="M2 5h2v10h2V4.99L8 5 5 1 2 5zm8.58-1.58L10 2.39l.06-.2L11.88 1h.92l.2.17v5.66l-.2.17h-1.1l-.2-.17v-4l-.92.59zm3.24 10.27l.18.18-.1.98-.19.15h-3.46l-.18-.17v-.84l.05-.11.62-.59c.39-.35.77-.72 1.13-1.1.15-.15.27-.3.36-.44a1.3 1.3 0 0 0 .28-.83.7.7 0 0 0-.14-.46.4.4 0 0 0-.34-.16c-.2 0-.34.05-.44.14-.1.09-.19.28-.25.59l-.2.13-.99-.12-.15-.2c.04-.24.11-.47.2-.68a1.67 1.67 0 0 1 .97-1c.24-.11.53-.16.87-.16.61 0 1.08.17 1.4.51.33.34.49.79.49 1.35a2.51 2.51 0 0 1-.33 1.25c-.1.2-.24.38-.4.57a9.72 9.72 0 0 1-1 1h1.62z" fill="#fff" fill-rule="nonzero"/></svg>

  <svg *ngSwitchCase="'card'" class="icon" width="16px" height="16px" viewBox="0 0 16 16"
       xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2">
       <path d="M1.88 15h12.25c.52 0 .87-.28.87-.7V1.7c0-.42-.35-.7-.88-.7H1.88c-.53 0-.88.28-.88.7v12.6c0 .42.35.7.88.7zm1.45-4.67h9.34v2.34H3.33v-2.34zm0-7h9.34V8H3.33V3.33z" fill="#fff" fill-rule="nonzero"/></svg>

  <ds-bundle-icon *ngSwitchCase="'bundle'"></ds-bundle-icon>
  <ds-translations-icon *ngSwitchCase="'language'"></ds-translations-icon>
  <ds-screen-out-icon *ngSwitchCase="'screenOut'"></ds-screen-out-icon>
  <ds-webhook-icon *ngSwitchCase="'webhook'" class="icon"></ds-webhook-icon>
  <ds-quota-icon *ngSwitchCase="'quota'" class="icon"></ds-quota-icon>
</ng-container>
