import { Pipe, PipeTransform } from '@angular/core';
import { ElementText } from '@app/surveys/models/survey-plan-elements.model';

@Pipe({name: 'elementText'})
export class ElementTextPipe implements PipeTransform {
  transform(value: ElementText) {
    if (!value || (!value.name && !value.nameHtml && !value.nameRaw)) {
      return '';
    }

    return value.nameHtml || value.nameRaw || value.name || '';
  }
}
