import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-download-icon',
  templateUrl: './download-icon.component.html',
  styleUrls: ['./download-icon.component.scss']
})
export class DownloadIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
