import { ElementEditMode } from '@app/survey-element-edit/models/element-edit.model';
import { Action, createReducer, on } from '@ngrx/store';
import { LayoutActions } from '@core/actions';

export const layoutFeatureKey = 'layout';

export interface State {
  mainViewLocked: boolean;
  elementEditMode: ElementEditMode;
  updatingQuestionOptions: boolean;
}

const initialState: State = {
  mainViewLocked: false,
  elementEditMode: ElementEditMode.Compact,
  updatingQuestionOptions: false
};

const layoutReducer = createReducer(
  initialState,
  on(LayoutActions.lockMainView, (state, {locked}) => ({...state, mainViewLocked: locked})),
  on(LayoutActions.toggleNodeEditMode, (state) => ({
    ...state,
    updatingQuestionOptions: false,
    elementEditMode:
      state.elementEditMode === ElementEditMode.Compact ?
        ElementEditMode.Expanded :
        ElementEditMode.Compact
  })),
  on(LayoutActions.resetNodeEditMode, (state) => ({
    ...state,
    elementEditMode: state.updatingQuestionOptions ? ElementEditMode.Expanded : ElementEditMode.Compact
  })),
  on(LayoutActions.setUpdatingQuestionOptions, (state) => ({
    ...state,
    updatingQuestionOptions: true
  })),
  on(LayoutActions.unsetUpdatingQuestionOptions, (state) => ({
    ...state,
    updatingQuestionOptions: false
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return layoutReducer(state, action);
}

export const getMainViewLocked = (state: State) => state.mainViewLocked;
export const getElementEditMode = (state: State) => state.elementEditMode;
