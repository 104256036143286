<div class='layout-aside-main'>
    <!-- [ngClass]="asideClasses$ | async" -->
    <div class="aside"
         [class.expanded]="viewExpanded$ | async">
        <router-outlet name="aside"></router-outlet>
    </div>

    <!-- [ngClass]="mainClasses$ | async" -->
    <div class="main"
         [class.hidden]="viewExpanded$ | async"
         [class.lock]="lockMainScreen$ | async">
        <router-outlet></router-outlet>

        <div *ngIf="lockMainScreen$ | async" class="lock-screen">
            <ds-loading-spinner></ds-loading-spinner>
        </div>
    </div>
</div>
