// API

export type UserRoleModel = 'public' | 'admin';

export interface UserModel {
  id: string;
  email: string;
  created_at: string;
  updated_at: string | null;
  current_sign_in_at: string | null;
  roles: UserRoleModel[];
  is_admin?: boolean;
}

// DASH

export enum UserRole {
  Admin = 'admin',
  Public = 'public'
}


export interface User {
  uuid: string;
  roles: UserRole[];

  email: string;

  createdAt: Date;
  updatedAt: Date | null;
  currentSignInAt: Date | null;

  isAdmin: boolean;
}

// HELPERS - DASH

export const userRoleMap = new Map<UserRoleModel, UserRole>([
  ['admin', UserRole.Admin],
  ['public', UserRole.Public]
]);

export function toUser(data: UserModel): User {
  return {
    uuid: data.id,
    roles: data.roles.map((role) => userRoleMap.get(role)),

    email: data.email,

    createdAt: new Date(data.created_at),
    updatedAt: data.updated_at && new Date(data.updated_at) || null,

    currentSignInAt: data.current_sign_in_at && new Date(data.current_sign_in_at) || null,

    isAdmin: data.roles.some((role) => role === 'admin')
  };
}

// HELPERS - USER

export function userIsAdmin(data: User): boolean {
  return data.roles.some((role) => role === UserRole.Admin);
}
