import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { Router } from '@angular/router';

@Injectable()
export class RoutingEffects {
  navigation$ = createEffect(() =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATION),
        // tap((action) => console.log('NAVIGATE: ', action.payload.routerState))
      ),
    {dispatch: false}
  );

  constructor(private router: Router, private actions$: Actions) {
  }
}
