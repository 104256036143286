import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-abacus-icon',
  templateUrl: './abacus-icon.component.html',
  styleUrls: ['./abacus-icon.component.scss']
})
export class AbacusIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
