<div class="top-container">
  <div class="left-block">
    <ng-content select=".top-left-block"></ng-content>
  </div>
  <div class="middle-block">
    <ng-content select="ds-element-preview-panel"></ng-content>
  </div>
  <div class="right-block"></div>
</div>

<div class="bottom-container">
  <div class="left-block"></div>
  <div class="middle-block">
    <ng-content select="ds-search-question-panel"></ng-content>
  </div>
  <div class="right-block"></div>
</div>
