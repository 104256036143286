import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router, CanLoad, Route
} from '@angular/router';
import { AuthService } from '../services';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private router: Router,
              private auth: AuthService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkForToken();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

  canLoad(route: Route): Observable<boolean> {
    return this.checkForToken();
  }

  checkForToken() {
    return this.auth.accessToken$.pipe(
      map((token: string) => token !== null),
      tap((hasToken: boolean) => {
        if (!hasToken) {
          this.auth.redirectToGWILogin();
        }
      })
    );
  }
}
