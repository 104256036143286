import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import * as fromRoot from '@app/core/reducers/';
import { BrowserStorageService } from '@app/core/services/browser-storage.service';
import { AuthActions } from '@core/actions';
import { AuthApiService, GwiRefreshTokenModel } from '@core/services/auth-api.service';
import { environment } from '@env/environment';
import { Store, select } from '@ngrx/store';
import { Observable, merge, of } from 'rxjs';
import { catchError, filter, first, map, mergeMap, take, tap } from 'rxjs/operators';
import { GWI_LOGIN_URL_PRODUCTION, GWI_LOGIN_URL_STAGING } from '../constants';

export enum AuthCookies {
  GwiAuthStaging = 'auth_gwi_staging',
  GwiAuthProduction = 'auth_gwi',
  GwiRefreshStaging = 'refresh_gwi_staging',
  GwiRefreshProduction = 'refresh_gwi',
  DashRefresh = 'refresh_dash',
  DashAuth = 'auth_dash',
  DashRefreshStaging = 'dash_refresh_staging',
  DashAuthStaging = 'dash_auth_staging'
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private authApiService: AuthApiService,
              private store: Store<fromRoot.State>,
              private browserStorage: BrowserStorageService,
              @Inject(DOCUMENT) private document: Document
              ) { }

  requestRefreshedToken(): Observable<string> {


    const authGrant$ = this.authApiService.requestGWIRefreshedToken();

    return authGrant$.pipe(
      tap((refreshModel: GwiRefreshTokenModel) => {
        this.clearAuth();
        this.setCookie(this.dashAuthCookieName, refreshModel.access_token, new Date(refreshModel.access_token_expires_at).toUTCString());
        this.setCookie(this.dashRefreshCookieName, refreshModel.refresh_token, new Date(refreshModel.refresh_token_expires_at).toUTCString());
        this.store.dispatch(AuthActions.updateAuthToken({token: refreshModel.access_token}));
        this.store.dispatch(AuthActions.updateRefreshToken({token: refreshModel.refresh_token}));
      }),
      map((refreshModel: GwiRefreshTokenModel) => refreshModel.access_token)
    );
  }

  setCookie(name, value, expires) {
    document.cookie = `${name}=${value};expires=${expires};path=/;secure;SameSite=Lax`;
  }

  refreshAccessToken(): Observable<string> {
    const refreshing = this.store.pipe(
      select(fromRoot.isRefreshing),
      take(1)
    );

    // If refresh is already in motion, wait for it to finish.
    // This avoids calling refresh multiple times.
    const wait$ = refreshing.pipe(
      filter((val) => val),
      mergeMap(() => this.store.pipe(
        select(fromRoot.isRefreshing),
        first((val) => val === false))),
      mergeMap(() => this.accessToken$),
      filter((accessToken) => accessToken !== null));

    const refresh$ = refreshing.pipe(
      filter((val) => !val),
      tap(() => this.store.dispatch(AuthActions.refreshingAuthToken({refreshing: true}))),
      mergeMap(() => this.requestRefreshedToken()),
      tap(() => this.store.dispatch(AuthActions.refreshingAuthToken({refreshing: false}))),
      catchError((error) => {
        this.logout();
        this.store.dispatch(AuthActions.refreshingAuthToken({refreshing: false}));
        return of(error);
      }));

    return merge(
      wait$,
      refresh$
    );
  }

  clearAuth() {
    this.removeCookie(AuthCookies.DashAuth);
    this.removeCookie(AuthCookies.DashRefresh);
    this.store.dispatch(AuthActions.clearAuthToken());
    this.store.dispatch(AuthActions.clearRefreshToken());
  }

  removeCookie(name: string) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  retrieveAccessCookie() {
    const dashAuth = this.browserStorage.getCookie(this.dashAuthCookieName);
    return dashAuth ?? this.browserStorage.getCookie(this.gwiAuthCookieName);
    // This checks if Dash has used previous set it's own auth token, if undefined then checks for and returns the globalwebindex set auth token.
  }

  retrieveRefreshCookie() {
    const dashRefresh = this.browserStorage.getCookie(this.dashRefreshCookieName);
    return dashRefresh ?? this.browserStorage.getCookie(this.gwiRefreshCookieName);
    // This checks if Dash has used previous refresh token and set it's own, if undefined then checks for and returns the globalwebindex set refresh token.
  }

  retrieveAuthFromStorage() {
    const accessToken = this.retrieveAccessCookie();
    const refreshToken = this.retrieveRefreshCookie();

    if (accessToken) { this.store.dispatch(AuthActions.updateAuthToken({token: accessToken})); }
    if (refreshToken) { this.store.dispatch(AuthActions.updateRefreshToken({token: refreshToken})); }
  }

  logout() {
    this.clearAuth();
    this.redirectToGWILogin();
  }

  redirectToGWILogin(): void {
    const gwiLoginUrl = environment.runningTestEnv ? GWI_LOGIN_URL_STAGING : GWI_LOGIN_URL_PRODUCTION;
    this.document.location.href = `${gwiLoginUrl}/?return_to=${this.document.location.href}`;
  }

  get accessToken$(): Observable<string> {
    return this.store.pipe(
      select(fromRoot.accessToken),
      take(1));
  }

  get refreshToken$(): Observable<string> {
    return this.store.pipe(
      select(fromRoot.refreshToken),
      take(1));
  }

  get authorizationHeader$(): Observable<string> {
    return this.accessToken$.pipe(
      take(1),
      map((accessToken: string) => `Bearer ${accessToken}`)
    );
  }

  get refreshHeader$(): Observable<string> {
    return this.refreshToken$.pipe(
      take(1),
      map((refreshToken: string) => `Basic ${refreshToken}`)
    );
  }

  get gwiAuthCookieName(): string {
    return environment.runningTestEnv ? AuthCookies.GwiAuthStaging : AuthCookies.GwiAuthProduction;
  }

  get gwiRefreshCookieName(): string {
    return environment.runningTestEnv ? AuthCookies.GwiRefreshStaging : AuthCookies.GwiRefreshProduction;
  }

  get dashAuthCookieName(): string {
    return environment.runningTestEnv ? AuthCookies.DashAuthStaging : AuthCookies.DashAuth;
  }

  get dashRefreshCookieName(): string {
    return environment.runningTestEnv ? AuthCookies.DashRefreshStaging : AuthCookies.DashRefresh;
  }

}
