import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiError } from '@core/models/error.model';
import { throwError as _throw ,  Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnvironmentService } from '@core/services/environment.service';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly env: EnvironmentService,
  ) {}

  get<T = any>(path: string, options?: object): Observable<T> {
    const url: string = this.env.apiURI + path;

    return this.http.get<T>(url, options)
      .pipe(
        catchError(this.handleError())
      );
  }

  post<T = any>(path: string, body: any, options?: object): Observable<T> {
    const url: string = this.env.apiURI + path;

    return this.http.post<T>(url, body, options)
      .pipe(
        catchError(this.handleError())
      );
  }

  put<T = any>(path: string, body: any, options?: object): Observable<T> {
    const url: string = this.env.apiURI + path;

    return this.http.put<T>(url, body, options)
      .pipe(
        catchError(this.handleError())
      );
  }

  patch<T = any>(path: string, body: any, options?: object): Observable<T> {
    const url: string = this.env.apiURI + path;

    return this.http.patch<T>(url, body, options)
      .pipe(
        catchError(this.handleError())
      );
  }


  delete<T = any>(path: string, options?: object): Observable<T> {
    const url: string = this.env.apiURI + path;

    return this.http.delete<T>(url, options)
      .pipe(
        catchError(this.handleError())
      );
  }

  handleError() {
    return (response: HttpErrorResponse) => _throw(new ApiError(response));
  }
}
