<div class="modal-title H3">Create a New Survey</div>
<div class="modal-text body-small">Please add a name for your new survey. Remember to make it memorable so you can
  easily find it again later.
</div>

<form [formGroup]="form" (ngSubmit)="createNewSurvey()">
  <label class="modal-label body-small" for="name">Please choose a name</label>
  <input name="name"
         class="modal-input body-small form-control"
         [ngClass]="{'invalid': (hasError | async)}"
         id="name"
         ds-input
         placeholder="E.g. Brand research survey"
         formControlName="name">

  <ul class="errors">
    <li *ngFor="let error of (errorMessages$ | async)" class="error counters">{{ error }}</li>
  </ul>

  <div class="modal-buttons">
    <button ds-button
            class="outline medium-size"
            type="button"
            (click)="close(false)">
      Cancel
    </button>
    <button ds-button
            class="medium-size"
            type="submit" >
      Create new survey
    </button>
  </div>
</form>
