<label class="option">
  <input #checkbox
         class="custom-control-input"
         id="{{id}}"
         value="{{value}}"
         name="{{name}}"
         type="checkbox"
         [disabled]="isDisabled"
         (change)="inputValueChange($event)"
         (blur)="onTouched()"
         (click)="inputClicked($event)">
  <span [class.no-content]="content === null" [ngClass]="{ 'disabled': isDisabled, 'enabled': !isDisabled && checked }" class="custom-control-indicator"></span>
  <span *ngIf="content" class="custom-control-description">{{content}}</span>
</label>
