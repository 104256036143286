import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
  ElementText,
  QuestionOption
} from '@app/surveys/models/survey-plan-elements.model';

@Component({
  selector: 'ds-question-option-element-preview',
  templateUrl: './question-option-element-preview.component.html',
  styleUrls: ['./question-option-element-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionOptionElementPreviewComponent {
  @Input() data: QuestionOption;

  get displayText(): ElementText {
    return this.data.text;
  }
}
