import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Optional,
  ViewContainerRef
} from '@angular/core';
import {
  FlexibleConnectedPositionStrategy,
  HorizontalConnectionPos,
  OriginConnectionPosition,
  Overlay,
  OverlayConnectionPosition,
  OverlayRef,
  ScrollDispatcher,
  ScrollStrategy,
  VerticalConnectionPos
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Platform } from '@angular/cdk/platform';
import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
  DS_TOOLTIP_DEFAULT_OPTIONS,
  DS_TOOLTIP_SCROLL_STRATEGY,
  DSTooltipDefaultOptions,
  getDsTooltipInvalidPositionError,
  TOOLTIP_PANEL_CLASS,
  TooltipArrowPosition,
  TooltipPosition
} from './tooltip.model';
import { TooltipComponent } from './tooltip.component';

/**
 * Directive source code inspired by:
 * https://github.com/angular/material2/blob/master/src/lib/tooltip/tooltip.ts
 */
@Directive({
  selector: '[dsTooltip]',
  exportAs: 'dsTooltip',
})
export class TooltipDirective implements OnDestroy {
  ovrRef: OverlayRef | null;
  tooltipInstance: TooltipComponent | null;

  private portal: ComponentPortal<TooltipComponent>;
  private dsPosition: TooltipPosition = 'below';
  private dsArrowPosition: TooltipArrowPosition = 'middle';
  private dsDisabled = false;
  private dsTooltipClass: string | string[] | Set<string> | { [key: string]: any };
  private readonly scrollStrategy: () => ScrollStrategy;

  /** Allows the user to define the position of the tooltip relative to the parent element */
  @Input('dsTooltipPosition')
  get position(): TooltipPosition {
    return this.dsPosition;
  }

  set position(value: TooltipPosition) {
    if (value !== this.dsPosition) {
      this.dsPosition = value;

      if (this.ovrRef) {
        this.updatePosition();

        if (this.tooltipInstance) {
          this.tooltipInstance.tooltipPosition = value;
          this.tooltipInstance.show(0);
        }

        this.ovrRef.updatePosition();
      }
    }
  }

  @Input() dsTooltipArrow = false;

  @Input('dsTooltipArrowPosition')
  get arrowPosition(): TooltipArrowPosition {
    return this.dsArrowPosition;
  }

  set arrowPosition(value: TooltipArrowPosition) {
    if (value !== this.dsArrowPosition) {
      this.dsArrowPosition = value;
      this.setTooltipArrowPosition(this.dsArrowPosition);
    }
  }

  /** Disables the display of the tooltip. */
  @Input('dsTooltipDisabled')
  get disabled(): boolean {
    return this.dsDisabled;
  }

  set disabled(value) {
    this.dsDisabled = coerceBooleanProperty(value);

    // If tooltip is disabled, hide immediately.
    if (this.dsDisabled) {
      this.hide(0);
    }
  }

  /** The default delay in ms before showing the tooltip after show is called */
  @Input() showDelay = this.defaultOptions.showDelay;

  /** The default delay in ms before hiding the tooltip after hide is called */
  @Input() hideDelay = this.defaultOptions.hideDelay;

  private dsMessage = '';

  @Input('dsTooltip')
  get message() {
    return this.dsMessage;
  }

  set message(value: string) {
    this.ariaDescriber.removeDescription(this.elementRef.nativeElement, this.dsMessage);

    // If the message is not a string (e.g. number), convert it to a string and trim it.
    this.dsMessage = value != null ? `${value}`.trim() : '';

    if (!this.dsMessage && this.isTooltipVisible()) {
      this.hide(0);
    } else {
      this.updateTooltipMessage();
      this.ariaDescriber.describe(this.elementRef.nativeElement, this.message);
    }
  }

  @Input('dsTooltipClass')
  get tooltipClass() {
    return this.dsTooltipClass;
  }

  set tooltipClass(value: string | string[] | Set<string> | { [key: string]: any }) {
    this.dsTooltipClass = value;
    if (this.tooltipInstance) {
      this.setTooltipClass(this.dsTooltipClass);
    }
  }

  private manualListeners = new Map<string, EventListenerOrEventListenerObject>();

  private readonly ngUnsubscribe$ = new Subject<void>();

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef<HTMLElement>,
    private scrollDispatcher: ScrollDispatcher,
    private viewContainerRef: ViewContainerRef,
    private ngZone: NgZone,
    platform: Platform,
    private ariaDescriber: AriaDescriber,
    private focusMonitor: FocusMonitor,
    @Inject(DS_TOOLTIP_SCROLL_STRATEGY) scrollStrategy: any,
    @Optional() private dir: Directionality,
    @Optional() @Inject(DS_TOOLTIP_DEFAULT_OPTIONS)
    private defaultOptions: DSTooltipDefaultOptions) {

    this.scrollStrategy = scrollStrategy;
    const element: HTMLElement = elementRef.nativeElement;
    const elementStyle = element.style as CSSStyleDeclaration & { webkitUserDrag: string; msUserSelect: string };

    this.manualListeners
      .set('mouseenter', () => this.show())
      .set('mouseleave', () => this.hide());

    this.manualListeners.forEach((listener, event) => element.addEventListener(event, listener));

    if (element.nodeName === 'INPUT' || element.nodeName === 'TEXTAREA') {
      // When we bind a gesture event on an element (in this case `longpress`), HammerJS
      // will add some inline styles by default, including `user-select: none`. This is
      // problematic on iOS and in Safari, because it will prevent users from typing in inputs.
      // Since `user-select: none` is not needed for the `longpress` event and can cause unexpected
      // behavior for text fields, we always clear the `user-select` to avoid such issues.
      elementStyle.webkitUserSelect = elementStyle.userSelect = elementStyle.msUserSelect = '';
    }

    // Hammer applies `-webkit-user-drag: none` on all elements by default,
    // which breaks the native drag&drop. If the consumer explicitly made
    // the element draggable, clear the `-webkit-user-drag`.
    if (element.draggable && elementStyle.webkitUserDrag === 'none') {
      elementStyle.webkitUserDrag = '';
    }

    focusMonitor.monitor(elementRef).pipe(takeUntil(this.ngUnsubscribe$)).subscribe(origin => {
      // Note that the focus monitor runs outside the Angular zone.
      if (!origin) {
        ngZone.run(() => this.hide(0));
      } else if (origin === 'keyboard') {
        ngZone.run(() => this.show());
      }
    });

    if (defaultOptions && defaultOptions.position) {
      this.position = defaultOptions.position;
    }
  }

  ngOnDestroy() {
    if (this.ovrRef) {
      this.ovrRef.dispose();
      this.tooltipInstance = null;
    }

    // Clean up the event listeners set in the constructor
    this.manualListeners.forEach((listener, event) => {
      this.elementRef.nativeElement.removeEventListener(event, listener);
    });
    this.manualListeners.clear();

    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();

    this.ariaDescriber.removeDescription(this.elementRef.nativeElement, this.message);
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  /** Shows the tooltip after the delay in ms, defaults to tooltip-delay-show or 0ms if no input */
  @HostListener('longpress')
  show(delay: number = this.showDelay): void {
    if (this.disabled || !this.message || (this.isTooltipVisible() &&
      this.tooltipInstance && !this.tooltipInstance.showTimeoutId && !this.tooltipInstance.hideTimeoutId)
    ) {
      return;
    }

    const overlayRef = this.createOverlay();

    this.detach();
    this.portal = this.portal || new ComponentPortal(TooltipComponent, this.viewContainerRef);
    this.tooltipInstance = overlayRef.attach(this.portal).instance;
    this.tooltipInstance.afterHidden()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.detach());
    this.setTooltipClass(this.dsTooltipClass);
    this.setTooltipArrowPosition(this.dsArrowPosition);
    this.tooltipInstance.tooltipPosition = this.position;
    this.tooltipInstance.hasArrow = this.dsTooltipArrow;
    this.updateTooltipMessage();
    this.tooltipInstance.show(delay);
  }

  /** Hides the tooltip after the delay in ms, defaults to tooltip-delay-hide or 0ms if no input */
  hide(delay: number = this.hideDelay): void {
    if (this.tooltipInstance) {
      this.tooltipInstance.hide(delay);
    }
  }

  toggle(): void {
    this.isTooltipVisible() ? this.hide() : this.show();
  }

  isTooltipVisible(): boolean {
    return !!this.tooltipInstance && this.tooltipInstance.isVisible();
  }

  // /** Handles the keydown events on the host element. */
  // _handleKeydown(e: KeyboardEvent) {
  //   if (this._isTooltipVisible() && e.keyCode === ESCAPE) {
  //     e.stopPropagation();
  //     this.hide(0);
  //   }
  // }

  @HostListener('touchend')
  handleTouchend() {
    this.hide(this.defaultOptions.touchendHideDelay);
  }

  private createOverlay(): OverlayRef {
    if (this.ovrRef) {
      return this.ovrRef;
    }

    // Create connected position strategy that listens for scroll events to reposition.
    const strategy = this.overlay.position()
      .flexibleConnectedTo(this.elementRef)
      .withTransformOriginOn('.ds-tooltip')
      .withFlexibleDimensions(false)
      .withViewportMargin(8);

    const scrollableAncestors = this.scrollDispatcher
      .getAncestorScrollContainers(this.elementRef);

    strategy.withScrollableContainers(scrollableAncestors);

    strategy.positionChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(change => {
      if (this.tooltipInstance) {
        const originY = change.connectionPair.originY;
        const isInverted = (originY === 'top' && this.position === 'below') || (originY === 'bottom' && this.position === 'above');

        this.tooltipInstance.setIsInverted(isInverted);

        if (change.scrollableViewProperties.isOverlayClipped && this.tooltipInstance.isVisible()) {
          // After position changes occur and the overlay is clipped by
          // a parent scrollable then close the tooltip.
          this.ngZone.run(() => this.hide(0));
        }
      }
    });

    this.ovrRef = this.overlay.create({
      direction: this.dir,
      positionStrategy: strategy,
      panelClass: TOOLTIP_PANEL_CLASS,
      scrollStrategy: this.scrollStrategy()
    });

    this.updatePosition();

    this.ovrRef.detachments()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.detach());

    return this.ovrRef;
  }

  /** Detaches the currently-attached tooltip. */
  private detach() {
    if (this.ovrRef && this.ovrRef.hasAttached()) {
      this.ovrRef.detach();
    }

    this.tooltipInstance = null;
  }

  /** Updates the position of the current tooltip. */
  private updatePosition() {
    const position =
      this.ovrRef && this.ovrRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;
    const origin = this.getOrigin();
    const overlay = this.getOverlayPosition();

    position.withPositions([
      { ...origin.main, ...overlay.main },
      { ...origin.fallback, ...overlay.fallback }
    ]);
  }

  /**
   * Returns the origin position and a fallback position based on the user's position preference.
   * The fallback position is the inverse of the origin (e.g. `'below' -> 'above'`).
   */
  getOrigin(): { main: OriginConnectionPosition, fallback: OriginConnectionPosition } {
    const isLtr = !this.dir || this.dir.value === 'ltr';
    const position = this.position;
    let originPosition: OriginConnectionPosition;

    if (position === 'above' || position === 'below') {
      originPosition = { originX: 'center', originY: position === 'above' ? 'top' : 'bottom' };
    } else if (
      position === 'before' ||
      (position === 'left' && isLtr) ||
      (position === 'right' && !isLtr)) {
      originPosition = { originX: 'start', originY: 'center' };
    } else if (
      position === 'after' ||
      (position === 'right' && isLtr) ||
      (position === 'left' && !isLtr)) {
      originPosition = { originX: 'end', originY: 'center' };
    } else {
      throw getDsTooltipInvalidPositionError(position);
    }

    const { x, y } = this.invertPosition(originPosition.originX, originPosition.originY);

    return {
      main: originPosition,
      fallback: { originX: x, originY: y }
    };
  }

  /** Returns the overlay position and a fallback position based on the user's preference */
  getOverlayPosition(): { main: OverlayConnectionPosition, fallback: OverlayConnectionPosition } {
    const isLtr = !this.dir || this.dir.value === 'ltr';
    const position = this.position;
    let overlayPosition: OverlayConnectionPosition;

    if (position === 'above') {
      overlayPosition = { overlayX: 'center', overlayY: 'bottom' };
    } else if (position === 'below') {
      overlayPosition = { overlayX: 'center', overlayY: 'top' };
    } else if (
      position === 'before' ||
      (position === 'left' && isLtr) ||
      (position === 'right' && !isLtr)) {
      overlayPosition = { overlayX: 'end', overlayY: 'center' };
    } else if (
      position === 'after' ||
      (position === 'right' && isLtr) ||
      (position === 'left' && !isLtr)) {
      overlayPosition = { overlayX: 'start', overlayY: 'center' };
    } else {
      throw getDsTooltipInvalidPositionError(position);
    }

    const { x, y } = this.invertPosition(overlayPosition.overlayX, overlayPosition.overlayY);

    return {
      main: overlayPosition,
      fallback: { overlayX: x, overlayY: y }
    };
  }

  /** Updates the tooltip message and repositions the overlay according to the new message length */
  private updateTooltipMessage() {
    // Must wait for the message to be painted to the tooltip so that the overlay can properly
    // calculate the correct positioning based on the size of the text.
    if (this.tooltipInstance) {
      this.tooltipInstance.message = this.message;
      this.tooltipInstance.markForCheck();

      this.ngZone.onMicrotaskEmpty.asObservable().pipe(
        take(1),
        takeUntil(this.ngUnsubscribe$)
      ).subscribe(() => {
        if (this.tooltipInstance && this.ovrRef) {
          this.ovrRef.updatePosition();
        }
      });
    }
  }

  private setTooltipClass(tooltipClass: string | string[] | Set<string> | { [key: string]: any }) {
    if (this.tooltipInstance) {
      this.tooltipInstance.tooltipClass = tooltipClass;
      this.tooltipInstance.markForCheck();
    }
  }

  private setTooltipArrowPosition(tooltipPosition: TooltipArrowPosition) {
    if (this.tooltipInstance) {
      this.tooltipInstance.tooltipArrowPosition = tooltipPosition;
      this.tooltipInstance.markForCheck();
    }
  }

  private invertPosition(x: HorizontalConnectionPos, y: VerticalConnectionPos) {
    if (this.position === 'above' || this.position === 'below') {
      if (y === 'top') {
        y = 'bottom';
      } else if (y === 'bottom') {
        y = 'top';
      }
    } else {
      if (x === 'end') {
        x = 'start';
      } else if (x === 'start') {
        x = 'end';
      }
    }

    return { x, y };
  }
}
