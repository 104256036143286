import { ApiError } from '@core/models/error.model';
import { assertUnreachable } from '@app/lib/utils';
import { Pipe, PipeTransform } from '@angular/core';

export enum RemoteDataType {
  NotAsked = 'NotAsked',
  Loading = 'Loading',
  LoadingFail = 'LoadingFail',
  Refreshing = 'Refreshing',
  RefreshingFail = 'RefreshingFail',
  Updating = 'Updating',
  UpdatingFail = 'UpdatingFail',
  Success = 'Success'
}

export class NotAsked {
  readonly type = RemoteDataType.NotAsked;

  constructor() {
  }
}

export class Loading {
  readonly type = RemoteDataType.Loading;
}

export class LoadingFail {
  readonly type = RemoteDataType.LoadingFail;

  constructor(readonly error: ApiError) {
  }
}

export class Refreshing<T> {
  readonly type = RemoteDataType.Refreshing;
  readonly data: T;

  constructor(rd: RemoteData<T>) {
    this.data = extractData(rd);
  }
}

export class RefreshingFail<T> {
  readonly type = RemoteDataType.RefreshingFail;
  readonly data: T;

  constructor(readonly error: ApiError, rd: RemoteData<T>) {
    this.data = extractData(rd);
  }
}

export class Updating<T> {
  readonly type = RemoteDataType.Updating;
  readonly prevData: T;

  constructor(prevRD: RemoteData<T>, readonly data: T) {
    this.prevData = extractData(prevRD);
  }
}

export class UpdatingFail<T> {
  readonly type = RemoteDataType.UpdatingFail;
  readonly data: T;

  constructor(readonly error: ApiError, rd: RemoteData<T>, readonly updateData: T) {
    if (rd.type === RemoteDataType.Updating) {
      this.data = rd.prevData;
    } else {
      this.data = extractData(rd);
    }
  }
}

export class Success<T> {
  readonly type = RemoteDataType.Success;

  constructor(readonly data: T) {
  }
}

export type RemoteData<T> =
  NotAsked
  | Loading
  | LoadingFail
  | Refreshing<T>
  | RefreshingFail<T>
  | Updating<T>
  | UpdatingFail<T>
  | Success<T>;

// HELPERS

export function extractErrorMessages<T>(data: RemoteData<T>): string[] {
  switch (data.type) {
    case RemoteDataType.RefreshingFail:
    case RemoteDataType.UpdatingFail:
    case RemoteDataType.LoadingFail:
      return data.error.messages;

    case RemoteDataType.NotAsked:
    case RemoteDataType.Loading:
    case RemoteDataType.Refreshing:
    case RemoteDataType.Updating:
    case RemoteDataType.Success:
      return [];

    default:
      return assertUnreachable(data);
  }
}

export function extractHasError<T>(data: RemoteData<T>): boolean {
  switch (data.type) {
    case RemoteDataType.RefreshingFail:
    case RemoteDataType.UpdatingFail:
    case RemoteDataType.LoadingFail:
      return true;

    case RemoteDataType.NotAsked:
    case RemoteDataType.Loading:
    case RemoteDataType.Refreshing:
    case RemoteDataType.Updating:
    case RemoteDataType.Success:
      return false;

    default:
      return assertUnreachable(data);
  }
}

export function extractProcessing<T>(data: RemoteData<T>): boolean {
  switch (data.type) {
    case RemoteDataType.Loading:
    case RemoteDataType.Refreshing:
    case RemoteDataType.Updating:
      return true;

    case RemoteDataType.RefreshingFail:
    case RemoteDataType.UpdatingFail:
    case RemoteDataType.LoadingFail:
    case RemoteDataType.NotAsked:
    case RemoteDataType.Success:
      return false;

    default:
      return assertUnreachable(data);
  }
}

export function extractHasData<T>(data: RemoteData<T>): boolean {
  switch (data.type) {
    case RemoteDataType.NotAsked:
    case RemoteDataType.Loading:
    case RemoteDataType.LoadingFail:
      return false;

    case RemoteDataType.Refreshing:
    case RemoteDataType.RefreshingFail:
    case RemoteDataType.UpdatingFail:
    case RemoteDataType.Updating:
    case RemoteDataType.Success:
      return true;

    default:
      return assertUnreachable(data);
  }
}

export function extractData<T>(data: RemoteData<T>): T | null {
  switch (data.type) {
    case RemoteDataType.Success:
    case RemoteDataType.Updating:
    case RemoteDataType.Refreshing:
    case RemoteDataType.RefreshingFail:
    case RemoteDataType.UpdatingFail:
      return data.data;

    case RemoteDataType.NotAsked:
    case RemoteDataType.Loading:
    case RemoteDataType.LoadingFail:
      return null;

    default:
      return assertUnreachable(data);
  }
}

// PIPES

@Pipe({name: 'rdIsNotAsked'})
export class IsNotAskedPipe implements PipeTransform {
  transform<T>(value: RemoteData<T>): boolean {
    return value.type === RemoteDataType.NotAsked;
  }
}

@Pipe({name: 'rdIsLoading'})
export class IsLoadingPipe implements PipeTransform {
  transform<T>(value: RemoteData<T>): boolean {
    return value.type === RemoteDataType.Loading;
  }
}

@Pipe({name: 'rdIsLoadingFail'})
export class IsLoadingFailPipe implements PipeTransform {
  transform<T>(value: RemoteData<T>): boolean {
    return value.type === RemoteDataType.LoadingFail;
  }
}

@Pipe({name: 'rdIsRefreshing'})
export class IsRefreshingPipe implements PipeTransform {
  transform<T>(value: RemoteData<T>): boolean {
    return value.type === RemoteDataType.Refreshing;
  }
}

@Pipe({name: 'rdIsRefreshingFail'})
export class IsRefreshingFailPipe implements PipeTransform {
  transform<T>(value: RemoteData<T>): boolean {
    return value.type === RemoteDataType.RefreshingFail;
  }
}

@Pipe({name: 'rdIsUpdating'})
export class IsUpdatingPipe implements PipeTransform {
  transform<T>(value: RemoteData<T>): boolean {
    return value.type === RemoteDataType.Updating;
  }
}

@Pipe({name: 'rdIsUpdatingFail'})
export class IsUpdatingFailPipe implements PipeTransform {
  transform<T>(value: RemoteData<T>): boolean {
    return value.type === RemoteDataType.UpdatingFail;
  }
}

@Pipe({name: 'rdIsSuccess'})
export class IsSuccessPipe implements PipeTransform {
  transform<T>(value: RemoteData<T>): value is Success<T> {
    return value.type === RemoteDataType.Success;
  }
}
