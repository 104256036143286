<div (click)="toggleOpen()" class="toggle">
    <ds-chevron-up-icon *ngIf="!open"></ds-chevron-up-icon>
    <ds-chevron-down-icon *ngIf="open"></ds-chevron-down-icon>
</div>
<div class="content" [class.closed]="!open">
    <div>
        <div class="input-container">
            <label for="api-testing">API URL
                <input #apiHot id="api-testing">
            </label>

            <label for="preview-socket-testing">Preview Socket URL
                <input #previewSocketHot id="preview-socket-testing"></label>

            <label for="neo-testing" class="counters">Neo URL
                <input #neoHot id="neo-testing"></label>

            <label for="messenger-testing" class="counters">Messenger URL
                <input #messengerHot id="messenger-testing"></label>
    
            <div class="button-group">
                <button (click)="storeParam()">Save</button>
                <button (click)="clearParam()">Clear</button>
            </div>
        </div>
    </div>
    <div class="text-container">
        <div class="counters">Clear button will remove all properties stored. This will force the app to use
            default env properties.
        </div>
        <div class="counters warning">You need to refresh the page after changing, or clearing, any of the
            properties!
        </div>
    </div>
</div>
