import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ConditionNodeOwner, ConditionOwner } from '@app/shared/models/search-models';
import { SearchNode } from '@app/surveys/models/search-node.model';
import { Node } from '@app/surveys/models/survey-plan-elements.model';
import { ModalRef } from '@core/models/modal.model';
import { DS_MODAL_DATA } from '@core/tokens';

@Component({
  selector: 'ds-add-piping-modal',
  templateUrl: './add-piping-modal.component.html',
  styleUrls: ['./add-piping-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPipingModalComponent {

  constructor(
    public modalRef: ModalRef<AddPipingModalComponent, {}>,
    @Inject(DS_MODAL_DATA) public node: Node,
  ) {}


  get owner(): ConditionOwner {
    return new ConditionNodeOwner(this.node);
  }

  get excludeId(): string {
    return this.node.data.uuid;
  }

  close(): void {
    this.modalRef.close(false);
  }

  closeWithData(node: SearchNode): void {
    this.modalRef.close(node);
  }
}
