import { ChangeDetectionStrategy, Component } from '@angular/core';
import * as fromRoot from '@app/core/reducers';
import { ElementEditMode } from '@app/survey-element-edit/models/element-edit.model';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ds-layout-survey-builder',
  templateUrl: './layout-survey-builder.component.html',
  styleUrls: ['./layout-survey-builder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutSurveyBuilderComponent {
  constructor(private store: Store<fromRoot.State>) {
  }

  get lockMainScreen$(): Observable<boolean> {
    return this.store.pipe(select(fromRoot.mainViewLocked));
  }

  get viewExpanded$(): Observable<boolean> {
    return this.store.pipe(
      select(fromRoot.elementEditMode),
      map((view) => view === ElementEditMode.Expanded)
    );
  }
}
