import { NgModule } from '@angular/core';
import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { environment } from '../../environments/environment';

@NgModule({
  imports: [
    SocialLoginModule,
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: <SocialAuthServiceConfig>{
        autoLogin: false,
        providers: [{
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(
            environment.googleSsoClientId,
            {
              scope: 'openid',
              offline_access: true,
            },
          )
        }],
      },
    }
  ],
})
export class SingleSignOnModule {}
