import { createAction, props } from '@ngrx/store';
import { SurveysResponseModel, SurveyView } from '@app/surveys/models/survey.model';
import { Pagination } from '@shared/models/meta.model';
import { RemoteData } from '@app/remote-data/models/remote-data.model';
import { Direction } from '@core/models/utils.model';

export const querySurveysViews = createAction(
  '[SurveysViews] Query Surveys Views',
  props<{ query: string, page: number, per: number, archived: boolean }>()
);

export const replaceQueryResult = createAction(
  '[SurveysViews] Replace Query Result',
  props<{ result: RemoteData<SurveysResponseModel> }>()
);

export const replacePagination = createAction(
  '[SurveysViews] Replace Pagination',
  props<{ pagination: Pagination }>()
);

export const addSurveysViews = createAction(
  '[SurveysViews] Add Surveys Views',
  props<{ surveysViews: [string, RemoteData<SurveyView>][] }>()
);

export const replaceSurveyViews = createAction(
  '[SurveysViews] Replace Surveys Views',
  props<{ surveysViews: [string, RemoteData<SurveyView>][] }>()
);

export const clearSurveysViews = createAction(
  '[SurveysViews] Clear Surveys Views'
);

export const updateSurveyView = createAction(
  '[SurveysViews] Update Survey View',
  props<{ uuid: string, surveyView: SurveyView }>()
);

export const replaceSurveyView = createAction(
  '[SurveysViews] Replace Survey View',
  props<{ uuid: string, surveyView: RemoteData<SurveyView> }>()
);

export const deleteSurveyView = createAction(
    '[SurveyViews] Delete Survey View',
    props<{ surveyUuid: string }>()
);

export const removeSurveyView = createAction(
    '[SurveyViews] Remove Survey View',
    props<{ surveyUuid: string }>()
);

export const duplicateSurveyView = createAction(
  '[SurveyViews] Duplicate Survey View',
  props<{ surveyId: string }>(),
);

// POLLING

export const pollSurveyViews = createAction(
  '[SurveysViews] Poll Survey View',
  props<{ query: string, archived: boolean, page: number, size: number }>(),
);

// SELECT

export const selectSurveyView = createAction(
  '[SurveysViews] Select Survey View',
  props<{ surveyUuid: string }>()
);

export const clearSelectedSurveyView = createAction(
  '[SurveysViews] Clear select Survey View'
);

// MOVE

export const moveSurveyView = createAction(
  '[SurveysViews] Move Survey View',
  props<{ surveyUuid: string, currentPosition: number, direction: Direction }>()
);

export const replacePositionsStatus = createAction(
  '[SurveysViews] Replace Positions Status',
  props<{ status: RemoteData<void> }>()
);

export const addPositions = createAction(
  '[SurveysViews] Add Positions',
  props<{ positions: string[] }>()
);

export const replacePositions = createAction(
  '[SurveysViews] Replace Positions',
  props<{ positions: string[] }>()
);
