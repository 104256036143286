import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NoContentComponent } from './no-content';
import { AuthGuard } from '@core/guards';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'surveys',
  },
  {
    path: 'surveys',
    canLoad: [AuthGuard],
    loadChildren: () => import('@app/surveys/surveys.module').then(m => m.SurveysModule)
  },
  {
    path: 'analytics',
    canLoad: [AuthGuard],
    loadChildren: () => import('@app/analytics/analytics.module').then(m => m.AnalyticsModule)
  },
  {
    path: '**',
    component: NoContentComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'disabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
