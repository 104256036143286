import { GlobalPositionStrategy, OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UUID } from '@app/lib/utils';
import { ModalContainerComponent } from '@shared/modals';
import { hasModifierKey } from '@angular/cdk/keycodes';

export interface ConfirmationModalData {
  title: string;
  content: string;
  closeBtnText: string;
  confirmBtnText: string;
  confirmBtnPrimary?: boolean;
}

export interface CustomModalStyle {
  height?: string | number;
  width?: string | number;
  maxHeight?: string | number;
  maxWidth?: string | number;
  minHeight?: string | number;
  minWidth?: string | number;
  padding?: string | number;
  panelClass?: string;
  positionStrategy?: GlobalPositionStrategy;
}

export interface ModalOptions {
  disableCloseOnOverlayEvents?: boolean;
}

export class ModalRef<T, R> {
  componentInstance: T;

  private readonly afterOpened$ = new Subject<void>();
  private readonly afterClosed$ = new Subject<R | boolean>();

  private result: R | boolean;

  readonly uuid: string;

  constructor(
    private overlayRef: OverlayRef,
    public containerInstance: ModalContainerComponent,
    private options: ModalOptions) {

    this.uuid = UUID.create4();

    containerInstance._id = this.uuid;

    this.afterOpened$.next();
    this.afterOpened$.complete();

    /*
    overlayRef.detachments().subscribe(() => {
      this.afterClosed$.next(this.result);
      this.afterClosed$.complete();
      this.componentInstance = null;
      this.overlayRef.dispose();
    });
    */

    if (!options.disableCloseOnOverlayEvents) {
      this.overlayRef.backdropClick().subscribe(
        () => this.close(false)
      );

      this.overlayRef.keydownEvents().pipe(
        filter((event: KeyboardEvent) => event.key === 'Escape' && !hasModifierKey(event)))
        .subscribe(() => this.close(false));
    }
  }

  /**
   * Close the dialog.
   * @param dialogResult Optional result to return to the dialog opener.
   */
  close(dialogResult: R | boolean): void {
    this.result = dialogResult;

    this.afterClosed$.next(dialogResult);
    this.afterClosed$.complete();
    this.overlayRef.detachBackdrop();
    this.componentInstance = null;
    this.overlayRef.dispose();
  }

  /**
   * Gets an observable that is notified when the dialog is finished opening.
   */
  afterOpened(): Observable<void> {
    return this.afterOpened$.asObservable();
  }

  /**
   * Gets an observable that is notified when the dialog is finished closing.
   */
  afterClosed(): Observable<R | boolean> {
    return this.afterClosed$.asObservable();
  }

  /**
   * Gets an observable that emits when the overlay's backdrop has been clicked.
   */
  backdropClick(): Observable<MouseEvent> {
    return this.overlayRef.backdropClick();
  }

  /**
   * Gets an observable that emits when keydown events are targeted on the overlay.
   */
  keydownEvents(): Observable<KeyboardEvent> {
    return this.overlayRef.keydownEvents();
  }
}

// MODALS DATA

export const deleteBlockModalData: ConfirmationModalData = {
  title: 'Delete Block',
  content: 'Deleting this block will delete all the questions it contains. Are you sure you want to delete?',
  closeBtnText: 'Cancel',
  confirmBtnText: 'Delete Block'
};

export const deleteNodeModalData: ConfirmationModalData = {
  title: 'Remove node',
  content: 'The node will be removed from the survey.',
  closeBtnText: 'Cancel',
  confirmBtnText: 'Remove Node'
};

export const confirmTranslationChangesData: ConfirmationModalData = {
  title: 'Confirm changes',
  content: 'Are you sure you want to save these changes? Existing translated text will be replaced by your new input.',
  closeBtnText: 'Cancel',
  confirmBtnText: 'Yes, I\'m sure',
  confirmBtnPrimary: true,
};
