import { AuthGrant } from '@core/models/auth-grant.model';
import { Action, createReducer, on } from '@ngrx/store';
import { AuthActions } from '@core/actions';

export const authFeatureKey = 'auth';

export interface State {
  isAuthenticating: boolean;
  isRefreshing: boolean;
  authToken: string | null;
  refreshToken: string | null;
}

const initialState: State = {
  isAuthenticating: false,
  isRefreshing: false,
  authToken: null,
  refreshToken: null
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.authenticating, (state, {authenticating}) => ({...state, isAuthenticating: authenticating})),
  on(AuthActions.refreshingAuthToken, (state, {refreshing}) => ({...state, isRefreshing: refreshing})),
  on(AuthActions.updateAuthToken, (state, {token}) => ({...state, authToken: token})),
  on(AuthActions.updateRefreshToken, (state, {token}) => ({...state, refreshToken: token})),
  on(AuthActions.clearAuthToken, (state) => ({...state, authToken: null})),
  on(AuthActions.clearRefreshToken, (state) => ({...state, refreshToken: null})),
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}

export const getIsAuthenticating = (state: State) => state.isAuthenticating;
export const getIsRefreshing = (state: State) => state.isRefreshing;
export const getAuthToken = (state: State) => state.authToken;
export const getRefreshToken = (state: State) => state.refreshToken;
