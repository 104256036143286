import { Injectable } from '@angular/core';

function getWindow (): any {
  return window || {};
}

@Injectable({
  providedIn: 'root'
})
export class WindowRefService {
  get hasWindow (): boolean {
    return window !== undefined && window !== null;
  }

  get nativeWindow (): any {
    return getWindow();
  }
}
