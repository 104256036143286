import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-plus-icon',
  templateUrl: './plus-icon.component.html',
  styleUrls: ['./plus-icon.component.scss']
})
export class PlusIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
