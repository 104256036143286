import { Utils } from '@app/lib/utils';
import { ElementText, PipedQuestion, PipingType, QuestionOption, BundleQuestion } from '@app/surveys/models/survey-plan-elements.model';
import { ImageData } from '../modals/image-upload-modal/image-upload-modal.component';

export interface TextSegmentModel {
  text: ElementText;
  questionCode: string;
}

export interface QuestionOptionsSegmentModel {
  questionOptions: QuestionOptionSegmentModel[];
}

export enum QuestionBundleType {
  Single = 'RadioQuestion',
  Multiple = 'MultipleQuestion',
}

export interface QuestionBundleSegmentModel extends QuestionOptionsSegmentModel {
  questions: QuestionOptionSegmentModel[];
  questionBundleType: QuestionBundleType;
}

export interface QuestionOptionSegmentModel {
  uuid?: string;
  text: ElementText;
  openEnded: boolean;
  nota: boolean;
  code: string;
  row: number;
  image: ImageData;
}

export interface QuestionCodeSegmentModel {
  questionCodesEnabled: boolean;
}

export interface MinMaxSegmentModel {
  maxAnswers: number;
  minAnswers: number;
}

export interface PipedSwitchSegmentModel {
  pipedQuestionEnabled: boolean;
}

export interface QuestionDisplayTypeModel {
  questionDisplayTypeEnabled: boolean;
  questionDisplayType: QuestionDisplayType;
}

export interface PipedFromSegmentModel {
  pipedFrom: PipedQuestionSegmentModel[];
}

export interface PipedQuestionSegmentModel {
  uuid: string;
  pipingType: PipingType;
  pipingLinkId: string;
}

export interface BundleRandomizationSegmentModel {
  randomizeQuestionOptions: boolean;
  randomizeQuestions: boolean;
}

export type SegmentModel =
  TextSegmentModel |
  QuestionOptionsSegmentModel |
  QuestionCodeSegmentModel |
  MinMaxSegmentModel |
  PipedSwitchSegmentModel |
  PipedFromSegmentModel |
  QuestionDisplayTypeModel |
  QuestionBundleSegmentModel |
  BundleRandomizationSegmentModel;

export interface QuestionCodeSegmentData {
  questionCodesEnabled: boolean;
}

export enum QuestionDisplayType {
  AUTO = 'auto',
  QUICK_SELECT = 'quick-select',
  VERTICAL_LIST = 'vertical-list',
}

export interface QuestionDisplayTypeData {
  questionDisplayType: QuestionDisplayType;
}

export interface TextSegmentData {
  text: string;
  textHtml?: string;
  questionCode: string;
}

export interface QuestionOptionSegmentData {
  uuid: string;
  text: string;
  openEnded: boolean;
  nota: boolean;
  code: string;
  row: number;
  displayLogic: boolean;
  textHtml: string;
  image: ImageData;
}

export interface MinMaxSegmentData {
  max: number;
  min: number;
}

export interface QuestionOptionsSegmentData {
  questionOptions: QuestionOptionSegmentData[];
}

export interface QuestionBundleSegmentData extends QuestionOptionsSegmentData {
  questions: QuestionOptionSegmentData[];
  questionType: QuestionBundleType;
}

export interface PipedFromSegmentData {
  pipedQuestions: PipedQuestionSegmentData[];
}

export interface PipedQuestionSegmentData {
  name: string;
  questionCode: string;
  uuid: string;
  pipingType: PipingType;
  pipingLinkId: string;
}

export interface PipedSwitchSegmentData {
  pipedQuestionEnabled: boolean;
}

export interface BundleRandomizationSegmentData {
  randomizeQuestions: boolean;
  randomizeQuestionOptions: boolean;
}

export type SegmentData =
  QuestionCodeSegmentData |
  TextSegmentData |
  QuestionOptionsSegmentData |
  QuestionBundleSegmentData |
  MinMaxSegmentData |
  PipedFromSegmentData |
  PipedSwitchSegmentData |
  QuestionDisplayTypeData |
  BundleRandomizationSegmentData;

export interface QuestionDisplayTypeFormData {
  displayTypeEnabled: boolean;
  questionDisplayType: QuestionDisplayType;
}

export enum QuestionOptionType {
  QuestionOption = 'answer_options',
  BundleQuestionOption = 'bundle_answer_options',
  BundleStatement = 'bundle_statement'
}

// HELPERS
export function toQuestionOptionSegmentData(data: QuestionOption): QuestionOptionSegmentData {
  return {
    uuid: data.uuid,
    text: data.text.name || data.text.nameRaw || '',
    openEnded: data.openEnded,
    nota: data.nota,
    code: data.code || '',
    row: data.row,
    displayLogic: data.displayLogic,
    textHtml: data.text.nameHtml || '',
    image: data.image
  };
}

export function toQuestionOptionsSegmentData(data: QuestionOption[]): QuestionOptionsSegmentData {
  const options = data.map((item) =>
    toQuestionOptionSegmentData(item));

  return { questionOptions: Utils.sortByProperty(options, 'row') };
}

export function toQuestionBundleSegmentData(
  questions: BundleQuestion[],
  questionOptions: QuestionOption[],
  questionType: QuestionBundleType,
): QuestionBundleSegmentData {
  return {
    questions: Utils.sortByProperty(
      questions.map((item) => toQuestionOptionSegmentData(<any> item)),
      'row',
    ),
    questionOptions: Utils.sortByProperty(
      questionOptions.map((item) => toQuestionOptionSegmentData(item)),
      'row',
    ),
    questionType,
  };
}

export function toPipedQuestionSegmentData(data: PipedQuestion): PipedQuestionSegmentData {
  return {
    name: data.text,
    questionCode: data.questionCode,
    uuid: data.uuid,
    pipingType: data.pipingType,
    pipingLinkId: data.pipingLinkId
  };
}

export function toPipedFromSegmentData(data: PipedQuestion[]): PipedFromSegmentData {
  return { pipedQuestions: data.map((item) => toPipedQuestionSegmentData(item)) };
}
