import { assertUnreachable } from '@app/lib/utils';
import {
  RemoteData,
  RemoteDataType,
} from '@app/remote-data/models/remote-data.model';
import { ImageData } from '@app/survey-element-edit/modals/image-upload-modal/image-upload-modal.component';
import {
  QuestionBundleType,
  QuestionDisplayType,
} from '@app/survey-element-edit/models/segment.model';
import {
  Structure,
  StructureGroup,
  StructureGroupItem,
  StructureGroupItemModel,
  StructureGroupModel,
  StructureModel,
  toStructure,
} from '@app/surveys/models/survey.model';
import { Properties } from '@core/models/utils.model';
import { PaginationModel } from '@shared/models/meta.model';

/******************************
 API
 *****************************/

// BLOCK

export interface BlockModel {
  id: string;
  survey_id: string;
  name: string;
  expression?: any;
  display_logic: DisplayLogicModel | null | boolean;
  structure: StructureModel;
  nodes: NodeModel[];
}

export type BlockCreateModel = Pick<BlockModel, 'name'>;
export type BlockUpdateModel = Pick<BlockModel, 'name' | 'id'>;

// NODE

export type NodeModelType =
  | 'TextNode'
  | 'Bundle::Node'
  | 'RadioQuestion'
  | 'MultipleQuestion'
  | 'LanguageQuestion'
  | 'OpenEndedQuestion'
  | 'Question'
  | 'ScreenOutNode'
  | 'WebhookNode'
  | 'QuotaNode';

export type NodeElementModel =
  | QuestionElementModel
  | BundleElementModel
  | TextNodeModel
  | ScreenOutElementModel;

interface BaseNodeModel {
  id: string;
  type: NodeModelType;
  block_id: string;
  element: NodeElementModel;
  display_logic: DisplayLogicModel | null | boolean;
}

export interface QuestionNodeModel extends BaseNodeModel {
  type:
    | 'RadioQuestion'
    | 'MultipleQuestion'
    | 'LanguageQuestion'
    | 'OpenEndedQuestion';
  element: QuestionElementModel;
}

export interface BundleNodeModel extends BaseNodeModel {
  type: 'Bundle::Node';
  element: BundleElementModel;
}

export type NodeModel = BaseNodeModel | QuestionNodeModel | BundleNodeModel;

// QUESTION

export type QuestionModelType =
  | 'RadioQuestion'
  | 'MultipleQuestion'
  | 'LanguageQuestion'
  | 'OpenEndedQuestion';

export enum QuestionBundleTypeModel {
  RadioQuestion = 'RadioQuestion',
  MultipleQuestion = 'MultipleQuestion',
}

export enum QuestionModelDisplayType {
  Auto = 'auto',
  Row = 'row',
  Column = 'column',
}

export interface WithTextName {
  name: string;
  name_html: string | null;
  name_raw: string | null;
}

export interface WithTranslationLabels {
  labels?: TranslationLabelsModel;
}

export interface QuestionsResponseModel {
  questions: QuestionElementModel[];
  meta: PaginationModel;
}

export interface QuestionResponseModel {
  question: QuestionElementModel;
}

export interface QuestionElementModel
  extends WithTextName,
    WithTranslationLabels {
  id: string;
  question_type: QuestionModelType;
  display_type: QuestionModelDisplayType;

  code: string | null; // Must be unique + can be null + is NOT case sensitive

  question_options: QuestionOptionModel[];

  // Multi questions only
  max_answers: number | null;
  min_answers: number | null;

  piped_from: PipedQuestionModel[] | null;

  structure: StructureModel;
}

export interface BundleElementModel
  extends WithTextName,
    WithTranslationLabels {
  id: string;
  question_type: QuestionBundleTypeModel;
  display_type: QuestionModelDisplayType;

  code: string | null;

  question_options: QuestionOptionModel[];
  questions: BundleQuestionModel[];

  question_options_structure: StructureModel;
  questions_structure: StructureModel;
}

export interface TranslationLabelsModel {
  [index: number]: {
    code: string;
    name: string;
    name_html: string;
  };
}

export interface QuestionOptionModel
  extends WithTextName,
    WithTranslationLabels {
  id: string;
  code: string | null;
  nota: boolean;
  row: number;
  _destroy: boolean;
  display_logic: DisplayLogicModel | null | boolean;
  open_ended: boolean;
  image: ImageDataModel | null;
}

export interface SearchResultQuestionOptionModel extends WithTextName {
  id: string;
  code: string | null;
}

export interface BundleQuestionModel
  extends WithTextName,
    WithTranslationLabels {
  id?: string;
  code: string | null;
  display_logic: boolean;
  _destroy?: boolean;
}

export interface PipedQuestionModel {
  id: string;
  name: string;
  piping_type: PipingType;
  piping_link_id: string | null;
  code: string | null;
  row: number;
}

export interface ImageDataModel {
  url: string;
  alt_text: string;
  size: ImageSize;
  original_size: ImageSize;
}

export interface ImageSize {
  width: number;
  height: number;
}

// TODO: split this interface to reflect the reality of the schema
// some properties are only present for specific questions
export interface QuestionCreateModel {
  id?: string;
  name: string;
  question_type: QuestionModelType;
  display_type: QuestionModelDisplayType;
  code?: string | null; // Must be unique + can be null + is NOT case sensitive
  random_question_options?: boolean;
  min_answers?: number | null;
  max_answers?: number | null;
  question_options: Partial<QuestionOptionModel>[];
  piping_links?: Partial<PipedQuestionModel>[];
}

export interface BundleQuestionCreateModel {
  id?: string;
  name: string;
  question_type: QuestionBundleTypeModel;
  display_type: QuestionModelDisplayType;
  code: string | null;
  question_options: Partial<QuestionOptionModel>[];
  questions: Partial<BundleQuestionModel>[];
}

export interface QuestionUpdateModel {
  id: string;
  question_type?: QuestionModelType;
  display_type?: QuestionModelDisplayType;
  code?: string | null; // Must be unique + can be null + is NOT case sensitive
  name?: string;
  random_question_options?: boolean;
  min_answers?: number | null;
  max_answers?: number | null;
  question_options?: QuestionOptionUpdateModel[];
  piping_links?: Partial<PipedQuestionModel>[];
}

export interface QuestionBundleUpdateModel {
  id: string;
  name?: string;
  question_type?: QuestionBundleTypeModel;
  display_type?: QuestionModelDisplayType;
  code?: string | null;
  question_options?: QuestionOptionUpdateModel[];
  questions?: QuestionOptionUpdateModel[];
}

export interface QuestionOptionUpdateModel {
  id: string;
  name?: string;
  code?: string | null;
  nota?: boolean;
  image?: ImageDataModel;
  _destroy?: boolean;
}

export interface BundleQuestionUpdateModel {
  id: string;
  name?: string;
  code?: string | null;
  _destroy?: boolean;
}

export type PipedQuestionUpdateModel = Partial<PipedQuestionModel>;

// SCREEN OUT

export interface ScreenOutElementModel {
  id: string;
  name: string;
}

export type ScreenOutCreateModel = Pick<ScreenOutElementModel, 'name'>;
export type ScreenOutUpdateModel = Pick<ScreenOutElementModel, 'name' | 'id'>;

// QUOTA NODE

export interface QuotaNodeModel {
  id: string;
  name: string;
  meta_keys: string[];
}

export type QuotaNodeCreateModel = Pick<QuotaNodeModel, 'name' | 'meta_keys'>;
export type QuotaNodeUpdateModel = Pick<
  QuotaNodeModel,
  'name' | 'meta_keys' | 'id'
>;

// TEXT

export interface TextModel {
  text_node: TextNodeModel;
}

export interface TextNodeModel {
  id: string;
  name: string;
  name_html: string;
  name_raw: string;
  labels?: TranslationLabelsModel;
}

export type TextCreateModel = Pick<TextNodeModel, 'name'>;
export type TextUpdateModel = Pick<TextNodeModel, 'name' | 'id'>;

// WEBHOOK NODE

export interface WebhookNodeModel {
  id: string;
  name: string;
}

/*
export function toBlockModel(block: Block): Partial<BlockModel> {}

export function toNodeModel(node: Node): Partial<NodeModel> {}
*/

/******************************
 DASH
 *****************************/

// SURVEY PLAN ELEMENT

export enum SurveyPlanElementType {
  Block = '[SurveyPlanElementType] Block',
  Node = '[SurveyPlanElementType] Node',
}

export type SurveyPlanElement = Block | Node;

export interface ElementText {
  name: string;
  nameHtml: string;
  nameRaw: string;
}

export interface QuestionOptionTextCodeCouple {
  text: ElementText;
  code: string;
  image: ImageData;
}

export interface TranslationLabels {
  [code: string]: {
    name: string;
    nameHtml: string;
  };
}

export type SurveyPLanElementsView = SurveyPlanBlockElementView[];

export interface SurveyPlanBlockElementView {
  block: { uuid: string; data: RemoteData<Block> };
  structure: RemoteData<Structure>;
  nodes: SurveyPlanNodeElementView[];
}

export interface SurveyPlanNodeElementView {
  node: { uuid: string; data: RemoteData<Node> };
}

// SURVEY PLAN ELEMENT - BLOCK

export class Block {
  readonly type = SurveyPlanElementType.Block;

  uuid: string = this.props.uuid;
  surveyId: string = this.props.surveyId;
  text: ElementText = this.props.text;
  nodes: string[] = this.props.nodes;
  displayLogic: boolean = this.props.displayLogic;

  constructor(private readonly props: Omit<Properties<Block>, 'type'>) {
    if ('props' in props) {
      delete (<any>props).props;
    }
  }
}

// SURVEY PLAN ELEMENT - NODE

export class Node {
  readonly type = SurveyPlanElementType.Node;

  uuid: string = this.props.uuid;
  blockId: string = this.props.blockId;
  data: NodeData = this.props.data;
  displayLogic?: boolean = this.props.displayLogic;

  constructor(private readonly props: Omit<Properties<Node>, 'type'>) {
    if ('props' in props) {
      delete (<any>props).props;
    }
  }
}

// SURVEY PLAN ELEMENT - NODE - DATA

export enum NodeDataType {
  Text = '[NodeDataType] Text',
  RadioQuestion = '[NodeDataType] Radio Question',
  MultipleQuestion = '[NodeDataType] Multiple Question',
  OpenEndedQuestion = '[NodeDataType] Open Ended Question',
  BundleNode = '[NodeDataType] Bundle Node',
  BundleQuestion = '[NodeDataType] Bundle Question',
  LanguageQuestion = '[NodeDataType] Language Question',
  ScreenOutNode = '[NodeDataType] Screen Out Node',
  WebhookNode = '[NodeDataType] WebhookNode Node',
  QuotaNode = '[NodeDataType] Quota Node',
}

export type NodeData =
  | TextData
  | RadioQuestionData
  | MultipleQuestionData
  | OpenEndedQuestionData
  | BundleNodeData
  | LanguageQuestionData
  | ScreenOutData
  | WebhookNodeData
  | QuotaNodeData;

export type NodeQuestionData =
  | RadioQuestionData
  | MultipleQuestionData
  | OpenEndedQuestionData
  | LanguageQuestionData;

// SURVEY PLAN ELEMENT - NODE - DATA - TEXT

export class TextData {
  readonly type = NodeDataType.Text;

  uuid: string = this.props.uuid;
  text: ElementText = this.props.text;
  labels?: TranslationLabels = this.props.labels;

  constructor(private readonly props: Omit<Properties<TextData>, 'type'>) {}
}

// SURVEY PLAN ELEMENT - NODE - DATA - QUESTION - MULTIPLE

export class MultipleQuestionData {
  readonly type = NodeDataType.MultipleQuestion;

  uuid: string = this.props.uuid;
  text: ElementText = this.props.text;
  questionCode: string = this.props.questionCode;
  maxAnswers: number = this.props.maxAnswers;
  minAnswers: number = this.props.minAnswers;
  questionOptions: QuestionOption[] = this.props.questionOptions;
  pipedFrom: PipedQuestion[] | null = this.props.pipedFrom;
  structure: Structure = this.props.structure;
  displayType: QuestionDisplayType = this.props.displayType;
  labels?: TranslationLabels = this.props.labels;

  constructor(
    private readonly props: Omit<Properties<MultipleQuestionData>, 'type'>
  ) {}
}

// SURVEY PLAN ELEMENT - NODE - DATA - QUESTION - BUNDLE

export class BundleNodeData {
  readonly type = NodeDataType.BundleNode;

  uuid: string = this.props.uuid;
  text: ElementText = this.props.text;
  questionCode: string = this.props.questionCode;
  questionType: QuestionBundleType = this.props.questionType;
  questionOptions: QuestionOption[] = this.props.questionOptions;
  questionOptionsStructure: Structure = this.props.questionOptionsStructure;
  questions: BundleQuestion[] = this.props.questions;
  questionsStructure: Structure = this.props.questionsStructure;
  displayType: QuestionDisplayType = this.props.displayType;
  labels?: TranslationLabels = this.props.labels;

  constructor(
    private readonly props: Omit<Properties<BundleNodeData>, 'type'>
  ) {}
}

// SURVEY PLAN ELEMENT - NODE - DATA - QUESTION - RADIO

export class RadioQuestionData {
  readonly type = NodeDataType.RadioQuestion;

  uuid: string = this.props.uuid;
  text: ElementText = this.props.text;
  questionCode: string = this.props.questionCode;
  questionOptions: QuestionOption[] = this.props.questionOptions;
  pipedFrom: PipedQuestion[] | null = this.props.pipedFrom;
  structure: Structure = this.props.structure;
  displayType: QuestionDisplayType = this.props.displayType;
  labels?: TranslationLabels = this.props.labels;

  constructor(
    private readonly props: Omit<Properties<RadioQuestionData>, 'type'>
  ) {}
}

export class OpenEndedQuestionData {
  readonly type = NodeDataType.OpenEndedQuestion;

  uuid: string = this.props.uuid;
  text: ElementText = this.props.text;
  questionCode: string = this.props.questionCode;
  questionOptions: QuestionOption[] = this.props.questionOptions;
  pipedFrom: PipedQuestion[] | null = this.props.pipedFrom;
  structure: Structure = this.props.structure;
  labels?: TranslationLabels = this.props.labels;

  constructor(
    private readonly props: Omit<Properties<OpenEndedQuestionData>, 'type'>
  ) {}
}

export class ScreenOutData {
  readonly type = NodeDataType.ScreenOutNode;
  uuid: string = this.props.uuid;
  name: string = this.props.name;
  constructor(
    private readonly props: Omit<Properties<ScreenOutData>, 'type'>
  ) {}
}

export class QuotaNodeData {
  readonly type = NodeDataType.QuotaNode;
  uuid: string = this.props.uuid;
  name: string = this.props.name;
  keys: string[] = this.props.keys;
  constructor(
    private readonly props: Omit<Properties<QuotaNodeData>, 'type'>
  ) {}
}

export class WebhookNodeData {
  readonly type = NodeDataType.WebhookNode;
  uuid: string = this.props.uuid;
  name: string = this.props.name;

  constructor(
    private readonly props: Omit<Properties<WebhookNodeData>, 'type'>
  ) {}
}

export class LanguageQuestionData {
  readonly type = NodeDataType.LanguageQuestion;

  uuid: string = this.props.uuid;
  text: ElementText = this.props.text;
  questionCode: string = this.props.questionCode;
  questionOptions: QuestionOption[] = this.props.questionOptions;
  pipedFrom: PipedQuestion[] | null = this.props.pipedFrom;
  structure: Structure = this.props.structure;

  constructor(
    private readonly props: Omit<Properties<LanguageQuestionData>, 'type'>
  ) {}
}

// SURVEY PLAN ELEMENT - NODE - DATA - QUESTION - QUESTION OPTION

// QuestionOption and BundleQuestion share a lot of properties - we need a
// base type that both extend, so we can use it in methods that work with both types
export interface BaseQuestionOption {
  uuid: string;
  text: ElementText;
  displayLogic: boolean;
  destroy?: boolean;
  code: string;
  labels?: TranslationLabels;
}

export interface QuestionOption extends BaseQuestionOption {
  row: number;
  openEnded: boolean;
  nota: boolean;
  image: ImageData | null;
}

export type BundleQuestion = BaseQuestionOption;

export interface SearchResultQuestionOption {
  uuid: string;
  text: ElementText;
  code: string;
}

// SURVEY PLAN ELEMENT - NODE - DATA - QUESTION - PIPED QUESTION

export interface PipedQuestion {
  uuid: string;
  questionCode: string;
  text: string;
  pipingType: PipingType;
  pipingLinkId: string;
  row?: number;
}

export interface PipedFrom {
  pipedQuestions: PipedQuestion[];
}

// SURVEY PLAN ELEMENT - NODE - DATA - PIPED FROM

export type PipingType = 'selected' | 'unselected';

// DISPLAY LOGIC
export interface DisplayLogicModel {
  kind: string;
  or: Array<any>;
}

// HELPERS - TYPE MAPS

export const QuestionModelTypeMap = new Map<NodeDataType, QuestionModelType>([
  [NodeDataType.MultipleQuestion, 'MultipleQuestion'],
  [NodeDataType.RadioQuestion, 'RadioQuestion'],
  [NodeDataType.OpenEndedQuestion, 'OpenEndedQuestion'],
  [NodeDataType.LanguageQuestion, 'LanguageQuestion'],
]);

export const QuestionBundleTypeMap = new Map<
  QuestionBundleType,
  QuestionBundleTypeModel
>([
  [QuestionBundleType.Multiple, QuestionBundleTypeModel.MultipleQuestion],
  [QuestionBundleType.Single, QuestionBundleTypeModel.RadioQuestion],
]);

export const QuestionBundleTypeModelMap = new Map<
  QuestionBundleTypeModel,
  QuestionBundleType
>([
  [QuestionBundleTypeModel.MultipleQuestion, QuestionBundleType.Multiple],
  [QuestionBundleTypeModel.RadioQuestion, QuestionBundleType.Single],
]);

// HELPERS - EXTRACT

export function extractSurveyPlanElement<T extends SurveyPlanElement>(
  data: RemoteData<T>
): T {
  switch (data.type) {
    case RemoteDataType.Success:
    case RemoteDataType.Updating:
    case RemoteDataType.Refreshing:
    case RemoteDataType.RefreshingFail:
    case RemoteDataType.UpdatingFail:
      return data.data;

    case RemoteDataType.NotAsked:
    case RemoteDataType.Loading:
    case RemoteDataType.LoadingFail:
      throw new Error(
        `[ExtractSurveyPlanElement] State ${data.type} should not be set for Survey Plan Elements`
      );

    default:
      return assertUnreachable(data);
  }
}

// HELPERS - BLOCK
export const temporaryMockDisplayLogic = {
  or: [
    {
      country: 'Australia',
      kind: 'lives_in',
    },
    {
      country: 'Austria',
      kind: 'lives_in',
    },
  ],
  kind: 'or',
};

export function toBlock(model: BlockModel): Block {
  return new Block({
    uuid: model.id,
    surveyId: model.survey_id,
    text: { name: model.name, nameHtml: '', nameRaw: '' },
    nodes: model.nodes.map((item) => item.id),
    displayLogic:
      model.display_logic !== null &&
      model.display_logic !== false &&
      model.expression !== null,
  });
}

export function toNode(model: NodeModel): Node {
  return new Node({
    uuid: model.id,
    blockId: model.block_id,
    data: toNodeData(model),
    displayLogic: model.display_logic !== null && model.display_logic !== false,
  });
}

// HELPERS - NODE

export function toNodeData(model: NodeModel): NodeData {
  switch (model.type) {
    case 'TextNode':
      return toTextData(<TextNodeModel>model.element);

    case 'RadioQuestion':
    case 'MultipleQuestion':
    case 'OpenEndedQuestion':
    case 'LanguageQuestion':
    case 'Question':
      return toNodeQuestionData(<QuestionElementModel>model.element);
    case 'ScreenOutNode':
      return toNodeScreenOutData(<ScreenOutElementModel>model.element);
    case 'QuotaNode':
      return toQuotaNodeData(<QuotaNodeModel>model.element);
    case 'WebhookNode':
      return toWebhookNodeData(<WebhookNodeModel>model.element);
    case 'Bundle::Node':
      return toQuestionBundleData(<BundleElementModel>model.element);
  }
}

// HELPERS - NODE - TEXT

export function toElementText(model: WithTextName): ElementText {
  return {
    name: model.name,
    nameHtml: model.name_html,
    nameRaw: model.name_raw,
  };
}

export function toTextData(model: TextNodeModel): TextData {
  return new TextData({
    uuid: model.id,
    text: toElementText(model),
    ...(model.labels && { labels: toTranslationLabels(model.labels) }),
  });
}

export function toNodeScreenOutData(
  model: ScreenOutElementModel
): ScreenOutData {
  return new ScreenOutData({
    uuid: model.id,
    name: model.name,
  });
}

export function toQuotaNodeData(model: QuotaNodeModel): QuotaNodeData {
  return new QuotaNodeData({
    uuid: model.id,
    name: model.name,
    keys: model.meta_keys,
  });
}

// HELPERS - NODE - WEBHOOK NODE

export function toWebhookNodeData(model: WebhookNodeModel): WebhookNodeData {
  return new WebhookNodeData({
    uuid: model.id,
    name: model.name,
  });
}

// HELPERS - NODE - QUESTION

function toQuestionDisplayType(
  modelDisplayType: QuestionModelDisplayType
): QuestionDisplayType {
  switch (modelDisplayType) {
    case QuestionModelDisplayType.Column:
      return QuestionDisplayType.QUICK_SELECT;
    case QuestionModelDisplayType.Row:
      return QuestionDisplayType.VERTICAL_LIST;
    case QuestionModelDisplayType.Auto:
    default:
      return QuestionDisplayType.AUTO;
  }
}

export function toNodeQuestionData(
  model: QuestionElementModel
): NodeQuestionData {
  switch (model.question_type) {
    case 'RadioQuestion':
      return toRadioQuestionData(model);

    case 'MultipleQuestion':
      return toMultipleQuestionData(model);

    case 'OpenEndedQuestion':
      return toOpenEndedQuestionData(model);

    case 'LanguageQuestion':
      return toLanguageQuestionData(model);
  }
}

export function toQuestionBundleData(
  model: BundleElementModel
): BundleNodeData {
  return new BundleNodeData({
    uuid: model.id,
    questionType: QuestionBundleTypeModelMap.get(model.question_type),
    text: toElementText(model),
    displayType: toQuestionDisplayType(model.display_type),
    questionOptionsStructure: toStructure(model.question_options_structure),
    questionsStructure: toStructure(model.questions_structure),
    questionCode: model.code || '',
    questionOptions: model.question_options.map((option) =>
      toQuestionOption(option)
    ),
    questions: model.questions.map((question) => toBundleQuestion(question)),
    ...(model.labels && { labels: toTranslationLabels(model.labels) }),
  });
}

export function toMultipleQuestionData(
  model: QuestionElementModel
): MultipleQuestionData {
  return new MultipleQuestionData({
    uuid: model.id,
    text: toElementText(model),
    displayType: toQuestionDisplayType(model.display_type),
    questionCode: model.code || '',
    maxAnswers: model.max_answers,
    minAnswers: model.min_answers,
    questionOptions: model.question_options.map((item) =>
      toQuestionOption(item)
    ),
    pipedFrom: toPipedFrom(model.piped_from || []),
    structure:
      model.structure === undefined
        ? generateQuestionStructure(model.question_options)
        : toQuestionStructure(model.structure),
    ...(model.labels && { labels: toTranslationLabels(model.labels) }),
  });
}

export function toRadioQuestionData(
  model: QuestionElementModel
): RadioQuestionData {
  return new RadioQuestionData({
    uuid: model.id,
    text: toElementText(model),
    displayType: toQuestionDisplayType(model.display_type),
    questionCode: model.code || '',
    questionOptions: model.question_options.map((item) =>
      toQuestionOption(item)
    ),
    pipedFrom: toPipedFrom(model.piped_from || []),
    structure:
      model.structure === undefined
        ? null
        : toQuestionStructure(model.structure),
    ...(model.labels && { labels: toTranslationLabels(model.labels) }),
  });
}

export function toLanguageQuestionData(
  model: QuestionElementModel
): LanguageQuestionData {
  return new LanguageQuestionData({
    uuid: model.id,
    text: toElementText(model),
    questionCode: model.code || '',
    questionOptions: model.question_options.map((item) =>
      toQuestionOption(item)
    ),
    pipedFrom: toPipedFrom(model.piped_from || []),
    structure:
      model.structure === undefined
        ? null
        : toQuestionStructure(model.structure),
  });
}

export function toOpenEndedQuestionData(
  model: QuestionElementModel
): OpenEndedQuestionData {
  return new OpenEndedQuestionData({
    uuid: model.id,
    text: toElementText(model),
    questionCode: model.code || '',
    questionOptions: model.question_options.map((item) =>
      toQuestionOption(item)
    ),
    pipedFrom: toPipedFrom(model.piped_from || []),
    structure:
      model.structure === undefined
        ? null
        : toQuestionStructure(model.structure),
    ...(model.labels && { labels: toTranslationLabels(model.labels) }),
  });
}

export function toQuestionOption(model: QuestionOptionModel): QuestionOption {
  return {
    uuid: model.id,
    text: toElementText(model),
    openEnded: model.open_ended,
    nota: model.nota || false,
    code: model.code || '',
    row: model.row,
    destroy: model._destroy,
    displayLogic: model.display_logic !== null && model.display_logic !== false,
    ...(model.labels && { labels: toTranslationLabels(model.labels) }),
    image: toImageData(model.image),
  };
}

export function toSearchResultQuestionOption(
  model: SearchResultQuestionOptionModel
): SearchResultQuestionOption {
  return {
    code: model.code || '',
    uuid: model.id,
    text: toElementText(model),
  };
}

export function toBundleQuestion(model: BundleQuestionModel): BundleQuestion {
  return {
    uuid: model.id,
    text: toElementText(model),
    displayLogic: model.display_logic !== null && model.display_logic !== false,
    code: model.code || '',
    ...(model.labels && { labels: toTranslationLabels(model.labels) }),
  };
}

export function toTranslationLabels(
  labels: TranslationLabelsModel
): TranslationLabels {
  return Object.entries(labels).reduce((result, [code, element]) => {
    return {
      ...result,
      [code]: {
        name: element.name,
        nameHtml: element.name_html,
      },
    };
  }, {});
}

export function translationLabelsExist(labels?: TranslationLabels) {
  return labels && Object.keys(labels).length > 0;
}

// HELPERS - NODE - QUESTION OPTION

export function toImageData(model: ImageDataModel): ImageData {
  return model === null
    ? null
    : {
        src: model.url,
        alt: model.alt_text,
        size: { width: model.size.width, height: model.size.height },
      };
}

// HELPERS - NODE - QUESTION - PIPED

export function toPipedFrom(
  model: PipedQuestionModel[]
): PipedQuestion[] | null {
  return model.map((item) => toPipedQuestion(item));
}

export function toPipedQuestion(
  model: PipedQuestionModel
): PipedQuestion | null {
  return {
    uuid: model.id,
    text: model.name,
    pipingLinkId: model.piping_link_id,
    pipingType: model.piping_type,
    questionCode: model.code || '',
    row: model.row || -1,
  };
}

// HELPERS - NODE - QUESTION - STRUCTURE

export function toQuestionStructure(
  model: StructureModel
): Structure | undefined | null {
  return {
    randomizeGroups: model.randomize_groups,
    groups: model.groups.map((group) => toQuestionGroup(group)),
  };
}

export function toQuestionGroup(model: StructureGroupModel): StructureGroup {
  return {
    randomizeItems: model.randomize_items,
    fixed: model.fixed,
    uuid: model.uuid,
    text: model.name,
    items: model.items.map((item) => toQuestionGroupItem(item)),
  };
}

export function toQuestionGroupItem(
  model: StructureGroupItemModel
): StructureGroupItem {
  return {
    uuid: model.id,
    fixed: model.fixed,
    text: '',
    code: '',
  };
}

export function generateQuestionStructure(
  items: QuestionOptionModel[]
): Structure {
  return {
    randomizeGroups: false,
    groups: items.map((item) => ({
      randomizeItems: false,
      fixed: false,
      text: 'Group',
      items: [{ uuid: item.id, fixed: false, text: '', code: '' }],
    })),
  };
}
