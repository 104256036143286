<ds-plus-icon *ngIf="iconLeft == 'plus'"></ds-plus-icon>
<ds-google-icon *ngIf="iconLeft == 'google'"></ds-google-icon>
<span *ngIf="iconLeft && !loading && iconLeft!== 'plus'" [ngSwitch]="iconLeft">
    <ds-download-icon *ngSwitchCase="'download'"></ds-download-icon>
    <ds-code-branch-icon *ngSwitchCase="'code-branch'"></ds-code-branch-icon>
    <ds-abacus-icon *ngSwitchCase="'abacus'"></ds-abacus-icon>
    <ds-random-icon *ngSwitchCase="'random'"></ds-random-icon>
    <ds-file-check-icon *ngSwitchCase="'file-check'"></ds-file-check-icon>
</span>
<ds-spinner-icon *ngIf="loading" class="icon"></ds-spinner-icon>
<span><ng-content></ng-content></span>

