import { HttpErrorResponse } from '@angular/common/http';

export enum ErrorType {
  AuthenticationError_InvalidGrant = 'AuthenticationError::InvalidGrant',
  AuthenticationError_InvalidToken = 'AuthenticationError::InvalidToken',
  AuthenticationError_WrongPassword = 'AuthenticationError::WrongPassword',

  AuthorizationError_UsersOverload = 'AuthorizationError::UsersOverload',
  AuthorizationError_RegistrationsClosed = 'AuthorizationError::RegistrationsClosed',
  AuthorizationError_DeactivatedAccount = 'AuthorizationError::DeactivatedAccount',
  AuthorizationError_SecurityTagged = 'AuthorizationError::SecurityTagged',
  AuthorizationError_ResetPasswordToken = 'AuthorizationError::ResetPasswordToken',
  AuthorizationError_NoPermissions = 'AuthorizationError::NoPermissions',
  AuthorizationError_GeoBlocked = 'AuthorizationError::GeoBlocked',
  AuthorizationError_MissingInformation = 'AuthorizationError::MissingInformation',
  AuthorizationError_NoAuthorizationCode = 'AuthorizationError::NoAuthorizationCode',

  ActiveRecord_RecordNotFound = 'ActiveRecord::RecordNotFound',
  ActiveRecord_RecordInvalid = 'ActiveRecord::RecordInvalid',
  ActiveRecord_RecordNotDestroyed = 'ActiveRecord::RecordNotDestroyed',
  ActiveRecord_StatementInvalid = 'ActiveRecord::StatementInvalid',
  ActiveRecord_RecordNotUnique = 'ActiveRecord::RecordNotUnique',

  RecordError_EventNotPermitted = 'RecordError::EventNotPermitted',
  RecordError_UnsupportedAffiliateProvider = 'RecordError::UnsupportedAffiliateProvider',
  RecordError_SurveyLoadingFailed = 'RecordError::SurveyLoadingFailed',
  RecordError_InsufficientFunds = 'RecordError::InsufficientFunds',
  RecordError_QuestionInUse = 'RecordError::QuestionInUse',
  RecordError_QuestionOptionInUse = 'RecordError::QuestionOptionInUse',

  RequestError_ProcessBulkError = 'RequestError::ProcessBulkError',
  RequestError_FileNotFound = 'RequestError::FileNotFound',

  ActionController_ParameterMissing = 'ActionController::ParameterMissing',
  PG_UniqueViolation = 'PG::UniqueViolation',

  UnknownError = 'UnknownError',

  // CloudFlare - OPS
  ExceptionCloudFlareRateLimiting = 'Exception::CloudFlare::RateLimiting',
}

export const ErrorTypeMap = new Map<string, ErrorType>([
  ['AuthenticationError::InvalidGrant', ErrorType.AuthenticationError_InvalidGrant],
  ['AuthenticationError::InvalidToken', ErrorType.AuthenticationError_InvalidToken],
  ['AuthenticationError::WrongPassword', ErrorType.AuthenticationError_WrongPassword],

  ['AuthorizationError::UsersOverload', ErrorType.AuthorizationError_UsersOverload],
  ['AuthorizationError::RegistrationsClosed', ErrorType.AuthorizationError_RegistrationsClosed],
  ['AuthorizationError::DeactivatedAccount', ErrorType.AuthorizationError_DeactivatedAccount],
  ['AuthorizationError::SecurityTagged', ErrorType.AuthorizationError_SecurityTagged],
  ['AuthorizationError::ResetPasswordToken', ErrorType.AuthorizationError_ResetPasswordToken],
  ['AuthorizationError::NoPermissions', ErrorType.AuthorizationError_NoPermissions],
  ['AuthorizationError::GeoBlocked', ErrorType.AuthorizationError_GeoBlocked],
  ['AuthorizationError::MissingInformation', ErrorType.AuthorizationError_MissingInformation],
  ['AuthorizationError::NoAuthorizationCode', ErrorType.AuthorizationError_NoAuthorizationCode],

  ['ActiveRecord::RecordNotFound', ErrorType.ActiveRecord_RecordNotFound],
  ['ActiveRecord::RecordInvalid', ErrorType.ActiveRecord_RecordInvalid],
  ['ActiveRecord::RecordNotDestroyed', ErrorType.ActiveRecord_RecordNotDestroyed],
  ['ActiveRecord::StatementInvalid', ErrorType.ActiveRecord_StatementInvalid],
  ['ActiveRecord::RecordNotUnique', ErrorType.ActiveRecord_RecordNotUnique],

  ['RecordError::EventNotPermitted', ErrorType.RecordError_EventNotPermitted],
  ['RecordError::UnsupportedAffiliateProvider', ErrorType.RecordError_UnsupportedAffiliateProvider],
  ['RecordError::SurveyLoadingFailed', ErrorType.RecordError_SurveyLoadingFailed],
  ['RecordError::InsufficientFunds', ErrorType.RecordError_InsufficientFunds],
  ['RecordError::QuestionInUse', ErrorType.RecordError_QuestionInUse],
  ['RecordError::QuestionOptionInUse', ErrorType.RecordError_QuestionOptionInUse],

  ['RequestError::ProcessBulkError', ErrorType.RequestError_ProcessBulkError],
  ['RequestError::FileNotFound', ErrorType.RequestError_FileNotFound],

  ['ActionController::ParameterMissing', ErrorType.ActionController_ParameterMissing],
  ['PG::UniqueViolation', ErrorType.PG_UniqueViolation],

  ['Exception::CloudFlare::RateLimiting', ErrorType.ExceptionCloudFlareRateLimiting],
]);

export interface ErrorInfo {
  piping: ErrorNodeInfo[];
}

export interface ErrorNodeInfo {
  code: string;
  id: string;
  name: string;
  type: ErrorInfoType;
  question?: ErrorNodeInfo;
}

export class ApiError {
  type: ErrorType;
  messages: string[];
  codes: number[];
  originalResponse: HttpErrorResponse;
  data: object;
  info?: ErrorInfo;

  constructor(response: HttpErrorResponse) {

    if (!response.error) {
      console.warn(response);
      throw new Error('[ApiError] Unknown response format');
    }

    this.originalResponse = response;

    if (!response.error.error) {
      console.warn('[ApiError] Error format is incorrect!', response);
      this.setUnknownError();
      return;
    }

    this.setKnownError(response.error.error);
  }

  private setKnownError(error) {
    if (!ErrorTypeMap.get(error.kind)) {
      console.warn(`[ApiError] Error kind ${error.kind} not added to types!`);
    }

    this.type = ErrorTypeMap.get(error.kind) || ErrorType.UnknownError;
    this.messages = error.messages;
    this.codes = error.codes;
    this.data = error.data || {};
    this.info = error.info || {piping: []};
  }

  private setUnknownError() {
    this.type = ErrorType.UnknownError;
    this.messages = ['Ooops, something went wrong!'];
    this.codes = [];
  }
}

export enum ErrorInfoType {
  RadioQuestion = 'RadioQuestion',
  MultipleQuestion = 'MultipleQuestion',
  OpenEndedQuestion = 'OpenEndedQuestion',
  QuestionOption = 'QuestionOption',
  BundleNode = 'BundleNode',
  BundleQuestion = 'BundleQuestion',
  LanguageQuestion = 'LanguageQuestion',
  Block = 'Block'
}
