import { assertUnreachable } from '@app/lib/utils';
import { PaginationModel } from '@shared/models/meta.model';
import {
  ElementText,
  NodeDataType,
  QuestionOptionModel,
  SearchResultQuestionOption,
  toElementText,
  toSearchResultQuestionOption,
  WithTextName,
} from './survey-plan-elements.model';

export type SearchNodeModelType =
  'Bundle::Question'
  | 'RadioQuestion'
  | 'LanguageQuestion'
  | 'MultipleQuestion'
  | 'OpenEndedQuestion';


export interface SearchNodeModel extends WithTextName {
  code: string | null;
  id: string;
  survey_id: string;
  question_type: SearchNodeModelType;
  question_options: QuestionOptionModel[];

  bundle_id: string | null;
  bundle_name: string | null;
  bundle_code: string | null;
}

export interface QuestionsSearchResponseModel {
  meta: PaginationModel;
  results: SearchNodeModel[];
}

export interface SearchNode {
  text: ElementText;
  questionCode?: string | null;
  code: string | null;
  id: string;
  surveyId: string;
  type: NodeDataType;
  questionOptions: SearchResultQuestionOption[];
  parentId?: string;
  parentName?: string;
  parentCode?: string;
}
export function toSearchNode(searchNodeModel: SearchNodeModel): SearchNode {
  return {
    code: searchNodeModel.code,
    id: searchNodeModel.id,
    surveyId: searchNodeModel.survey_id,
    type: mapType(searchNodeModel.question_type),
    text: toElementText(searchNodeModel),
    questionOptions: searchNodeModel.question_options.map((option) => toSearchResultQuestionOption(option)),
    parentId: searchNodeModel.bundle_id,
    parentName: searchNodeModel.bundle_name,
    parentCode: searchNodeModel.bundle_code,
  };
}

function mapType (modelType: SearchNodeModelType): NodeDataType {
  switch (modelType) {
    case 'Bundle::Question':
      return NodeDataType.BundleQuestion;

    case 'LanguageQuestion':
      return NodeDataType.LanguageQuestion;

    case 'RadioQuestion':
      return NodeDataType.RadioQuestion;

    case 'MultipleQuestion':
      return NodeDataType.MultipleQuestion;

    case 'OpenEndedQuestion':
      return NodeDataType.OpenEndedQuestion;

    default:
      return assertUnreachable(modelType);
  }
}
