<ds-info-page>
  <img title-image src="../../assets/svg/lost.svg">

  <h1 class="H3">Oops, something went wrong...</h1>
  <p class="body-small">
    The page you were looking for could not be found.
    We're sorry about that! Click below to go back home.
  </p>
  <button ds-button class="large-size" routerLink="/">Back Home</button>
</ds-info-page>
