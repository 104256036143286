import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import * as fromRoot from '@app/core/reducers';

import { User } from '@app/core/models/user.model';

@Injectable()
export class UserGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router,
              private store: Store<fromRoot.State>) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(fromRoot.userProfile),
      take(1),
      map((user: User) => user !== null),
      tap((hasUser: boolean) => {
        if (!hasUser) {
          this.router.navigate(['/public/sign-in'])
            .catch((err) => console.warn(err));
        }
      })
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }
}
