import { NgModule } from '@angular/core';
import {
  IsLoadingFailPipe,
  IsLoadingPipe,
  IsNotAskedPipe, IsRefreshingFailPipe,
  IsRefreshingPipe,
  IsSuccessPipe, IsUpdatingFailPipe, IsUpdatingPipe
} from '@app/remote-data/models/remote-data.model';
import { RemoteDataComponent } from './containers/remote-data/remote-data.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    IsNotAskedPipe,
    IsSuccessPipe,
    IsLoadingPipe,
    IsLoadingFailPipe,
    IsRefreshingPipe,
    IsRefreshingFailPipe,
    IsUpdatingPipe,
    IsUpdatingFailPipe,
    RemoteDataComponent,
  ],
  exports: [
    IsNotAskedPipe,
    IsSuccessPipe,
    IsLoadingPipe,
    IsLoadingFailPipe,
    IsRefreshingPipe,
    IsRefreshingFailPipe,
    IsUpdatingPipe,
    IsUpdatingFailPipe,
    RemoteDataComponent
  ],
})
export class RemoteDataModule {
}
