import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ds-view-main',
  templateUrl: './view-main.component.html',
  styleUrls: ['./view-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewMainComponent {

  constructor() { }

}
