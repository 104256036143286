import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/*
 * Based on https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_doc.cookie_framework
 */

@Injectable({
  providedIn: 'root'
})
export class CookiesService {
  constructor(@Inject(DOCUMENT) private doc: any) {
  }

  getItem(sKey: string) {
    const key = encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&');
    const regExp = new RegExp(`(?:(?:^|.*;)\\s*${key}\\s*\\=\\s*([^;]*).*$)|^.*$`);

    return decodeURIComponent(this.doc.cookie.replace(regExp, '$1')) || null;
  }

  setItem(sKey: string, sValue: string, vEnd: Date | null, sPath?: string, sDomain?: string, bSecure?: boolean) {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
      return false;
    }

    const key = encodeURIComponent(sKey);
    const value = encodeURIComponent(sValue);
    const expires = vEnd ? `; expires=${vEnd.toUTCString()}` : '; expires=Fri, 31 Dec 9999 23:59:59 GMT';
    const domain = sDomain ? `; domain=${sDomain}` : '';
    const path = sPath ? `; path=${sPath}` : '';
    const secure = bSecure ? '; secure' : '';

    this.doc.cookie = `${key}=${value}${expires}${domain}${path}${secure}`;
    return true;
  }

  removeItem(sKey: string, sPath?: string, sDomain?: string) {
    if (!this.hasItem(sKey)) {
      return false;
    }

    const key = encodeURIComponent(sKey);
    const domain = sDomain ? `; domain=${sDomain}` : '';
    const path = sPath ? `; path=${sPath}` : '';

    this.doc.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT${domain}${path}`;
    return true;
  }

  hasItem(sKey) {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
      return false;
    }

    const key = encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&');
    const regExp = new RegExp(`(?:^|;\\s*)${key}\\s*\\=`);

    return regExp.test(this.doc.cookie);
  }

  keys() {
    return this.doc.cookie.replace(
      /((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, ''
    )
      .split(/\s*(?:\=[^;]*)?;\s*/)
      .map((val) => decodeURIComponent(val));
  }

  consentToCookies() {
    return this.setItem('consent', 'true', null);
  }

  hasConsentForCookies() {
    return this.hasItem('consent') && this.getItem('consent') === 'true';
  }
}
