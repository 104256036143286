import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ds-view-aside',
  templateUrl: './view-aside.component.html',
  styleUrls: ['./view-aside.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewAsideComponent {

  constructor() { }

}
