import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'ds-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit {
  @Input() placeholder: string;
  @Input() type = 'text';

  @Output() searchInput: EventEmitter<string> = new EventEmitter<string>();

  searchForm: FormGroup = new FormGroup({
    'search': new FormControl('', [Validators.required, Validators.minLength(1)])
  });

  searchIconVisible = true;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.searchForm.get('search').valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((searchValue: string) => {
      this.router.navigate([], { queryParams: {query: searchValue}});
      this.searchInput.emit(searchValue);
    });
    const query = this.router.parseUrl(this.router.url).queryParamMap.get('query');
    if (query) {
      this.searchForm.setValue({ search: query });
      this.searchIconVisible = false;
    }
  }

  onSearchFocus(): void {
    this.searchIconVisible = false;
  }

  onSearchFocusOut(): void {
    this.searchIconVisible = this.searchForm.get('search').value === '';
  }

  get searchIcon(): string {
    return this.type === 'date' ? 'calendar' : 'search';
  }
}
