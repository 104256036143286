<div class="title">
  <ds-element-icon [iconType]="iconType" class="icon"></ds-element-icon>
  <ds-html-text [htmlText]="displayText | elementText" class="text S1"></ds-html-text>
  <div *ngIf="hasQuestionCode" class="code counters">
    <div *ngIf="isBundleQuestion">{{parentCode}}_</div>
    {{questionCode}}
  </div>
</div>

<div class="content">
  <div *ngIf="isBundleQuestion" class="options-count body-small">
    A part of question bundle '<strong>{{ data.parentName }}</strong>' with {{ questionOptionsTotal}} shared answers.
  </div>
  <div *ngIf="!isBundleQuestion" class="options-count body-small">{{questionOptionsTotal}} answers</div>
</div>
