import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserModel, User, toUser } from '@app/core/models/user.model';
import { catchError, map, pluck } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError as _throw } from 'rxjs';
import { ApiError } from '../models/error.model';
import { environment } from '@env/environment';
import { GWI_API_PRODUCTION, GWI_API_STAGING } from '../constants';

export interface GwiRefreshTokenModel {
  access_token: string;
  access_token_expires_at: string;
  refresh_token: string;
  refresh_token_expires_at: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(private api: ApiService,
    private readonly http: HttpClient,
    ) {}

  requestGWIRefreshedToken(): Observable<GwiRefreshTokenModel> {
    const gwiRefreshUrl = `${environment.runningTestEnv ? GWI_API_STAGING : GWI_API_PRODUCTION}/v1/users-next/refresh_tokens`;
    return this.http.post<GwiRefreshTokenModel>(gwiRefreshUrl, {})
    .pipe(
      catchError((response: HttpErrorResponse) => _throw(new ApiError(response))),
    );
  }

  me(): Observable<User> {
    return this.api.get('/me').pipe(
      pluck('user'),
      map((data: UserModel) => toUser(data))
    );
  }
}
