import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { PortalModule } from '@angular/cdk/portal';

import {
  AlertComponent,
  ButtonComponent,
  ButtonIconComponent,
  CheckboxComponent,
  CheckboxIconComponent,
  HtmlTextComponent,
  InputComponent,
  LabelComponent,
  LinkComponent,
  ElementIconComponent,
  PaginationComponent,
  RadioComponent,
  SearchComponent,
  SearchNoFrameComponent,
  SelectComponent,
  SwitchComponent,
  LoadingSpinnerComponent,
  BlockElementPreviewComponent,
  QuestionElementPreviewComponent,
  TextElementPreviewComponent,
  ScreenOutElementPreviewComponent,
  SurveyViewPreviewComponent,
  QuestionOptionElementPreviewComponent,
  ElementPreviewFrameComponent,
  DebugPanelComponent,
  TabGroupComponent,
  TabComponent,
  ProgressComponent,
  SurveysStateFilterComponent,
  SnackBarMessageComponent,
  ToggleContentComponent,
} from './components';
import {
  LayoutSurveyBuilderComponent,
  ModalComponent,
  ViewAsideComponent,
  ViewMainComponent,
  InfoPageComponent,
  ElementPreviewPanelComponent,
  SearchQuestionPanelComponent,
  LayoutLogicModalComponent,
} from '@shared/containers';
import { AnchorDirective, TextareaAutosizeDirective } from '@shared/directives';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  AddPipingModalComponent,
  ConfirmationModalComponent,
  ModalContainerComponent,
  NewSurveyModalComponent,
} from '@shared/modals';
import { DsRadioControlRegistry } from '@shared/components/radio/radio.component';
import { TooltipModule } from '@shared/components/tooltip/tooltip.module';
import { ElementTextPipe } from './pipes/element-text.pipe';
import { TabulaIconModule } from './components/tabula-icon-components/tabula-icons.module';
import { TransitionModalComponent } from './modals/dash2-transition/transition-modal/transition-modal/transition-modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    PortalModule,
    TooltipModule,
    TabulaIconModule,
  ],
  providers: [DsRadioControlRegistry, ElementTextPipe],
  declarations: [
    AddPipingModalComponent,
    AlertComponent,
    AnchorDirective,
    BlockElementPreviewComponent,
    ButtonComponent,
    ButtonIconComponent,
    CheckboxComponent,
    CheckboxIconComponent,
    ConfirmationModalComponent,
    DebugPanelComponent,
    ElementIconComponent,
    ElementPreviewFrameComponent,
    ElementPreviewPanelComponent,
    ElementTextPipe,
    HtmlTextComponent,
    InfoPageComponent,
    InputComponent,
    LabelComponent,
    LayoutLogicModalComponent,
    LayoutSurveyBuilderComponent,
    LinkComponent,
    LoadingSpinnerComponent,
    ModalComponent,
    ModalContainerComponent,
    NewSurveyModalComponent,
    PaginationComponent,
    ProgressComponent,
    QuestionElementPreviewComponent,
    QuestionOptionElementPreviewComponent,
    RadioComponent,
    ScreenOutElementPreviewComponent,
    SearchComponent,
    SearchNoFrameComponent,
    SearchQuestionPanelComponent,
    SelectComponent,
    SnackBarMessageComponent,
    SurveysStateFilterComponent,
    ToggleContentComponent,
    SurveyViewPreviewComponent,
    SwitchComponent,
    TabComponent,
    TabGroupComponent,
    TextareaAutosizeDirective,
    TextElementPreviewComponent,
    ViewAsideComponent,
    ViewMainComponent,
    TransitionModalComponent,
  ],
  exports: [
    AddPipingModalComponent,
    AlertComponent,
    AnchorDirective,
    BlockElementPreviewComponent,
    ButtonComponent,
    ButtonIconComponent,
    CheckboxComponent,
    CheckboxIconComponent,
    CommonModule,
    ConfirmationModalComponent,
    DebugPanelComponent,
    ElementIconComponent,
    ElementPreviewFrameComponent,
    ElementPreviewPanelComponent,
    ElementTextPipe,
    HtmlTextComponent,
    InfoPageComponent,
    InputComponent,
    LabelComponent,
    LayoutLogicModalComponent,
    LayoutSurveyBuilderComponent,
    LinkComponent,
    LoadingSpinnerComponent,
    ModalComponent,
    ModalContainerComponent,
    NewSurveyModalComponent,
    PaginationComponent,
    ProgressComponent,
    ToggleContentComponent,
    QuestionElementPreviewComponent,
    QuestionOptionElementPreviewComponent,
    RadioComponent,
    ScreenOutElementPreviewComponent,
    SearchComponent,
    SearchNoFrameComponent,
    SearchQuestionPanelComponent,
    SelectComponent,
    SurveyViewPreviewComponent,
    SurveysStateFilterComponent,
    SwitchComponent,
    TabComponent,
    TabComponent,
    TabGroupComponent,
    TextElementPreviewComponent,
    TextareaAutosizeDirective,
    TooltipModule,
    ViewAsideComponent,
    ViewMainComponent,
    TabulaIconModule,
  ],
})
export class SharedModule {}
