export interface BrowserStorage {
  setItem(key: string, val: any): void;
  removeItem(key: string): void;
  getItem(key: string): any;
  clear(): void;
}

export class MemoryStorage implements BrowserStorage {
  data: object = {};

  setItem(key, val) {
    this.data[key] = val;
  }

  removeItem(key) {
    delete this.data[key];
  }

  getItem(key) {
    return this.data[key];
  }

  clear() {
    this.data = {};
  }
}
