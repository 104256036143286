import { Overlay, ScrollStrategy, } from '@angular/cdk/overlay';
import { InjectionToken, } from '@angular/core';

export type TooltipPosition = 'left' | 'right' | 'above' | 'below' | 'before' | 'after';
export type TooltipArrowPosition = 'left' | 'right' | 'middle';

/** Time in ms to throttle repositioning after scroll events. */
export const SCROLL_THROTTLE_MS = 20;

/** CSS class that will be attached to the overlay panel. */
export const TOOLTIP_PANEL_CLASS = 'ds-tooltip-panel';

/**
 * Creates an error to be thrown if the user supplied an invalid tooltip position.
 */
export function getDsTooltipInvalidPositionError(position: string) {
  return Error(`Tooltip position "${position}" is invalid.`);
}

/** Injection token that determines the scroll handling while a tooltip is visible. */
export const DS_TOOLTIP_SCROLL_STRATEGY =
  new InjectionToken<() => ScrollStrategy>('ds-tooltip-scroll-strategy');

export function DS_TOOLTIP_SCROLL_STRATEGY_FACTORY(overlay: Overlay): () => ScrollStrategy {
  return () => overlay.scrollStrategies.reposition({ scrollThrottle: SCROLL_THROTTLE_MS });
}

export const DS_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER = {
  provide: DS_TOOLTIP_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: DS_TOOLTIP_SCROLL_STRATEGY_FACTORY,
};

/** Default `dsTooltip` options that can be overridden. */
export interface DSTooltipDefaultOptions {
  showDelay: number;
  hideDelay: number;
  touchendHideDelay: number;
  position?: TooltipPosition;
}

/** Injection token to be used to override the default options for `dsTooltip`. */
export const DS_TOOLTIP_DEFAULT_OPTIONS =
  new InjectionToken<DSTooltipDefaultOptions>('ds-tooltip-default-options', {
    providedIn: 'root',
    factory: DS_TOOLTIP_DEFAULT_OPTIONS_FACTORY
  });

export function DS_TOOLTIP_DEFAULT_OPTIONS_FACTORY(): DSTooltipDefaultOptions {
  return {
    showDelay: 0,
    hideDelay: 0,
    touchendHideDelay: 1500,
  };
}

export type TooltipVisibility = 'initial' | 'visible' | 'hidden';

