import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ds-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true
  }]
})
export class CheckboxComponent implements ControlValueAccessor {
  @HostBinding('class.disabled')
  get disabledValue() {
    return this.disabled;
  }

  @ViewChild('checkbox', { static: true }) inputElement;

  @Input() id: string;
  @Input() name: string;
  @Input() value: string;
  @Input() content: string | null = null;
  @Input()
  get checked(): boolean {
    return this._checked;
  }
  set checked(value: boolean) {
    this._checked = value;
    this.inputElement.nativeElement.checked = this._checked;
  }
  @Input()
  isDisabled: boolean;

  @Output() readonly change = new EventEmitter<boolean>();

  _checked: boolean;
  disabled: boolean;

  onChange = (value: any) => {};
  onTouched = () => {};

  constructor() { }

  inputClicked(event: Event) {
    event.stopPropagation();
  }

  inputValueChange(event: Event) {
    event.stopPropagation();

    this._checked = this.inputElement.nativeElement.checked;

    this.emitChangeEvent(this._checked);
  }

  emitChangeEvent(checked: boolean) {
    this.onChange(checked);
    this.change.emit(checked);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.checked = !!value;
  }
}
