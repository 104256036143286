import { AfterViewInit, Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormGroup, NgControl } from '@angular/forms';
import { QuestionDisplayType, SegmentData, SegmentModel, QuestionBundleType } from '@app/survey-element-edit/models/segment.model';
import { ElementText, PipingType, SurveyPlanElement, NodeDataType } from '@app/surveys/models/survey-plan-elements.model';
import { Subject } from 'rxjs';
import { ImageData } from '../modals/image-upload-modal/image-upload-modal.component';

export enum ElementEditMode {
  Compact = 'compact',
  Expanded = 'expanded'
}

export enum ElementEditFormMode {
  Edit = 'edit',
  Create = 'create'
}

@Directive()
export abstract class ElementSegmentDirective<T extends SegmentModel, D extends SegmentData>
  implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  @Input() element: SurveyPlanElement | null;
  @Input() configuration: SegmentConfiguration;
  @Input() locked: false;

  protected state = new FormGroup({});
  protected data: D;
  protected ngUnsubscribe$ = new Subject<void>();
  textChanged = false;

  protected get showIn(): ElementEditMode[] {
    return this.configuration.showInMode;
  }

  onTouched: () => void;

  protected constructor(public ngControl: NgControl | null) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.data = this.defaultData(this.configuration, this.element);
    this.setForm(this.data);
  }

  ngAfterViewInit(): void {
    this.handleLinkedDependencies();

    if (this.ngControl === null) {
      return;
    }

    const control = this.ngControl.control;

    const extraValidators = [() => {
      return this.state.valid ? null : {
        invalidSegment: {
          valid: false,
          message: `${this.configuration.name} is invalid!`
        }
      };
    }];
    const validators = control.validator
      ? [control.validator, ...extraValidators]
      : extraValidators;

    control.setValidators(validators);
    control.updateValueAndValidity();

    this.callAfterViewInit();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
  }

  protected abstract callAfterViewInit();

  protected abstract setForm(data: D);

  protected abstract handleLinkedDependencies();

  protected abstract defaultData(configuration: SegmentConfiguration, element: SurveyPlanElement | null): D;

  // CONTROL VALUE ACCESSOR

  writeValue(val: any): void {
    if (val) {
      this.state.setValue(val, {emitEvent: false});
    }
  }

  registerOnChange(fn: any): void {
    this.state.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.state.disable() : this.state.enable();
  }

  // PUBLIC INTERFACE

  abstract setEditState(state: ElementEditMode);

  abstract segmentModel(): T;
}

export interface ElementEditDataModel {
  text: ElementText;
  questionBundleType: QuestionBundleType;
  questionCodesEnabled: boolean;
  questionCode: string | null;
  questionOptions: QuestionOptionEditData[];
  questions: QuestionOptionEditData[];
  randomizeQuestionOptions: boolean;
  randomizeQuestions: boolean;
  maxAnswers: number | null;
  minAnswers: number | null;
  pipedQuestionEnabled: boolean;
  pipedFrom: PipedQuestionEditDataModel[];
  questionDisplayTypeEnabled: boolean;
  questionDisplayType: QuestionDisplayType;
}

export interface PipedQuestionEditDataModel {
  uuid: string;
  questionCode: string;
  text: string;
  pipingType: PipingType;
  pipingLinkId: string;

}

export interface QuestionOptionEditData {
  uuid: string;
  text: ElementText;
  openEnded: boolean;
  nota: boolean;
  code: string | null;
  row: number;
  image: ImageData;
}

// ELEMENT - CONFIGURATION

export enum ElementConfigurationType {
  Block = '[ElementConfigurationType] Block',
  RadioQuestionNode = '[ElementConfigurationType] Radio Question Node',
  MultiQuestionNode = '[ElementConfigurationType] Multi Question Node',
  BundleNode = '[ElementConfigurationType] Bundle Node',
  LanguageQuestionNode = '[ElementConfigurationType] Language Question Node',
  OpenEndedQuestionNode = '[ElementConfigurationType] Open Ended Question Node',
  TextNode = '[ElementConfigurationType] Text Node',
  ScreenOutNode = '[ElementConfigurationType] Screen Out Node',
  QuotaNode = '[ElementConfigurationType] Quota Node',
  WebhookNode = '[ElementConfigurationType] Webhook Node'
}

export type ElementRouteTypeParam =
  'radio-question'
  | 'multi-question'
  | 'bundle-question'
  | 'language-question'
  | 'open-ended-question'
  | 'text'
  | 'screen-out'
  | 'quota'
  | 'block'
  | 'webhook';

export const RouteParamToElementType = new Map<ElementRouteTypeParam, ElementConfigurationType>([
  ['radio-question', ElementConfigurationType.RadioQuestionNode],
  ['multi-question', ElementConfigurationType.MultiQuestionNode],
  ['bundle-question', ElementConfigurationType.BundleNode],
  ['language-question', ElementConfigurationType.LanguageQuestionNode],
  ['open-ended-question', ElementConfigurationType.OpenEndedQuestionNode],
  ['text', ElementConfigurationType.TextNode],
  ['screen-out', ElementConfigurationType.ScreenOutNode],
  ['webhook', ElementConfigurationType.WebhookNode],
  ['quota', ElementConfigurationType.QuotaNode],
  ['block', ElementConfigurationType.Block]
]);

export const SurveyPlanElementToConfigurationType = new Map<NodeDataType, ElementConfigurationType>([
  [NodeDataType.Text, ElementConfigurationType.TextNode],
  [NodeDataType.LanguageQuestion, ElementConfigurationType.LanguageQuestionNode],
  [NodeDataType.RadioQuestion, ElementConfigurationType.RadioQuestionNode],
  [NodeDataType.MultipleQuestion, ElementConfigurationType.MultiQuestionNode],
  [NodeDataType.BundleNode, ElementConfigurationType.BundleNode],
  [NodeDataType.OpenEndedQuestion, ElementConfigurationType.OpenEndedQuestionNode],
  [NodeDataType.QuotaNode, ElementConfigurationType.QuotaNode],
  [NodeDataType.WebhookNode, ElementConfigurationType.WebhookNode],
  [NodeDataType.ScreenOutNode, ElementConfigurationType.ScreenOutNode],
]);

export enum SegmentName {
  TITLE = '[SegmentName] Title',
  QUESTION_OPTIONS = '[SegmentName] Question Options',
  QUESTION_BUNDLE = '[SegmentName] Question Bundle',
  BUNDLE_RANDOMIZATION = '[SegmentName] Bundle Randomization',
  QUESTION_CODE = '[SegmentName] Question Code',
  MIN_MAX = '[SegmentName] MinMax',
  PIPED_FROM = '[SegmentName] Piped From',
  PIPED_SWITCH = '[SegmentName] Piped Switch',
  QUESTION_DISPLAY_TYPE = '[SegmentName] Question Display Type',
}

export type SegmentControlName =
  'title'
  | 'questionOptions'
  | 'questionCode'
  | 'questionBundle'
  | 'bundleRandomization'
  | 'minMax'
  | 'pipedFrom'
  | 'pipedSwitch'
  | 'questionDisplayType';

export const SegmentFormControlsNames = new Map<SegmentName, SegmentControlName>([
  [SegmentName.TITLE, 'title'],
  [SegmentName.QUESTION_BUNDLE, 'questionBundle'],
  [SegmentName.BUNDLE_RANDOMIZATION, 'bundleRandomization'],
  [SegmentName.QUESTION_OPTIONS, 'questionOptions'],
  [SegmentName.QUESTION_CODE, 'questionCode'],
  [SegmentName.MIN_MAX, 'minMax'],
  [SegmentName.PIPED_FROM, 'pipedFrom'],
  [SegmentName.PIPED_SWITCH, 'pipedSwitch'],
  [SegmentName.QUESTION_DISPLAY_TYPE, 'questionDisplayType'],
]);

export interface SegmentConfiguration {
  name: SegmentName;
  defaults: SegmentData;
  showInMode: ElementEditMode[];
}

export type SegmentsConfigurations = Map<SegmentName, SegmentConfiguration>;

export interface ElementConfiguration {
  type: ElementConfigurationType;
  routeType: ElementRouteTypeParam;
  name: string;
  description: string;
  order: SegmentName[];
  segments: SegmentsConfigurations;
}
