import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ConfirmationModalData, ModalRef } from '@core/models/modal.model';
import { DS_MODAL_DATA } from '@core/tokens';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationModalComponent {

  constructor(public modalRef: ModalRef<ConfirmationModalComponent, {}>,
              @Inject(DS_MODAL_DATA) public data: ConfirmationModalData) {
  }

  close(confirm: boolean) {
    this.modalRef.close(confirm);
  }
}
