import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SurveysService } from '@app/surveys/services';
import { map } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModalRef } from '@core/models/modal.model';
import { SurveyViewModel } from '@app/surveys/models/survey.model';


@Component({
  selector: 'ds-new-survey-modal',
  templateUrl: './new-survey-modal.component.html',
  styleUrls: ['./new-survey-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewSurveyModalComponent implements OnInit {
  form: FormGroup;

  errorMessages$ = new BehaviorSubject<string[]>([]);


  constructor(private surveysService: SurveysService,
              public modalRef: ModalRef<NewSurveyModalComponent, {}>) {
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required)
    });
  }

  close(confirm: boolean) {
    this.modalRef.close(confirm);
  }

  createNewSurvey() {
    const name = this.form.get('name').value.trim();

    this.surveysService.createSurvey(name)
      .subscribe(
        (survey: SurveyViewModel) => {
          this.modalRef.close(survey.id);
        },
        (error) => {
          this.errorMessages$.next(error.messages);
        });
  }

  get hasError(): Observable<boolean> {
    return this.errorMessages$.pipe(
      map((errors) => errors.length > 0)
    );
  }
}
