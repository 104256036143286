import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { TooltipArrowPosition, TooltipPosition, TooltipVisibility } from './tooltip.model';

/**
 * Internal component that wraps the tooltip's content.
 */
@Component({
  selector: 'ds-tooltip-component',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent implements OnDestroy {
  @HostBinding('style.zoom')
  get isZoom() {
    return this.visibility === 'visible' ? 1 : null;
  }

  // Forces the element to have a layout in IE and Edge. This fixes issues where the element
  // won't be rendered if the animations are disabled or there is no web animations polyfill.
  @HostBinding('style.aria-hidden') ariaHidden = true;

  // Message to display in the tooltip
  message: string;
  // Classes to be added to the tooltip. Supports the same syntax as `ngClass`.
  tooltipClass: string | string[] | Set<string> | { [key: string]: any };
  // The timeout ID of any current timer set to show the tooltip
  showTimeoutId: number | null | any;
  // The timeout ID of any current timer set to hide the tooltip
  hideTimeoutId: number | null | any;
  // Property watched by the animation framework to show or hide the tooltip
  visibility: TooltipVisibility = 'initial';
  // Tooltip can or cannot have arrow
  hasArrow = true;
  // The position set for arrow
  tooltipArrowPosition: TooltipArrowPosition;
  // The position of the tooltip
  tooltipPosition: TooltipPosition;

  // The class of the arrow will change if the position is inverted
  get tooltipArrowClass() {
    return `${(this.isInverted && this.tooltipPosition === 'below') ? 'bottom' : 'top'} ${this.tooltipArrowPosition}`;
  }

  // Whether interactions on the page should close the tooltip
  private closeOnInteraction = true;
  // Whether the tooltip position is inverted due to viewport not in view
  private isInverted = false;
  // Subject for notifying that the tooltip has been hidden from the view
  private readonly onHide: Subject<any> = new Subject();

  constructor(private cdr: ChangeDetectorRef) {
  }

  show(delay: number): void {
    // Cancel the delayed hide if it is scheduled
    if (this.hideTimeoutId) {
      clearTimeout(this.hideTimeoutId);
      this.hideTimeoutId = null;
    }

    // Body interactions should cancel the tooltip if there is a delay in showing.
    this.closeOnInteraction = true;
    this.showTimeoutId = setTimeout(() => {
      this.visibility = 'visible';
      this.showTimeoutId = null;

      // Mark for check so if any parent component has set the
      // ChangeDetectionStrategy to OnPush it will be checked anyways
      this.markForCheck();
    }, delay);
  }

  hide(delay: number): void {
    // Cancel the delayed show if it is scheduled
    if (this.showTimeoutId) {
      clearTimeout(this.showTimeoutId);
      this.showTimeoutId = null;
    }

    this.hideTimeoutId = setTimeout(() => {
      this.visibility = 'hidden';
      this.hideTimeoutId = null;

      // Mark for check so if any parent component has set the
      // ChangeDetectionStrategy to OnPush it will be checked anyways
      this.markForCheck();
    }, delay);
  }

  /** Returns an observable that notifies when the tooltip has been hidden from view. */
  afterHidden(): Observable<void> {
    return this.onHide.asObservable();
  }

  isVisible(): boolean {
    return this.visibility === 'visible';
  }

  ngOnDestroy() {
    this.onHide.complete();
  }

  setIsInverted(isInverted: boolean) {
    this.isInverted = isInverted;
  }

  @HostListener('body:click')
  handleBodyInteraction(): void {
    if (this.closeOnInteraction) {
      this.hide(0);
    }
  }

  /**
   * Marks that the tooltip needs to be checked in the next change detection run.
   * Mainly used for rendering the initial text before positioning a tooltip, which
   * can be problematic in components with OnPush change detection.
   */
  markForCheck(): void {
    this.cdr.markForCheck();
  }
}
