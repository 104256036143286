import { createAction, props } from '@ngrx/store';
import { User } from '@core/models/user.model';

export const loadedUser = createAction(
  '[User] Loaded User',
  props<{ user: User; }>()
);

export const clearUser = createAction(
  '[User] Clear User'
);
