import { ParamMap, Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  paramMap: ParamMap;
  queryParamMap: ParamMap;
}

export class CustomRouteSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams, queryParamMap },
    } = routerState;
    const { params, paramMap } = route;

    return { url, params, queryParams, paramMap, queryParamMap };
  }
}
