import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { SurveysViewsActions } from '@app/surveys-manager/actions';

@Injectable()
export class SurveyIdResolverService implements Resolve<string> {

  constructor(private store: Store<any>) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    const id = route.paramMap.get('surveyId');
    this.store.dispatch(SurveysViewsActions.selectSurveyView({surveyUuid: id}));

    return of(id);
  }
}
