<div class="logic-search-labels">
    <div
         class="logic-search-instructions body-small">
        <ng-container>Please find the decision question</ng-container>

        <ds-tooltip-icon class="icon">
            <span #tooltipHTML>
                <strong>
                    A decision question is the question that is used to apply logic to your original question.
                </strong>
                <br><br>
                <i>
                    I.e.: If you only wanted to show your question to females you would set your decision question
                    as &quot;Which gender are you?&quot; and then select female from the following screen.
                </i>
            </span>
        </ds-tooltip-icon>
    </div>
</div>

<ds-search
           placeholder="Search questions and question codes e.g: 'Gender', 'Q1234'"
           (searchInput)="updateQuery($event)"></ds-search>

<div *ngIf="!(loadingInitial$ | async); else loadingSpinner"
     class="search-results">

    <div>
        <div *ngIf="(hasResults$ | async)"
             class="results-panel">
            <div *ngIf="(showResultCounter$ | async)"
                 class="results-counter">
                {{numberOfResults$ | async}} results
            </div>

            <ul class="results-list">
                <li *ngFor="let node of (nodes$ | async)"
                    (click)="selectQuestion(node)">
                    <ds-element-preview-frame class="search-result">
                        <ds-question-element-preview [data]="node"></ds-question-element-preview>
                    </ds-element-preview-frame>
                </li>
            </ul>

            <div *ngIf="showLoadMore$ | async"
                 class="load-more">
                <button ds-button
                        [disabled]="loading$ | async"
                        [loading]="loading$ | async"
                        (click)="loadNext()">
                    Load More
                </button>
            </div>
        </div>

        <div *ngIf="!(hasResults$ | async)"
             class="no-results-panel">
            <div class="S1 no-results-heading">🙈 Sorry, no results found for '{{(queryString$ | async)}}'
            </div>
            <div class="no-results-info">Please try another keyword or phrase in the search bar above</div>
        </div>
    </div>
</div>

<ng-template #loadingSpinner>
    <div class="loading-state">
        <ds-loading-spinner></ds-loading-spinner>
    </div>
</ng-template>
