import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Block, ElementText } from '@app/surveys/models/survey-plan-elements.model';
import { NodeIconType } from '@shared/components/element-icon/element-icon.component';

@Component({
  selector: 'ds-block-element-preview',
  templateUrl: './block-element-preview.component.html',
  styleUrls: ['./block-element-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlockElementPreviewComponent implements OnInit {
  @Input() block: Block;

  iconType: NodeIconType = 'block';
  displayText: ElementText;

  ngOnInit() {
    this.displayText = this.block.text;
    this.iconType = 'block';
  }
}
