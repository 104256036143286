import { OverlayModule } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { TooltipDirective } from './tooltip.directive';
import { TooltipComponent } from './tooltip.component';
import { DS_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER } from './tooltip.model';
import { TooltipIconComponent } from './tooltip-icon.component';
import { TabulaIconModule } from '../tabula-icon-components/tabula-icons.module';

@NgModule({
  imports: [
    A11yModule,
    CommonModule,
    OverlayModule,
    FontAwesomeModule,
    TabulaIconModule
  ],
  exports: [
    TooltipDirective,
    TooltipComponent,
    TooltipIconComponent,
  ],
  declarations: [
    TooltipDirective,
    TooltipComponent,
    TooltipIconComponent,
  ],
  providers: [
    DS_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER
  ]
})
export class TooltipModule {
}
