import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ds-info-page',
  templateUrl: './info-page.component.html',
  styleUrls: ['./info-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoPageComponent {
}
