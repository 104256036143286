import { ChangeDetectionStrategy, Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';


export interface SnackBarMessageData {
   message: string;
   icon: string;
}

@Component({
  selector: 'ds-snack-bar-message',
  templateUrl: 'snack-bar-message.component.html',
  styleUrls: ['snack-bar-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,

})
export class SnackBarMessageComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarMessageData) { }
}
