<div class="modal-title H3">{{data.title}}</div>

<div class="modal-content body-small">{{data.content}}</div>

<div class="modal-button-group">
  <button ds-button
          class="outline medium-size"
          (click)="close(false)">
    {{data.closeBtnText}}
  </button>
  <button ds-button
          class="medium-size"
          [class.primary]="data.confirmBtnPrimary"
          [class.destructive]="!data.confirmBtnPrimary"
          (click)="close(true)">
    {{data.confirmBtnText}}
  </button>
</div>
