import { Injectable } from '@angular/core';
import { SearchElementsResponseModel } from '@app/surveys/models/survey.model';
import { ApiService } from '@core/services/api.service';
import { Observable } from 'rxjs';
import { QuestionsSearchResponseModel } from '../models/search-node.model';

export interface SearchForQuestionsParams {
  survey_id: string;
  per: number;
  page: number;
  piping_candidates: boolean;
}

export interface SearchForElementParams {
  search_term: string;
  per?: number;
}

@Injectable({
  providedIn: 'root'
})
export class SearchApiService {

  constructor(private api: ApiService) {}

  searchForQuestions(params: SearchForQuestionsParams): Observable<QuestionsSearchResponseModel> {
    return this.api.get(`/search`, {params});
  }

  searchForElements(params: SearchForElementParams, surveyId: string): Observable<SearchElementsResponseModel> {
    return this.api.get(`/surveys/${surveyId}/search_elements`, {params});
  }
}
