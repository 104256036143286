import * as fromSurveysViews from './survey-views.reducer';
import * as fromRoot from '@app/core/reducers/';
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { RemoteData } from '@app/remote-data/models/remote-data.model';
import { SurveyView } from '@app/surveys/models/survey.model';

export const surveysManagerFeatureKey = 'surveysManager';

export interface SurveysManagerState {
  [fromSurveysViews.surveysViewsFeatureKey]: fromSurveysViews.State;
}

export interface State extends fromRoot.State {
  [surveysManagerFeatureKey]: SurveysManagerState;
}


export function reducers(state: SurveysManagerState | undefined, action: Action) {
  return combineReducers({
    [fromSurveysViews.surveysViewsFeatureKey]: fromSurveysViews.reducer,
  })(state, action);
}

export const surveysManagerState = createFeatureSelector<SurveysManagerState>(surveysManagerFeatureKey);

// SURVEY VIEWS

export const surveysViewsState = createSelector(
  surveysManagerState,
  (state) => state[fromSurveysViews.surveysViewsFeatureKey]
);

export const queryResult = createSelector(
  surveysViewsState,
  fromSurveysViews.getQueryResult
);

export const pagination = createSelector(
  surveysViewsState,
  fromSurveysViews.getPagination
);

export const surveysViewsPositions = createSelector(
  surveysViewsState,
  fromSurveysViews.getPositions
);

export const surveysViewsPositionsStatus = createSelector(
  surveysViewsState,
  fromSurveysViews.getPositionsStatus
);

export const surveysViews = createSelector(
  surveysViewsState,
  fromSurveysViews.getSurveysViews
);

export const sortedSurveysViews = createSelector(
  surveysViews,
  surveysViewsPositions,
  (surveys, positions) => {
    return <[string, RemoteData<SurveyView>][]>positions
      .filter((uuid) => surveys.has(uuid))
      .map((uuid) => [uuid, surveys.get(uuid)]);
  }
);

export const selectedSurveyViewId = createSelector(
  surveysViewsState,
  fromSurveysViews.getSelectedSurveyViewId
);

export const selectedSurveyView = createSelector(
  surveysViews,
  selectedSurveyViewId,
  (surveys, uuid) => surveys.get(uuid) || null
);

export const surveyViewByUuid = (uuid: string) => {
  return createSelector(
    surveysViews,
    (surveys) => surveys.get(uuid) || null
  );
};

export const surveysViewsCount = createSelector(
  surveysViews,
  (surveys) => surveys.size
);
