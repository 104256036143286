import { EnvironmentModel } from '@env/environment.model';

export const environment: EnvironmentModel = {
  runningTestEnv: true,
  showDebugPanel: true,
  enableProdMode: true,
  storeDevToolsEnabled: false,

  api: 'https://surveys-testing.pollpass.com',
  neo: 'https://neo-testing.pollpass.com',
  clientId: 'd8ae9ac545ef72147dae38a5fda7d6f97c8cff6f27d976eb3a0a96c024a44a57',
  clientSecret: '73a3fb8bab578cced9d4eb5c31251aef671fe629f2ae7cca6ccd4d36894a5e22',

  googleSsoClientId: '340234780019-7auhtfl9uegvchqhkanati0vsn1jposn.apps.googleusercontent.com',

  messenger: 'https://messenger-testing.pollpass.com/',
  previewSocket: 'wss://neo-testing.pollpass.com/surveys',
  surveyTestingSocket: 'wss://neo-testing.pollpass.com/conversation',
  dashApp: 'https://dash-app-testing.globalwebindex.com',

  bugsnagEnabled: false,
  bugsnagKey: 'ba839307b2af255b935f4fece3335330',
  bugsnagStage: 'testing',
  appVersion: 'cde98a3',
};
