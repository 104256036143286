import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'input[ds-input]',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputComponent {
  @HostBinding('class.slim')
  get isSlim() {
    return this._isSlim;
  }

  @Input()
  set slim(val: boolean | string) {
    this._isSlim = typeof val === 'string' ? val === 'true' : val;
  }

  @HostBinding('max') @Input() max: number;

  _isSlim = false;

  constructor() {
  }
}
