import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { environment } from '@env/environment'; // Angular CLI environment

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppComponent } from './app.component';
import { NoContentComponent } from './no-content';
import { initApp } from './app.initializer';

import { CoreModule } from '@core/core.module';
import { metaReducers, ROOT_REDUCERS } from '@core/reducers';
import { AuthService } from '@core/services';

import { AppRoutingModule } from './app-routing.module';
import { SingleSignOnModule } from './single-sign-on/single-sign-on.module';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SharedModule } from '@shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomRouteSerializer } from '@app/app-custom-route-serializer';
import { RoutingEffects } from '@app/app-routing.effects';
import { BugsnagErrorHandler } from '@app/lib/bugsnag-error-handler';
import { DisplayLogicBuilderComponent } from './display-logic/display-logic-builder/display-logic-builder.component';

@NgModule({
  declarations: [
    AppComponent,
    NoContentComponent,
    DisplayLogicBuilderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    SingleSignOnModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),
    EffectsModule.forRoot([RoutingEffects]),
    environment.storeDevToolsEnabled ? StoreDevtoolsModule.instrument(
      {
        maxAge: 25,
        serialize: { options: { map: true }}
      }) : [],
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouteSerializer
    }),
  ],
  exports: [
    BrowserModule,
    RouterModule,
    ReactiveFormsModule,
    CoreModule,
    SharedModule,
  ],
  providers: [
    [
      {
        provide: APP_INITIALIZER,
        useFactory: initApp,
        deps: [AuthService],
        multi: true
      },
      {
        provide: ErrorHandler,
        useClass: BugsnagErrorHandler,
        deps: [Injector],
      }
    ]
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }
}
