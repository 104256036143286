import { Component, Input } from '@angular/core';

@Component({
  selector: 'a[ds-button], button[ds-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() loading = false;

  constructor() { }
}
