import { Injectable } from '@angular/core';
import { SearchResultElement, SearchResultOption } from '@app/languages/models/translations.models';
import { SearchElementsResponseModel, SearchResultNodeModel, TextSearchResultNodeModel } from '@app/surveys/models/survey.model';
import { SearchApiService, SearchForElementParams, SearchForQuestionsParams } from '@app/surveys/services/search-api.service';
import { Pagination, toPagination } from '@shared/models/meta.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuestionsSearchResponseModel, SearchNode, SearchNodeModel, toSearchNode } from '../models/search-node.model';

@Injectable({
  providedIn: 'root'
})

export class SearchService {
  constructor(private searchApiService: SearchApiService) {
  }

  searchForElements(
    query: string,
    surveyUuid: string,
  ): Observable<SearchResultElement[]> {
    const params: SearchForElementParams = {
      search_term: query,
      per: 999
    };

    return this.searchApiService.searchForElements(params, surveyUuid).pipe(
      map((payload: SearchElementsResponseModel): SearchResultElement[] => this.toResultElements(payload.survey_elements)),
    );
  }

  // QUESTIONS

  searchForQuestions(
    query: string,
    page: number,
    per: number,
    surveyUuid: string,
    pipingCandidate: boolean,
  ): Observable<[SearchNode[], Pagination]> {
    const params: SearchForQuestionsParams = {
      survey_id: surveyUuid,
      page: page,
      per: per,
      piping_candidates: pipingCandidate,
      ...query.length > 0 ? {query} : {},
    };

    return this.searchApiService.searchForQuestions(params).pipe(
      map((payload: QuestionsSearchResponseModel) => {
        const nodes = this.toNodes(payload.results);
        const pagination = toPagination(payload.meta);

        return <[SearchNode[], Pagination]>[nodes, pagination];
      })
    );
  }

  private toNodes(questions: SearchNodeModel[]): SearchNode[] {
    return questions.map((questionResponse: SearchNodeModel) => toSearchNode(questionResponse));
  }

  private toResultElements (surveyElements: SearchResultNodeModel[]) {
    return surveyElements.map((element): SearchResultElement => {
      const option: SearchResultOption = {
        id: element.id,
        code: element.code,
        name: element.name,
        nameHtml: element.name_html,
        elementType: element.element_type,
        optionCode: element.code
      };

      switch (element.element_type) {
        case 'Bundle::Node':
        return {
          ...option,
          elementType: element.element_type,
          bundleQuestionOptions: this.mapQuestionOptions(element.bundle_question_options, element.code),
          bundleQuestions: this.mapQuestionOptions(element.bundle_questions, element.code)
        };

        case 'MultipleQuestion':
        case 'RadioQuestion':
        case 'LanguageQuestion':
        case 'OpenEndedQuestion':
          return {
            ...option,
            elementType: element.element_type,
            options: this.mapQuestionOptions(element.question_options, element.code)
          };

        default:
          return {
            ...option,
            elementType: element.element_type,
          };
      }
    });
  }

  private mapQuestionOptions(options: TextSearchResultNodeModel[], parentQuestionCode: string) {
    return (options || []).map((option) => {
      return {
        id: option.id,
        name: option.name,
        nameHtml: option.name_html,
        elementType: option.element_type,
        code: option.code,
        optionCode: parentQuestionCode ? parentQuestionCode + '_' + option.code : option.code,
      };
    });
  }
}
