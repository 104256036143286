import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ApiError } from '@core/models/error.model';
import { throwError as _throw ,  Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EnvironmentService } from '@core/services/environment.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
  })
export class NeoService {
  constructor(private http: HttpClient,
              private auth: AuthService,
              private env: EnvironmentService) {
  }

  URI: string = this.env.neoURI;
  accessToken$: Observable<string> = this.auth.accessToken$;

  get(path: string, options?: object): Observable<any> {
    const url: string = this.env.neoURI + path;

    return this.http.get(url, options)
      .pipe(
        catchError(this.handleError())
      );
  }

  handleError() {
    return (response: HttpErrorResponse) => _throw(new ApiError(response));
  }
}
