import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ds-element-preview-frame',
  templateUrl: './element-preview-frame.component.html',
  styleUrls: ['./element-preview-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementPreviewFrameComponent {
  constructor() { }
}
