import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NodeIconType } from '@shared/components/element-icon/element-icon.component';
import { ScreenOutData } from '@app/surveys/models/survey-plan-elements.model';

@Component({
  selector: 'ds-screen-out-element-preview',
  templateUrl: './screen-out-element-preview.component.html',
  styleUrls: ['./screen-out-element-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScreenOutElementPreviewComponent {
  @Input() data: ScreenOutData;

  constructor() {
  }

  get displayText(): string {
    return 'Screen Out';
  }

  get iconType(): NodeIconType {
    return 'screenOut';
  }
}
