import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  AuthInterceptor
} from '@app/core/interceptors';

import {
  NeoService,
  ApiService,
  AuthService,
  AuthApiService,
  BrowserStorageServiceProvider,
  CookiesService, ModalsService,
  WindowRefService, EnvironmentService
} from '@app/core/services';

import {
  AuthGuard,
  UserGuard
} from '@app/core/guards';
import { Overlay } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { RemoteDataModule } from '@app/remote-data/remote-data.module';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    PortalModule,
    RemoteDataModule,
  ],
  declarations: [
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    PortalModule,
    RemoteDataModule,
  ],
  providers: [
    WindowRefService,
    BrowserStorageServiceProvider,
    EnvironmentService,
    ApiService,
    NeoService,
    AuthApiService,
    AuthService,
    CookiesService,
    AuthGuard,
    UserGuard,
    ModalsService,
    Overlay,
    [{
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }],
  ],
})
export class CoreModule {
  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        '[CoreModule] CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
