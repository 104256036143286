// UTILS CLASS

import { ApiError } from '@core/models/error.model';

export class Utils {
  static range(start, stop, step = 1): number[] {
    return Array.from({length: (stop - start) / step + 1}, (_, i) => start + (i * step));
  }

  static sortByProperty<T, K extends keyof T>(values: T[], orderBy: K) {
    const compare = (a, b) => a[orderBy] < b[orderBy] ? -1 : (a[orderBy] > b[orderBy] ? 1 : 0);
    return Array.from(values).sort(compare);
  }

  static swapArrayElements<T>(arr: T[], x, y): T[] {
    if (x === y || arr[x] === undefined || arr[y] === undefined) {
      console.warn('[swapArrayElements] Same elements or element does not exist!');
      return arr;
    }
    const a = x > y ? y : x;
    const b = x > y ? x : y;
    return [
      ...arr.slice(0, a),
      arr[b],
      ...arr.slice(a + 1, b),
      arr[a],
      ...arr.slice(b + 1)
    ];
  }

  static moveArrayElement<T>(arr: T[], from: number, to: number): T[] {
    const clone = [...arr];
    clone.splice(to, 0, clone.splice(from, 1)[0]);
    return clone;
  }

  static randomString(): string {
    return Math.random().toString(36).substring(7);
  }

  static stringToHash(str: string): string {
    let hash = 0;
    let chr;

    if (str.length === 0) {
      return String(hash);
    }

    for (let i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr; // tslint:disable-line
      hash |= 0; // tslint:disable-line
    }

    return String(hash);
  }
}

// UUID CLASS

// Based on https://github.com/pnegri/uuid-js
export class UUID {
  static limitUI04 = Math.pow(2, 4);
  static limitUI06 = Math.pow(2, 6);
  static limitUI08 = Math.pow(2, 8);
  static limitUI12 = Math.pow(2, 12);
  static limitUI16 = Math.pow(2, 16);
  static limitUI32 = Math.pow(2, 32);

  // Returns a random integer between min and max
  // Using Math.round() will give you a non-uniform distribution!
  // @see https://developer.mozilla.org/en-US/docs/JavaScript/Reference/Global_Objects/Math/random
  static getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  static randomUI06() {
    return UUID.getRandomInt(0, UUID.limitUI06 - 1);
  }

  static randomUI08() {
    return UUID.getRandomInt(0, UUID.limitUI08 - 1);
  }

  static randomUI12() {
    return UUID.getRandomInt(0, UUID.limitUI12 - 1);
  }

  static randomUI16() {
    return UUID.getRandomInt(0, UUID.limitUI16 - 1);
  }

  static randomUI32() {
    return UUID.getRandomInt(0, UUID.limitUI32 - 1);
  }

  static randomUI48() {
    return (0 | Math.random() * (1 << 30)) + (0 | Math.random() * (1 << 48 - 30)) * (1 << 30); // tslint:disable-line
  }

  static paddedString(str, length, z?) {
    str = String(str);
    z = (!z) ? '0' : z;
    let i = length - str.length;
    for (; i > 0; i >>>= 1, z += z) { // tslint:disable-line
      if (i & 1) { // tslint:disable-line
        str = z + str;
      }
    }
    return str;
  }

  static fromParts(timeLow, timeMid, timeHiAndVersion, clockSeqHiAndReserved, clockSeqLow, node) {
    return UUID.paddedString(timeLow.toString(16), 8)
      + '-'
      + UUID.paddedString(timeMid.toString(16), 4)
      + '-'
      + UUID.paddedString(timeHiAndVersion.toString(16), 4)
      + '-'
      + UUID.paddedString(clockSeqHiAndReserved.toString(16), 2)
      + UUID.paddedString(clockSeqLow.toString(16), 2)
      + '-'
      + UUID.paddedString(node.toString(16), 12);
  }

  static create4(): string {
    return UUID.fromParts(
      UUID.randomUI32(),
      UUID.randomUI16(),
      0x4000 | UUID.randomUI12(), // tslint:disable-line
      0x80 | UUID.randomUI06(), // tslint:disable-line
      UUID.randomUI08(),
      UUID.randomUI48()
    );
  }
}

// SWITCH ASSERT METHOD

export function assertUnreachable(x: unknown): never {
  throw new Error(`Should cover all cases!`);
}

// HANDLE RX ERROR

export function checkError(error: ApiError | TypeError) {
  if (!(error instanceof ApiError)) {
    throw error;
  }
}
