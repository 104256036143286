export interface PaginationModel {
  current_page: number;
  next_page?: number;
  prev_page?: number;
  total_count: number;
}

export interface Pagination {
  currentPage: number;
  nextPage: number;
  previousPage: number;
  totalItemsCount: number;
}

// HELPERS

export function toPagination(model: PaginationModel): Pagination {
  return {
    currentPage: model.current_page,
    nextPage: model.next_page || -1,
    previousPage: model.prev_page || -1,
    totalItemsCount: model.total_count
  };
}

export function defaultPagination(): Pagination {
  return {
    currentPage: 0,
    nextPage: -1,
    previousPage: -1,
    totalItemsCount: 1
  };
}
