import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as fromSurveysManager from '@app/surveys-manager/reducers';
import { SearchNode } from '@app/surveys/models/search-node.model';
import { SearchService } from '@app/surveys/services';
import { select, Store } from '@ngrx/store';
import { defaultPagination, Pagination } from '@shared/models/meta.model';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'ds-search-question-panel',
  templateUrl: './search-question-panel.component.html',
  styleUrls: ['./search-question-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchQuestionPanelComponent implements OnInit, OnDestroy {
  modeToggle: FormGroup;

  nodes$ = new BehaviorSubject<SearchNode[]>([]);

  queryString$ = new BehaviorSubject<string>('');
  pagination$ = new BehaviorSubject<Pagination>(defaultPagination());
  loadNext$ = new BehaviorSubject<boolean>(true);
  loading$ = new BehaviorSubject<boolean>(false);

  @Input() itemsPerPage = 40;
  @Input() pipingCandidate = false;
  @Input() excludeId: string | number = -1;

  @Output() questionSelected: EventEmitter<SearchNode> = new EventEmitter<SearchNode>();

  private ngUnsubscribe$ = new Subject<void>();

  constructor(
    private readonly searchService: SearchService,
    private readonly store: Store<fromSurveysManager.State>,
  ) {}

  ngOnInit (): void {
    this.load();
  }

  ngOnDestroy () {
    this.ngUnsubscribe$.next();
  }

  private resetSearch$ (): Observable<string> {
      return this.queryString$
      .pipe(
        tap(() => {
          this.pagination$.next(defaultPagination());
          this.nodes$.next([]);
        }));
  }

  private load () {
    combineLatest([
      this.resetSearch$(),
      this.loadNext$
    ])
      .pipe(
        tap(() => this.loading$.next(true)),
        switchMap(([query, _]) => this.loadMore$(query)),
        tap(() => this.loading$.next(false)),
        takeUntil(this.ngUnsubscribe$))
      .subscribe((pagination) => this.pagination$.next(pagination));
  }

  private loadMore$ (query: string): Observable<Pagination> {
    return this.pagination$.pipe(
      take(1),
      mergeMap(({ currentPage }) => {
        return this.loadQuestions(query, this.pipingCandidate, currentPage);
      })
    );
  }

  private loadQuestions (query: string, piping: boolean, currentPage: number): Observable<Pagination> {
    return this.selectedSurvey$
      .pipe(
        mergeMap((surveyUuid) =>
          this.searchService
            .searchForQuestions(query, currentPage + 1, this.itemsPerPage, surveyUuid, piping)),
        map(([newNodes, pagination]) => {
          this.nodes$.next([...newNodes, ...this.nodes$.value]);
          return pagination;
        }),
      );
  }

  loadNext () {
    this.loadNext$.next(true);
  }

  updateQuery (query: string): void {
    this.queryString$.next(query);
  }

  selectQuestion (node: SearchNode) {
    this.questionSelected.emit(node);
  }

  get loadingInitial$ (): Observable<boolean> {
    return combineLatest([
      this.loading$,
      this.pagination$
    ])
      .pipe(
        map(([loading, { currentPage }]) => loading && currentPage === 0)
      );
  }

  get selectedSurvey$ (): Observable<string> {
    return this.store.pipe(
      select(fromSurveysManager.selectedSurveyViewId),
      take(1)
    );
  }

  get hasResults$ (): Observable<boolean> {
    return this.nodes$.pipe(
      map((nodes) => nodes.length > 0),
    );
  }

  get numberOfResults$ (): Observable<number> {
    return this.pagination$.pipe(
      map(({ totalItemsCount }) => totalItemsCount)
    );
  }

  get showLoadMore$ (): Observable<boolean> {
    return this.pagination$.pipe(
      map(({ nextPage }) => nextPage > 0)
    );
  }

  get showResultCounter$ (): Observable<boolean> {
    return this.queryString$.pipe(
      map(query => query.length !== 0)
    );
  }

  get modeValue(): string {
    return this.modeToggle.get('mode').value;
  }
}
