import { User } from '@core/models/user.model';
import { Action, createReducer, on } from '@ngrx/store';
import { UserActions } from '@core/actions';

export const userFeatureKey = 'user';

export interface State {
  profile: User | null;
}

const initialState: State = {
  profile: null
};

const userReducer = createReducer(
  initialState,
  on(UserActions.loadedUser, (state, {user}) => ({...state, profile: user})),
  on(UserActions.clearUser, (state) => ({...state, profile: null}))
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

export const getUserProfile = (state: State) => state.profile;
