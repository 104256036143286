import { createAction, props } from '@ngrx/store';

export const lockMainView = createAction(
  '[SurveyBuilder] Lock Main View',
  props<{locked: boolean}>()
);

export const toggleNodeEditMode = createAction(
  '[NodeEditForm] Toggle Node Edit Mode',
);

export const resetNodeEditMode = createAction(
  '[NodeEditForm] Reset Node Edit Mode',
);

export const setUpdatingQuestionOptions = createAction(
  '[NodeEditForm] Set Updating Question Options',
);

export const unsetUpdatingQuestionOptions = createAction(
  '[NodeEditForm] Unset Updating Question Options',
);
