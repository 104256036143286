import { ComponentType, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { CustomModalStyle, ModalOptions, ModalRef } from '@core/models/modal.model';
import { DS_MODAL_DATA } from '@core/tokens';
import { ModalContainerComponent } from '@shared/modals';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {

  readonly defaultConfig = {
    positionStrategy: this.overlay.position().global().centerHorizontally().top('80px'),
    scrollStrategy: this.overlay.scrollStrategies.block(),
    hasBackdrop: true,
    disposeOnNavigation: true,
    width: 512,
    backdropClass: 'bg-1-overlay'
  };

  private openedModal: ModalRef<any, any> | null = null;

  constructor(private overlay: Overlay,
              private injector: Injector) {
  }

  open<T, D, R>(component: ComponentType<T>,
                data: D,
                customStyle: CustomModalStyle = {},
                options: ModalOptions = {}): ModalRef<T, R> {
    // Only one Modal can be opened at any one time!
    this.close();
    const overlayConfig = new OverlayConfig(Object.assign({}, this.defaultConfig, customStyle));
    const overlayRef = this.createOverlay(overlayConfig);
    const dialogContainer = this.attachContainer(overlayRef);
    const modalRef = this.attachContent<T, D, R>(component, data, options, dialogContainer, overlayRef);

    this.openedModal = modalRef;

    return modalRef;
  }

  close() {
    if (this.openedModal !== null) {
      this.openedModal.close(null);
      this.openedModal = null;
    }
  }

  private createOverlay(overlayConfig: OverlayConfig): OverlayRef {
    return this.overlay.create(overlayConfig);
  }

  private attachContainer(overlayRef: OverlayRef): ModalContainerComponent {
    const containerPortal = new ComponentPortal(ModalContainerComponent);
    const containerRef = overlayRef.attach<ModalContainerComponent>(containerPortal);

    return containerRef.instance;
  }

  private attachContent<T, D, R>(component: ComponentType<T>,
                                 data: D,
                                 options: ModalOptions,
                                 container: ModalContainerComponent,
                                 overlayRef: OverlayRef): ModalRef<T, R> {
    const modalRef = new ModalRef<T, R>(
      overlayRef,
      container,
      Object.assign({}, options));

    const injector = this.createInjector<T, D, R>(data, modalRef, container);
    const contentRef = container.attachComponentPortal<T>(
      new ComponentPortal(component, undefined, injector));

    modalRef.componentInstance = contentRef.instance;

    return modalRef;
  }

  private createInjector<T, D, R>(data: D,
                                  modalRef: ModalRef<T, R>,
                                  modalContainer: ModalContainerComponent): Injector {

    return Injector.create({
      parent: this.injector,
      providers: [
        {provide: ModalContainerComponent, useValue: modalContainer},
        {provide: DS_MODAL_DATA, useValue: data},
        {provide: ModalRef, useValue: modalRef}
      ]
    });
  }
}
