<ds-layout-logic-modal>
    <button ds-link
            icon="chevron-left"
            class="back top-left-block"
            (click)="close()">Cancel
    </button>

    <ds-element-preview-panel [owner]="owner"
                              text="Adding piped answers to">
    </ds-element-preview-panel>

    <ds-search-question-panel [pipingCandidate]="true"
                              [excludeId]="excludeId"
                              (questionSelected)="closeWithData($event)"></ds-search-question-panel>
</ds-layout-logic-modal>
