import { AuthGrant } from '@core/models/auth-grant.model';
import { createAction, props } from '@ngrx/store';

export const authenticating = createAction(
  '[Auth] Authenticating',
  props<{ authenticating: boolean; }>()
);

export const refreshingAuthToken = createAction(
  '[Auth] Refreshing',
  props<{ refreshing: boolean; }>()
);

export const updateAuthToken = createAction(
  '[Auth] Update Auth Token',
  props<{token: string}>()
);

export const updateRefreshToken = createAction(
  '[Auth] Update Refresh Token',
  props<{token: string}>()
);

export const clearAuthToken = createAction(
  '[Auth] Clear Access Token',
);

export const clearRefreshToken = createAction(
  '[Auth] Clear Refresh Token',
);
