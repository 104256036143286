import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  Input,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'ds-tooltip-icon',
  styleUrls: ['./tooltip-icon.component.scss'],
  templateUrl: './tooltip-icon.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipIconComponent implements AfterContentInit {
  @ContentChild('tooltipHTML', {static: true}) tooltipHTML: ElementRef;
  @Input() icon = 'question-circle';
  @Input() iconClass = 'help-icon';
  @Input() tooltipText: string;

  ngAfterContentInit(): void {
    if (!this.tooltipText) {
      this.tooltipText = this.tooltipHTML && this.tooltipHTML.nativeElement && this.tooltipHTML.nativeElement.innerHTML;
    }
  }
}
