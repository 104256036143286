import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-help-icon',
  templateUrl: './help-icon.component.html',
  styleUrls: ['./help-icon.component.scss']
})
export class HelpIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
