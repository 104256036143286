<label class="option"
       [ngClass]="{'checked': checked}">
    <input #radio
           class="custom-control-input"
           id="{{id}}"
           value="{{value}}"
           type="radio"
           [disabled]="disabled"
           (change)="inputValueChange($event)"
           (blur)="inputBlur()"
           (click)="inputClicked($event)">
    <span class="custom-control-indicator"></span>
    <span class="custom-control-description option-container">
      <span *ngIf="code" class="option-code" [innerHTML]="code"></span>
      <span class="option-text" [innerHTML]="content"></span>
   </span>
</label>
