import { AuthService } from '@core/services';
import { catchError, filter, mergeMap, timeout } from 'rxjs/operators';
import { merge, of, zip } from 'rxjs';

export function initApp(auth: AuthService): () => Promise<any> {
  auth.retrieveAuthFromStorage();

  return (): Promise<any> => {
    const hasFreshTokens$ = auth.accessToken$.pipe(
      filter((accessToken) => accessToken !== null),
    );

    const hasRefreshedTokens$ = zip(auth.accessToken$, auth.refreshToken$).pipe(
      filter(([accessToken, refreshToken]) => accessToken === null && refreshToken !== null),
      mergeMap((_) => auth.refreshAccessToken()),
    );

    const hasNoTokens$ = zip(auth.accessToken$, auth.refreshToken$).pipe(
      filter(([accessToken, refreshToken]) => accessToken === null && refreshToken === null),
    );

    return merge(
      hasFreshTokens$,
      hasRefreshedTokens$,
      hasNoTokens$)
      .pipe(
        timeout(10000),
        catchError((err) => {
          console.warn(err);
          auth.logout();
          return of(Promise.resolve());
        })
      ).toPromise();
  };
}
