import { Injectable } from '@angular/core';
import {
  Block,
  BlockCreateModel,
  BlockModel,
  BlockUpdateModel,
  BundleNodeData,
  BundleQuestionCreateModel,
  Node,
  NodeQuestionData,
  QuestionBundleUpdateModel,
  QuestionCreateModel,
  QuestionUpdateModel,
  QuotaNodeCreateModel,
  QuotaNodeData,
  QuotaNodeUpdateModel,
  ScreenOutCreateModel,
  ScreenOutData,
  ScreenOutUpdateModel,
  TextCreateModel,
  TextData,
  TextUpdateModel,
  toBlock,
  toNode,
  toNodeQuestionData,
  toNodeScreenOutData,
  toQuestionBundleData,
  toQuotaNodeData,
  toTextData
} from '@app/surveys/models/survey-plan-elements.model';
import {
  Structure,
  SurveyDescription,
  SurveyPlanBlock,
  SurveyPlanDetails,
  SurveyPlanModel,
  SurveysResponseModel,
  SurveyStateMap,
  SurveyView,
  SurveyViewModel,
  toStructure,
  toStructureModel,
  toSurveyPlan,
  toSurveyPlanElementStructure,
  toSurveyPlanLoadingBlocks,
  toSurveyPlanLoadingBlocksStructures,
  toSurveyPlanStructure,
  toSurveyView,
  toSurveyViewModel
} from '@app/surveys/models/survey.model';
import { SurveysApiService } from '@app/surveys/services/surveys-api.service';
import { map, mergeMap } from 'rxjs/operators';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SurveysService {

  constructor(private surveysApiService: SurveysApiService) {
  }

  // SURVEY VIEW

  querySurveysViews(query: string, page: number, per: number, showArchived: boolean): Observable<SurveysResponseModel> {
    const params = new URLSearchParams();

    if (query.length > 0) {
      params.set(`query`, query);
    }

    params.set(`page`, String(page));
    params.set(`per`, String(per));
    params.set(`show_archived`, String(showArchived));

    return this.surveysApiService.getSurveysViews(params);
  }

  createSurvey(name: string): Observable<SurveyViewModel> {
    return this.surveysApiService.createSurvey({name: name}).pipe(
      mergeMap((survey: SurveyViewModel) =>
        // This is here because all surveys need to have at least one block!
        this.surveysApiService.createBlock(survey.id, {name: 'Block'}).pipe(
          map(() => survey))));
  }

  updateSurvey(surveyView: SurveyView): Observable<SurveyView> {
    return this.surveysApiService.updateSurvey(toSurveyViewModel(surveyView)).pipe(
      map((model) => toSurveyView(model))
    );
  }

  deleteSurvey(id: string): Observable<boolean> {
    return this.surveysApiService.deleteSurvey(id);
  }

  duplicateSurvey(surveyId: string): Observable<boolean> {
    return this.surveysApiService.duplicateSurvey(surveyId);
  }

  // SURVEY VIEW - PREVIEW

  surveyPreviewById(surveyId: string): Observable<string> {
    return this.surveysApiService.getSurveyPreviewById(surveyId).pipe(
      map((data) => data.version_uuid)
    );
  }

  // SURVEY PLAN

  loadSurveyPlanById(surveyId: string): Observable<SurveyPlanDetails> {
    return this.surveysApiService.getSurveyPlanById(surveyId).pipe(
      map((model: SurveyPlanModel) => {
        return {
          survey: toSurveyPlan(model),
          surveyStructure: toSurveyPlanStructure(model),
          blocksToLoad: toSurveyPlanLoadingBlocks(model.block_ids),
          blocksStructuresToLoad: toSurveyPlanLoadingBlocksStructures(model.block_ids)
        };
      }));
  }

  loadSurveyPlanBlock(blockId: string, surveyId: string): Observable<SurveyPlanBlock> {
    return this.surveysApiService.getBlockById(surveyId, blockId).pipe(
      map((model: BlockModel) => {
        return {
          block: toBlock(model),
          blockStructure: toSurveyPlanElementStructure(model),
          nodes: model.nodes
        };
      })
    );
  }

  loadSurveyDescriptionById(surveyId: string): Observable<SurveyDescription> {
    return this.surveysApiService.getSurveyPlanById(surveyId).pipe(
      map((model: SurveyPlanModel) => {
          return {
            uuid: model.id,
            name: model.name,
            state: SurveyStateMap.get(model.state)
          };
        }
      )
    );
  }

  // SURVEY PLAN - STRUCTURE

  updateSurveyPlanStructure(surveyId: string, structure: Structure): Observable<Structure> {
    return this.surveysApiService.updateSurveyPlanStructure(surveyId, toStructureModel(structure)).pipe(
      map((model) => toStructure(model)));
  }

  // SURVEY PLAN - BLOCK

  createBlock(surveyId: string, data: BlockCreateModel): Observable<Block> {
    return this.surveysApiService.createBlock(surveyId, data).pipe(
      map((model) => toBlock(model)));
  }

  updateBlock(surveyId: string, data: BlockUpdateModel): Observable<Block> {
    return this.surveysApiService.updateBlock(surveyId, data).pipe(
      map((model) => toBlock(model)));
  }

  deleteBlock(surveyId: string, blockId: string): Observable<boolean> {
    return this.surveysApiService.deleteBlock(surveyId, blockId);
  }

  // SURVEY PLAN - BLOCK - STRUCTURE

  updateBlockStructure(surveyId: string, blockId: string, structure: Structure): Observable<Structure> {
    return this.surveysApiService.updateBlockStructure(surveyId, blockId, toStructureModel(structure)).pipe(
      map((model) => toStructure(model)));
  }

  // SURVEY PLAN - NODE

  deleteNodeInBlock(blockId: string, nodeId: string, surveyId: string): Observable<boolean> {
    return this.surveysApiService.deleteNodeInBlock(blockId, nodeId, surveyId);
  }

  // SURVEY PLAN - QUESTION

  createQuestion(data: QuestionCreateModel, surveyId: string, blockId: string): Observable<Node> {
    return this.surveysApiService.createQuestion(data, surveyId, blockId).pipe(
      map((model) => toNode(model)));
  }

  updateQuestion(data: QuestionUpdateModel, surveyId: string): Observable<NodeQuestionData> {
    return this.surveysApiService.updateQuestion(data, surveyId).pipe(
      map((model) => toNodeQuestionData(model)));
  }

  updateQuestionBundle(data: QuestionBundleUpdateModel, surveyId: string): Observable<BundleNodeData> {
    return this.surveysApiService.updateQuestionBundle(data, surveyId).pipe(
      map((model) => toQuestionBundleData(model)),
    );
  }

  // SURVEY PLAN - QUESTION - STRUCTURE

  updateQuestionStructure(questionId: string, structure: Structure, surveyId: string): Observable<Structure> {
    return this.surveysApiService.updateQuestionStructure(questionId, toStructureModel(structure), surveyId).pipe(
      map((model) => toStructure(model)));
  }

  updateBundleQuestionOptionsStructure (bundleNodeId, questionOptionsStructure: Structure, surveyId: string): Observable<Structure> {
    return this.surveysApiService.updateBundleQuestionOptionsStructure(bundleNodeId, toStructureModel(questionOptionsStructure), surveyId)
    .pipe(map((model) => toStructure(model)));
  }

  updateBundleQuestionsStructure (bundleNodeId, questionsStructure: Structure, surveyId: string): Observable<Structure> {
    return this.surveysApiService.updateBundleQuestionsStructure(bundleNodeId, toStructureModel(questionsStructure), surveyId)
    .pipe(map((model) => toStructure(model)));
  }

  createBundleQuestion (data: BundleQuestionCreateModel, surveyId: string, blockId: string): Observable<Node> {
    return this.surveysApiService.createBundleQuestion(data, surveyId, blockId).pipe(
      map((model) => toNode(model)),
    );
  }

  // SURVEY PLAN - TEXT

  createText(data: TextCreateModel, blockId: string, surveyId: string): Observable<Node> {
    return this.surveysApiService.createText(data, blockId, surveyId).pipe (
      map((model) => toNode(model)));
  }

  updateText(data: TextUpdateModel, blockId: string, surveyId: string): Observable<TextData> {
    return this.surveysApiService.updateText(data, blockId, surveyId).pipe(
      map((model) => toTextData(model)));
  }

  // SURVEY PLAN - SCREEN OUT

  createScreenOut(data: ScreenOutCreateModel, blockId: string, surveyId: string): Observable<Node> {
    return this.surveysApiService.createScreenOut(data, blockId, surveyId).pipe(
      map((model) => toNode(model)));
  }

  updateScreenOut(data: ScreenOutUpdateModel, blockId: string, surveyId: string): Observable<ScreenOutData> {
    return this.surveysApiService.updateScreenOut(data, blockId, surveyId).pipe(
      map((model) => toNodeScreenOutData(model)));
  }

  // SURVEY PLAN - QUOTA NODE

  createQuotaNode(data: QuotaNodeCreateModel, blockId: string, surveyId: string): Observable<Node> {
    return this.surveysApiService.createQuotaNode(data, blockId, surveyId).pipe(
      map((model) => toNode(model)));
  }

  updateQuotaNode(data: QuotaNodeUpdateModel, blockId: string, surveyId: string): Observable<QuotaNodeData> {
    return this.surveysApiService.updateQuotaNode(data, blockId, surveyId).pipe(
      map((model) => toQuotaNodeData(model)));
  }
}
