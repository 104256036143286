import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'a[ds-link], button[ds-link]',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkComponent {
  @Input() icon: string;

  constructor() { }
}
