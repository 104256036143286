import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-file-check-icon',
  templateUrl: './file-check-icon.component.html',
  styleUrls: ['./file-check-icon.component.scss']
})
export class FileCheckIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
