import { SurveyState } from '@app/surveys/models/survey.model';
import { SelectOption } from '@shared/models/select-option.model';

export type OptionsTemplate = 'months' | 'surveyState' | 'webhookNodeHttpMethod' | 'panelProvider';

export const optionsTemplates = new Map<OptionsTemplate, SelectOption[]>([
  ['months', [
    {value: 1, label: 'Jan'},
    {value: 2, label: 'Feb'},
    {value: 3, label: 'Mar'},
    {value: 4, label: 'Apr'},
    {value: 5, label: 'May'},
    {value: 6, label: 'Jun'},
    {value: 7, label: 'Jul'},
    {value: 8, label: 'Aug'},
    {value: 9, label: 'Sep'},
    {value: 10, label: 'Oct'},
    {value: 11, label: 'Nov'},
    {value: 12, label: 'Dec'},
  ]],
  ['surveyState', [
    {
      value: SurveyState.Active,
      selectedLabel: '<div class="dot-wrap"><div class="dot green"></div></div>',
      label: '<div class="dot green"></div><div>Active</div>'
    },
    {
      value: SurveyState.Draft,
      selectedLabel: '<div class="dot-wrap"><div class="dot yellow"></div></div>',
      label: '<div class="dot yellow"></div><div>Draft</div>'
    }
  ]],
  ['panelProvider', [
    {value: 'dynata', label: 'Dynata'},
    {value: 'toluna', label: 'Toluna'},
    {value: 'kantar', label: 'Kantar'},
    {value: 'market_cube', label: 'Market Cube'},
    {value: 'prodege', label: 'Prodege'},
    {value: 'dataspring', label: 'Dataspring'},
    {value: 'ampersand', label: 'Ampersand'},
    {value: 'borderless', label: 'Borderless Access'},
    {value: 'rakuten', label: 'Rakuten'}
  ]]
]);
