import { Injectable } from '@angular/core';
import { BrowserStorage } from '@app/core/models/browser-storage.model';

export type StorageKeyType =
  | 'ds-auth_grant'
  | 'ds_api_hot_url_param'
  | 'ds_neo_hot_url_param'
  | 'ds_preview_socket_hot_url_param'
  | 'ds_messenger_hot_url_param'
  | 'ds_dash_app_redirection_tooltip_show_param'
  | 'ds_dash2_preference'
  | 'ds_dash_app_modal_hide_param';
@Injectable({ providedIn: 'root' })
export class BrowserStorageService {
  private storage: BrowserStorage;

  constructor(storage: BrowserStorage) {
    this.storage = storage;
  }

  setItem(key: StorageKeyType, value: any) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  removeItem(key: StorageKeyType) {
    this.storage.removeItem(key);
  }

  getItem(key: StorageKeyType) {
    return JSON.parse(this.storage.getItem(key));
  }

  clear(): void {
    this.storage.clear();
  }

  getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }
}
