import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ds-bundle-icon',
  templateUrl: './bundle-icon.component.html',
  styleUrls: ['./bundle-icon.component.scss']
})
export class BundleIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
