<div class="label body-small code-branch-container">
    <ds-code-branch-icon></ds-code-branch-icon>
    <span class="description">{{text}}</span>
</div>

<ds-element-preview-frame [class.block]="isOwnerBlock">

    <ng-container [ngSwitch]="owner.type">

        <ds-block-element-preview *ngSwitchCase="conditionOwnerType.Block"
                                  [block]="owner.data"></ds-block-element-preview>

        <ng-container *ngSwitchCase="conditionOwnerType.Node">
            <ng-container [ngSwitch]="owner.data.data.type">

                <ds-question-element-preview *ngSwitchCase="nodeDataType.RadioQuestion"
                                             [data]="owner.data.data"></ds-question-element-preview>

                <ds-question-element-preview *ngSwitchCase="nodeDataType.BundleNode"
                                             [data]="owner.data.data"></ds-question-element-preview>

                <ds-question-element-preview *ngSwitchCase="nodeDataType.MultipleQuestion"
                                             [data]="owner.data.data"></ds-question-element-preview>

                <ds-question-element-preview *ngSwitchCase="nodeDataType.OpenEndedQuestion"
                                             [data]="owner.data.data"></ds-question-element-preview>

                <ds-text-element-preview *ngSwitchCase="nodeDataType.Text"
                                         [data]="owner.data.data"></ds-text-element-preview>

                <ds-screen-out-element-preview *ngSwitchCase="nodeDataType.ScreenOutNode" ></ds-screen-out-element-preview>

            </ng-container>
        </ng-container>

        <ds-question-option-element-preview *ngSwitchCase="conditionOwnerType.QuestionOption"
                                            [data]="owner.data"></ds-question-option-element-preview>

    </ng-container>

</ds-element-preview-frame>
