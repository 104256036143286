import {
  ActionReducer,
  MetaReducer,
  ActionReducerMap,
  createSelector,
  createFeatureSelector,
  Action
} from '@ngrx/store';
import { environment } from '@env/environment';

import * as fromAuth from './auth.reducer';
import * as fromUser from './user.reducer';
import * as fromLayout from './layout.reducer';
import * as fromRouter from '@ngrx/router-store';

export interface State {
  [fromAuth.authFeatureKey]: fromAuth.State;
  [fromUser.userFeatureKey]: fromUser.State;
  [fromLayout.layoutFeatureKey]: fromLayout.State;
  router: fromRouter.RouterReducerState<any>;
}

export const ROOT_REDUCERS: ActionReducerMap<State, Action> = {
  [fromAuth.authFeatureKey]: fromAuth.reducer,
  [fromUser.userFeatureKey]: fromUser.reducer,
  [fromLayout.layoutFeatureKey]: fromLayout.reducer,
  router: fromRouter.routerReducer
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action): State => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}

export const metaReducers: MetaReducer<State>[] = environment.storeDevToolsEnabled ? [logger] : [];

// Auth state
export const authState = createFeatureSelector<fromAuth.State>(fromAuth.authFeatureKey);

export const isAuthenticating = createSelector(authState, fromAuth.getIsAuthenticating);
export const isRefreshing = createSelector(authState, fromAuth.getIsRefreshing);
export const accessToken = createSelector(authState, fromAuth.getAuthToken);
export const refreshToken = createSelector(authState, fromAuth.getRefreshToken);

// User state
export const userState = createFeatureSelector<fromUser.State>(fromUser.userFeatureKey);

export const userProfile = createSelector(userState, fromUser.getUserProfile);

// Layout state
export const layoutState = createFeatureSelector<fromLayout.State>(fromLayout.layoutFeatureKey);

export const mainViewLocked = createSelector(layoutState, fromLayout.getMainViewLocked);
export const elementEditMode = createSelector(layoutState, fromLayout.getElementEditMode);
